<template>
  <div class="w-100 h-100">
    <div
      class="
        w-100
        border-box
        flex
        justify-start
        align-center
        card-shadow
        bg-white
        rounded
        px-1
        overflow-x
      "
      style="height: 50px"
    >
      <div class="flex justify-start align-center">
        <!-- 1 -->
        <div class="flex justify-start align-center">
          <div class="mr-1">世界排名:</div>
          <el-input
            type="number"
            style="width: 100px"
            v-model="queryInfo.start_ranking"
            placeholder="请输入"
          ></el-input>
          <div class="mx-1">-</div>
          <el-input
            type="number"
            style="width: 100px"
            v-model="queryInfo.end_ranking"
            placeholder="请输入"
          ></el-input>
        </div>
        <!-- 2 -->
        <div class="flex justify-start align-center ml-1">
          <div class="mr-1">院校名称:</div>
          <el-input
            style="width: 110px"
            v-model="queryInfo.college_name"
            placeholder="请输入"
          ></el-input>
        </div>
        <!-- 3 -->
        <div class="flex justify-start align-center">
          <div class="mx-1">所属国家:</div>
          <el-select
            class="mr-1"
            v-model="queryInfo.college_country"
            style="width: 110px"
            placeholder="Select"
          >
            <el-option
              v-for="item in college_country_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <!-- 4 -->
        <div class="flex justify-start align-center">
          <div class="mx-1">院校属性:</div>
          <el-select
            class="mr-1"
            v-model="queryInfo.college_attribute"
            style="width: 110px"
            placeholder="Select"
          >
            <el-option
              v-for="item in college_attribute_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <!-- 按钮 -->
        <el-button type="primary" @click="query">
          <el-icon :size="15"> <component :is="Search"></component> </el-icon
          >搜索
        </el-button>
        <el-button type="primary" @click="reset">重置</el-button>
        <el-button v-if="showAdd" @click="add" type="success">
          <el-icon :size="15"> <component :is="Plus"></component> </el-icon>新增
        </el-button>
        <el-link
          target="_blank"
          href="https://api.yooike.com/jylx-php/public/college.xlsx"
          :underline="false"
        >
          <el-button v-if="showAdd" type="primary" class="mx-1"
            >下载导入模版</el-button
          >
        </el-link>
        <input
          @change="change"
          type="file"
          id="file"
          style="display: none"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
        <el-button v-if="showAdd" @click="selectFile" type="success">
          <el-icon :size="15"> <component :is="Plus"></component> </el-icon>导入
        </el-button>
        <el-button
          v-if="showDelete && multipleSelection.length > 0"
          @click="daleteAll"
          type="danger"
        >
          批量删除
        </el-button>
      </div>
    </div>
    <div
      class="w-100 mt-1 border-box card-shadow bg-white rounded p-1"
      style="height: calc(100% - 70px)"
    >
      <!-- 表格 -->
      <div class="w-100" style="height: calc(100% - 60px)">
        <el-table
          :data="list"
          class="w-100 h-100"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="序号" align="center" min-width="100">
            <template #default="scope">
              {{scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column
            v-for="(field, index) in fieldList"
            :key="index"
            :prop="field.prop"
            :label="field.label"
            :width="field.width || ''"
            align="center"
          >
            <template #default="scope">
              <!-- 操作 -->
              <div v-if="field.prop == 'operation'">
                <!-- 修改按钮 -->
                <el-button
                  :disabled="!showEdit"
                  type="primary"
                  size="small"
                  @click="edit(scope.row)"
                  >编辑</el-button
                >
                <!-- 删除按钮 -->
                <el-button
                  :disabled="!showDelete"
                  type="danger"
                  size="small"
                  @click="del(scope.row)"
                  >删除
                </el-button>
              </div>
              <!-- 状态 -->
              <div v-else-if="field.prop == 'post_status'">
                <el-switch
                  @change="updatePost(scope.row)"
                  :disabled="!showEdit"
                  v-model="scope.row.post_status"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                />
              </div>
              <!-- 时间 -->
              <div v-else-if="field.prop == 'post_creation_time'">
                {{ getFormatTime(scope.row.post_creation_time) }}
              </div>
              <span v-else>{{ scope.row[field.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="flex justify-center align-center" style="height: 50px">
        <el-pagination
          :page-sizes="[10, 50, 100, 200]"
          background
          layout="total, sizes, prev, pager, next"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <addCollege ref="addColleges" @reacquire="reacquire"></addCollege>
    <importTable ref="importTables" :fieldList="fieldList" @reacquire="reacquire" @setLoading="setLoading"></importTable>
  </div>
</template>

<script setup>
import systemnApi from "@/api/systemnApi.js";
import XLSX from "@/utils/xlsx.core.min.js";
import addCollege from "./addCollege/addCollege.vue";
import importTable from "./importTable/importTable.vue";
import { ref, reactive, onMounted, computed } from "vue";
import { ElMessageBox, ElMessage, ElLoading } from "element-plus";
import { Search, Plus } from "@element-plus/icons-vue";
import { useStore } from "vuex";
const store = useStore();
let addColleges = ref(null);
let importTables = ref(null);
let college_country_options = ref([
  {
    value: "",
    label: "全部",
  },
]);
let college_attribute_options = ref([
  {
    value: "",
    label: "全部",
  },
]);

let loading = ref(null)

let queryInfo = reactive({
  college_name: "",
  college_country: "",
  college_attribute: "",
  start_ranking: "",
  end_ranking: "",
  pageNum: 1,
  pageSize: 10,
});
let creationTime = ref([]);
const fieldList = [
  // {
  //   id: 1,
  //   prop: "college_id",
  //   label: "序号",
  // },
  {
    id: 2,
    prop: "college_ranking",
    label: "qs世界排名",
  },
  {
    id: 3,
    prop: "college_name",
    label: "院校名称",
  },
  {
    id: 4,
    prop: "college_en_name",
    label: "院校英文名称",
  },
  {
    id: 5,
    prop: "college_attribute",
    label: "院校属性",
  },
  {
    id: 6,
    prop: "college_country",
    label: "院校所属国家",
  },
  {
    id: 7,
    prop: "college_province",
    label: "省/州",
  },
  {
    id: 8,
    prop: "college_city",
    label: "城市",
  },
  {
    id: 10,
    prop: "operation",
    label: "操作",
    width: 180,
  },
];
let total = ref(0);
let list = ref([]);

let multipleSelection = ref([]);

let showAdd = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("50202")) {
    return true;
  } else {
    return false;
  }
});

let showEdit = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("50203")) {
    return true;
  } else {
    return false;
  }
});

let showDelete = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("50204")) {
    return true;
  } else {
    return false;
  }
});

function handleSelectionChange(val) {
  console.log(val);
  multipleSelection.value = val;
}

function daleteAll() {
  ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      const deleteids = multipleSelection.value
        .map((item) => {
          return item.college_id;
        })
        .join(",");
      console.log(deleteids);
      delList(deleteids);
    })
    .catch(() => {
      // catch error
    });
}

function delList(ids) {
  systemnApi.deleteCollegeList({ ids }).then((res) => {
    console.log(res);
    reacquire();
  });
}

function selectFile() {
  document.getElementById("file").click();
}

function setLoading() {
  loading.value.close()
}

function change(e) {

  loading.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })

  var files = e.target.files;
  if (files.length == 0) return;
  var f = files[0];
  if (!/\.xlsx$/g.test(f.name)) {
    ElMessage.error("仅支持读取xlsx格式！");
    return;
  }
  readWorkbookFromLocalFile(f, function (workbook) {
    readWorkbook(workbook);
  });
  e.target.value = null;
}

// 读取本地excel文件
function readWorkbookFromLocalFile(file, callback) {
  var reader = new FileReader();
  reader.onload = function (e) {
    var data = e.target.result;
    var workbook = XLSX.read(data, { type: "binary" });
    if (callback) callback(workbook);
  };
  reader.readAsBinaryString(file);
}

function readWorkbook(workbook) {
  // 工作表名称集合
  var sheetNames = workbook.SheetNames;
  // 这里我们只读取第一张sheet
  var worksheet = workbook.Sheets[sheetNames[0]];
  // console.log(worksheet);
   var list = XLSX.utils.sheet_to_row_object_array(worksheet);
  seeImportTable(list);
}

function seeImportTable(list) {
  importTables.value.init(list);
}

const getSelectByKeyList = async (table, key) => {
  let data = { table, key };
  const res = await systemnApi.getSelectByKeyList(data);
  if (key == "college_country") {
    college_country_options.value = [
      {
        value: "",
        label: "全部",
      },
    ];
    for (const key in res.data) {
      let item = {
        value: res.data[key].college_country,
        label: res.data[key].college_country,
      };
      college_country_options.value.push(item);
    }
  } else {
    college_attribute_options.value = [
      {
        value: "",
        label: "全部",
      },
    ];
    for (const key in res.data) {
      let item = {
        value: res.data[key].college_attribute,
        label: res.data[key].college_attribute,
      };
      college_attribute_options.value.push(item);
    }
  }
};

function query() {
  queryInfo.pageNum = 1;
  getCollegeList();
}

function reset() {
  queryInfo.college_name = "";
  queryInfo.college_country = "";
  queryInfo.college_attribute = "";
  queryInfo.start_ranking = "";
  queryInfo.end_ranking = "";
  queryInfo.pageNum = 1;
  queryInfo.pageSize = 10;
  creationTime.value = [];
  getCollegeList();
}

function getCollegeList() {
  systemnApi.getCollegeList(queryInfo).then((res) => {
    list.value = res.data.list;
    total.value = res.data.total;
    // 根据关键词获取筛选数据(table, key)
    getSelectByKeyList("college", "college_country");
    getSelectByKeyList("college", "college_attribute");
  });
}

function add() {
  addColleges.value.init(null);
}

function edit(info) {
  addColleges.value.init(info);
}

function del(info) {
  ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let data = {
        college_id: info.college_id,
      };
      systemnApi.deleteCollege(data).then((res) => {
        console.log(res);
        ElMessage.success("删除成功");
        reacquire();
      });
    })
    .catch(() => {
      // catch error
    });
}

function handleSizeChange(number) {
  queryInfo.pageSize = number;
  getCollegeList();
}

function handleCurrentChange(newPage) {
  queryInfo.pageNum = newPage;
  getCollegeList();
}

function reacquire() {
  queryInfo.pageNum = 1;
  getCollegeList();
}

onMounted(() => {
  // 获取列表
  getCollegeList();
});
</script>

