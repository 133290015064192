<template>
  <!-- 对话框 -->
  <el-dialog
    v-model="dialogVisible"
    title="导入院校信息"
    width="1100px"
    @close="dialogClosed"
  >
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-table :data="list">
      <el-table-column
        v-for="(field, index) in fieldList"
        :key="index"
        :prop="field.prop"
        :label="field.label"
        :width="field.width || ''"
        align="center"
      >
        <template #default="scope">
          {{ scope.row[field.prop] }}
        </template>
      </el-table-column>
    </el-table>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import systemnApi from "@/api/systemnApi.js";
import { ElMessage } from "element-plus";
import { ref, defineEmits, defineExpose, defineProps } from "vue";
const emit = defineEmits(["reacquire", "setLoading"]);
const props = defineProps({
  fieldList: Array,
});
let fieldList = props.fieldList;
let dialogVisible = ref(false);

let list = ref([]);

function init(info = null) {
  if (info) {
    const listBase = info.slice(1);
    jsonToTable(listBase);
  }
  dialogVisible.value = true;
}
// 将json转换成表格
function jsonToTable(listBase) {
  listBase.forEach((row) => {
    let item = {
      college_ranking: row.college_ranking || "",
      college_name: row.college_name || "",
      college_en_name: row.college_en_name || "",
      college_attribute: row.college_attribute || "",
      college_country: row.college_country || "",
      college_province: row.college_province || "",
      college_city: row.college_city || "",
      college_remarks: row.college_remarks || "",
    };

    list.value.push(item);
  });
  emit("setLoading");
}
// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  systemnApi
    .addCollegeList({ list: list.value })
    .then((res) => {
      console.log(res);
      dialogVisible.value = false;
    })
    .catch((err) => {
      ElMessage.error("到入失败，请检查数据格式");
      console.log(err);
    });
}

function dialogClosed() {
  fieldList.value = [];
  list.value = [];
  emit("reacquire");
}
defineExpose({
  init,
});
</script>

<style>
.canvasTable {
  border-collapse: collapse;
}
.canvasTable th,
.canvasTable td {
  border: solid 1px #6d6d6d;
  padding: 5px 10px;
}
</style>
