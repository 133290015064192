<template>
  <!-- 对话框 -->
  <el-dialog
    v-model="dialogVisible"
    :title="title"
    width="700px"
    @close="dialogClosed"
  >
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="100px">
      <el-form-item label="等级排序：" prop="question_sort">
        <el-input
          type="number"
          v-model="form.question_sort"
          placeholder="请输入等级排序"
        ></el-input>
      </el-form-item>
      <el-form-item label="问题描述：" prop="question_title">
        <el-input
          type="textarea"
          :rows="4"
          v-model="form.question_title"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="解决方案：" prop="question_answer">
        <el-input
          type="textarea"
          :rows="4"
          v-model="form.question_answer"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import systemnApi from "@/api/systemnApi.js";
import { ref, reactive, defineEmits, defineExpose, computed } from "vue";
import { ElMessage } from "element-plus";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let title = computed(() => {
  if (form.value.question_id == 0) {
    return "问题添加";
  } else {
    return "问题修改";
  }
});
let formRef = ref(null);
let form = ref({
  question_id: 0,
  question_title: "",
  question_sort: 0,
  question_answer: "",
  question_status: 1,
});
const formRules = reactive({
  question_title: [
    { required: true, message: "请输入问题描述", trigger: "blur" },
  ],
  question_answer: [
    { required: true, message: "请输入解决方案", trigger: "blur" },
  ],
  question_sort: [
    { required: true, message: "请输入等级排序", trigger: "blur" },
  ],
});

function init(info = null, length = null) {
  if (info) {
    form.value = info;
  }
  if (length) {
    form.value.question_sort = length;
  }
  dialogVisible.value = true;
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      if (form.value.question_id && form.value.question_id != 0) {
        updateProblem();
      } else {
        addProblem();
      }
    } else {
      return false;
    }
  });
}
function updateProblem() {
  systemnApi.updateProblem(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}
function addProblem() {
  systemnApi.addProblem(form.value).then(() => {
    dialogVisible.value = false;
  });
}
function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    question_id: 0,
    question_title: "",
    question_sort: 0,
    question_answer: "",
    question_status: 1,
  };
  emit("reacquire");
}
defineExpose({
  init,
});
</script>
