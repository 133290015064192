import date from '@/utils/date'

const randomNumber = (e) => {
    e = e || 32;
    var t = "1234567890",
        a = t.length,
        n = "";
    for (let i = 0; i < e; i++) {
        n += t.charAt(Math.floor(Math.random() * a))
    };
    return n
}
export default {
    generateSystemNumber: (typeText) => {
       
        return typeText +  date.formatTimeNum() + randomNumber(5)
    },
    // 查询没有子集的第一个数据
    findNoChild(arr, callback) {
        arr.every((item) => {
            if (!item.children || item.children.length == 0) {
                callback(item);
                return;
            } else {
                this.findNoChild(item.children, callback);
            }
        });
    },
}

