<template>
  <div>
    <canvas class="mycanvas" width="120" height="30">抱歉，您的浏览器不支持canvas元素，换个浏览器试试？</canvas>
  </div>
</template>
<script setup>
import config from "@/utils/config.js"
import { ref, reactive, onMounted, defineEmits, defineExpose } from 'vue';
const emit = defineEmits(["getCode"]);
let codeNum = ref("")
let code = "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
function randomColor() {
  //得到随机的颜色值
  var r = Math.floor(Math.random() * 256);
  var g = Math.floor(Math.random() * 256);
  var b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}

function fourCode() {
  codeNum.value = "";
  for (let a = 0; a < 4; a++) {
    codeNum.value += addcode();
  }
  doDraw();
  setCode()
}

function addcode() {
  let index = Math.floor(Math.random() * (code.length - 1) + 1);
  return code[index];
}
function doDraw() {
  var x = 10;
  var y = 10 + Math.random() * 8;
  var deg = (Math.random() * 30 * Math.PI) / 180;
  //  获取canvas
  var canvas = document.getElementsByClassName("mycanvas");
  var canvas_width = canvas[0].width;
  var canvas_height = canvas[0].height;

  var context = canvas[0].getContext("2d");
  context.clearRect(0, 0, canvas_width, canvas_height);
  context.font = "bold 23px 微软雅黑";
  context.fillText(codeNum.value, 30, 25);
  context.translate(x, y); //canvas原点的偏移量
  context.rotate(deg); //旋转度数
  context.fillStyle = randomColor(); //设置颜色
  context.strokeStyle = randomColor(); //设置颜色
  context.rotate(-deg);
  context.translate(-x, -y);
  for (var i = 0; i <= 2; i++) {
    //验证码上显示线条
    context.strokeStyle = randomColor(); //轮廓风格
    context.beginPath();
    context.moveTo(
      Math.random() * canvas_width,
      Math.random() * canvas_height
    );
    context.lineTo(
      Math.random() * canvas_width,
      Math.random() * canvas_height
    );
    context.stroke();
  }
  for (var l = 0; l <= 4; l++) {
    //验证码上显示小点
    context.strokeStyle = randomColor();
    context.beginPath(); //新建一条路径
    var x2 = Math.random() * canvas_width;
    var y2 = Math.random() * canvas_height;
    context.moveTo(x2, y); //吧画笔移动到指定位置
    context.lineTo(x2 + 1, y2 + 1); //想指定位置移动
    context.stroke();
  }
}
function setCode() {
  if (!config.isProduction) {
    emit('getCode', 'aaaa')
  } else {
    emit('getCode', codeNum.value)
  }
}

onMounted(() => {
  fourCode();
  // setCode()
})
defineExpose({
  fourCode
})
</script>

<style>
.mycanvas {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
</style>