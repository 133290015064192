import axios from 'axios'
import { ElMessage } from 'element-plus'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// axios 配置
axios.defaults.timeout = 25000;

// axios.defaults.baseURL = 'https://api.joyeewin.cn/jylx-php/public/index.php/api
axios.defaults.baseURL = 'https://api.joyeewin.cn/jylx-php/public/index.php/api'

axios.interceptors.request.use( config => {
  const token = localStorage.getItem('token');
  if (token) { //判断token是否存在
    config.headers.Authorization = token;  //将token设置成请求头
  }
  return config;
},
err => {
  return Promise.reject(err);
})

export function post(url, params) {
    return new Promise((resolve, reject) => {
      axios.post(url, params)
        .then(res => {
           requestHandle(res,resolve,reject);
        })
        .catch(err =>{
          reject(err)
        })
    });
}

export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {params})
      .then(res => {
        requestHandle(res,resolve,reject);
      })
      .catch(err =>{
          reject(err);
      })
  });
}

export function del(url, params = null) {
  return new Promise((resolve, reject) => {
    axios.delete(url, {params})
      .then(res => {
        requestHandle(res,resolve,reject);
      })
      .catch(err =>{
        reject(err.data)
      })
  });
}

export function put(url,params) {
  return new Promise((resolve, reject) => {
    axios.put(url,params)
      .then(res => {
        requestHandle(res,resolve,reject);
      })
      .catch(err =>{
        reject(err)
      })
  });
}

// 请求的结果做统一处理
function requestHandle(res,resolve,reject){
  if (res.data.status == 407) {
    // 清除所有session值
    localStorage.clear()
    window.location.reload()
  } else if (res.data.status == 200) {
    resolve(res.data);
  } else if(res.data.status == -1) {
    ElMessage.error(res.data.msg);
    reject();
  } else {
    const msg = '服务器错误, 请稍后再试';
    ElMessage.error(msg);
    reject();
  }
}