<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view></router-view>
  </div>
</template>
<script setup>
import { onMounted } from "vue";
// 引入vuex
// import $store from "@/store/index.js";
import { useStore } from 'vuex';
const store = useStore();
function setUserInfo() {
  const userInfo = window.localStorage.getItem("userInfo")
    ? JSON.parse(window.localStorage.getItem("userInfo"))
    : null;
  // console.log(userInfo);
  if (userInfo) {
    store.commit('SET_VALUE', {key: "userInfo", value: userInfo})
  }
}
onMounted(() => {
  setUserInfo()
})
</script>
<style>
@import url("./assets/css/app.css");
</style>
