<template>
  <div>
    <!-- 对话框 -->
    <el-dialog v-model="dialogVisible" title="评分规则" width="1100px" @close="dialogClosed" top="10vh">
      <!-- 表单数据 -->
      <el-descriptions :column="3" border>
        <el-descriptions-item label="会员姓名">
          {{ infoDetails.screen_member_name }}
        </el-descriptions-item>
        <el-descriptions-item label="联系方式">
          {{ infoDetails.screen_member_phone }}
        </el-descriptions-item>
        <el-descriptions-item label="会员总分">
          {{ infoDetails.screen_score }}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 内容主体区域 -->
      <el-table :data="list" class="w-100" height="400px" :span-method="handleObjectSpanMethod">
        <el-table-column v-for="(field, index) in fieldList" :key="index" :prop="field.prop" :label="field.label"
          :width="field.width || ''" align="center">
          <template #default="scope">
            <div v-if="field.prop == 'score_member_sub'">
              {{ getScoreMemberSub(scope.row) }}
            </div>
            <div v-else-if="field.prop == 'score_member_score'">
              {{ getScoreMemberScore(scope.row) }}{{ showTisp(scope.row) }}
            </div>
            <span v-else>
              {{ scope.row[field.prop] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 底部区域 -->
      <template #footer>
        <span>
          <el-button @click="cancel()">关 闭</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import memberApi from "@/api/memberApi.js";
import { ref, reactive, defineEmits, defineExpose } from "vue";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let queryInfo = reactive({
  score_group: "",
});

let spanData = ref([]);
let pos = ref([]);

let infoDetails = ref({
  screen_score: 0
});

let fieldList = ref([
  {
    id: 1,
    prop: "score_describe",
    label: "评分依据",
  },
  {
    id: 2,
    prop: "score_remarks",
    label: "评分标准",
  },
  {
    id: 3,
    prop: "score_value",
    label: "评选分数",
  },
  {
    id: 4,
    prop: "score_member_sub",
    label: "会员提交信息",
  },
  {
    id: 5,
    prop: "score_member_score",
    label: "会员得分",
  },
]);

let list = ref([]);

let keys = ref([]);
let values = ref([]);

// 合并单元格
function handleObjectSpanMethod({ rowIndex, columnIndex }) {
  //  row, column,
  // 需要合并的列
  // [0, 1, 2].includes(columnIndex), 表示合并前三列
  if ([0, 3, 4].includes(columnIndex)) {
    const _row = spanData.value[rowIndex];
    const _col = _row > 0 ? 1 : 0;
    return {
      rowspan: _row,
      colspan: _col,
    };
  }
}

function init(group, info) {
  queryInfo.score_group = group;
  if (info) {
    infoDetails.value = info;
    infoDetails.value.screen_score = 0;
    console.log("infoDetails.value", infoDetails.value);
  }
  getScoreList();
}

function getScoreList() {
  memberApi.getScoreList(queryInfo).then((res) => {
    console.log(res);
    setData(res.data);
    list.value = res.data;
    getSpanData(res.data);
    // 计算会员总分
    getMemberScore();
    dialogVisible.value = true;
  });
}

function getMemberScore() {
  // 如果是研究生
  if (queryInfo.score_group == "postgraduate") {
    keys.value = [
      "education",
      "attribute",
      "GPA",
      "IELTS",
      "TOEFL",
      "GRE",
      "GMAT",
    ];
    values.value = [
      infoDetails.value.screen_education,
      infoDetails.value.screen_attribute,
      infoDetails.value.screen_achievement,
      infoDetails.value.screen_IELTS,
      infoDetails.value.screen_TOEFL,
      infoDetails.value.screen_GRE,
      infoDetails.value.screen_GMAT,
    ];
  } else if (queryInfo.score_group == "college") {
    // 大学生
    keys.value = [
      "education",
      "attribute",
      "GPA",
      "IELTS",
      "TOEFL",
      "collegeEntranceExamination",
    ];
    values.value = [
      infoDetails.value.screen_education,
      infoDetails.value.screen_attribute,
      infoDetails.value.screen_achievement,
      infoDetails.value.screen_IELTS,
      infoDetails.value.screen_TOEFL,
      infoDetails.value.screen_result,
    ];
  } else if (queryInfo.score_group == "high") {
    // 高中生
    keys.value = ["grade", "target", "GPA", "IELTS"];
    values.value = [
      infoDetails.value.screen_education,
      infoDetails.value.screen_target,
      infoDetails.value.screen_achievement,
      infoDetails.value.screen_IELTS,
    ];
  }
  if (queryInfo.score_group != "high") {
    // 判断用户的雅思成绩高还是托福成绩高
    if (infoDetails.value.screen_IELTS > 0 && infoDetails.value.screen_TOEFL > infoDetails.value.screen_IELTS) {
      keys.value.splice(4, 1);
      values.value.splice(4, 1);
    } else {
      keys.value.splice(3, 1);
      values.value.splice(3, 1);
    }

    // 判断用户的GRE成绩高还是GMAT成绩高
    if (infoDetails.value.screen_GRE > 0 && infoDetails.value.screen_GMAT < infoDetails.value.screen_GRE) {
      // console.log("GRE成绩高");
      keys.value.splice(5, 1);
      values.value.splice(5, 1);
    } else {
      // console.log("GMAT成绩高");
      keys.value.splice(4, 1);
      values.value.splice(4, 1);
    }
  }
  for (let index = 0; index < keys.value.length; index++) {
    let scoreData =
      list.value.find((item) => {
        return (
          item.score_standard == values.value[index] &&
          item.score_basis == keys.value[index]
        );
      });
    let number = 0;
    if (scoreData && scoreData.score_value) {
      number = scoreData.score_value;
    }
    infoDetails.value.screen_score += Number(number);
  }
}

function setData(data) {
  for (const key in data) {
    if (data[key].score_basis == "education") {
      data[key].score_member_sub = infoDetails.value.screen_education;
    } else if (data[key].score_basis == "attribute") {
      data[key].score_member_sub = infoDetails.value.screen_attribute;
    } else if (data[key].score_basis == "GPA") {
      data[key].score_member_sub = infoDetails.value.screen_achievement;
    } else if (data[key].score_basis == "IELTS") {
      data[key].score_member_sub = infoDetails.value.screen_IELTS;
    } else if (data[key].score_basis == "TOEFL") {
      data[key].score_member_sub = infoDetails.value.screen_TOEFL;
    } else if (data[key].score_basis == "GRE") {
      data[key].score_member_sub = infoDetails.value.screen_GRE;
    } else if (data[key].score_basis == "GMAT") {
      data[key].score_member_sub = infoDetails.value.screen_GMAT;
    } else if (data[key].score_basis == "collegeEntranceExamination") {
      data[key].score_member_sub = infoDetails.value.screen_result;
    } else if (data[key].score_basis == "target") {
      data[key].score_member_sub = infoDetails.value.screen_target;
    } else if (data[key].score_basis == "grade") {
      data[key].score_member_sub = infoDetails.value.screen_education;
    }
  }
}

function showTisp(info) {
  if (info.score_basis == "IELTS") {
    // 判断用户的雅思成绩高还是托福成绩高
    if(infoDetails.value.screen_IELTS == 0) {
      return "";
    } else if (infoDetails.value.screen_IELTS < infoDetails.value.screen_TOEFL) {
      return "";
    } else {
      return "（忽略计算）";
    }
  } else if (info.score_basis == "TOEFL") {
    // 判断用户的雅思成绩高还是托福成绩高
    if(infoDetails.value.screen_TOEFL == 0) {
      return "";
    } else if (infoDetails.value.screen_TOEFL  < infoDetails.value.screen_IELTS) {
      return "";
    } else if (infoDetails.value.screen_TOEFL  == infoDetails.value.screen_IELTS) {
      return "";
    }  else {
      return "（忽略计算）";
    }
  }  else if (info.score_basis == "GRE") {
    if(infoDetails.value.screen_GRE == 0) {
      return "";
    } else if (infoDetails.value.screen_GRE < infoDetails.value.screen_GMAT) {
      return "";
    } else {
      return "（忽略计算）";
    }
  } else if (info.score_basis == "GMAT") {
    if(infoDetails.value.screen_GMAT == 0) {
      return "";
    } else if (infoDetails.value.screen_GMAT < infoDetails.value.screen_GRE) {
      return "（忽略计算）";
    } else if (infoDetails.value.screen_GRE == infoDetails.value.screen_GMAT) {
      return "";
    } else {
      return "（忽略计算）";
    }
  }else {
    return "";
  }
}

function getScoreMemberSub(info) {
  const item = list.value.find((item) => {
    return (
      item.score_standard == info.score_member_sub &&
      item.score_basis == info.score_basis
    );
  });
  if (item && item.score_remarks) {
    return item.score_remarks;
  } else {
    return "未提交";
  }
}

function getScoreMemberScore(info) {
  const item = list.value.find((item) => {
    return (
      item.score_standard == info.score_member_sub &&
      item.score_basis == info.score_basis
    );
  });
  if (item && item.score_value) {
    return item.score_value;
  } else {
    return 0;
  }
}

// 计算需要合并的单元格
function getSpanData(data) {
  // 存放计算好的合并单元格的规则
  spanData.value = [];
  for (var i = 0; i < data.length; i++) {
    if (i === 0) {
      spanData.value.push(1);
      pos.value = 0;
    } else {
      // 判断当前元素与上一个元素是否相同
      if (data[i].score_basis === data[i - 1].score_basis) {
        spanData.value[pos.value] += 1;
        spanData.value.push(0);
      } else {
        spanData.value.push(1);
        pos.value = i;
      }
    }
  }
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

function dialogClosed() {
  emit("reacquire");
}

defineExpose({
  init,
});
</script>
