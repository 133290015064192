<template>
  <!-- 对话框 -->
  <el-dialog v-model="dialogVisible" title="会员背景提升咨询详情" width="1100px" @close="dialogClosed">
    <!-- 表单数据 -->
    <div v-if="list.length == 0" class="flex justify-center align-center">
      <div>----该会员未提交过咨询内容----</div>
    </div>
    <el-descriptions v-else :column="3" :size="size" border>
      <el-descriptions-item label="会员姓名">
        {{ info.promotion_member_name || "未填写" }}
      </el-descriptions-item>
      <el-descriptions-item label="联系方式">
        {{ info.promotion_member_phone || "未填写" }}
      </el-descriptions-item>
      <el-descriptions-item label="咨询时间">
        {{ info.promotion_creation_time ? getFormatTime(info.promotion_creation_time) :  "未填写" }}
      </el-descriptions-item>
      <el-descriptions-item label="希望获得">
        {{ getPromotionExpectCont(info.promotion_expect) || "未填写" }}
      </el-descriptions-item>
      <el-descriptions-item label="备注">
        {{ info.consult_application_professional || "未填写" }}
      </el-descriptions-item>
    </el-descriptions>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">关 闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import memberApi from "@/api/memberApi.js";
import date from "@/utils/date.js";
import { ref, reactive, defineEmits, defineExpose } from "vue";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let queryInfo = reactive({
  member_id: "",
  pageNum: 1,
  pageSize: 10,
});
let list = ref([]);
let info = ref({});

function getFormatTime(value) {
  return date.formatDate(value * 1000);
}

function getPromotionExpectCont(info) {
  const infoData = JSON.parse(info);
  return `语言培训：${infoData.train ? '希望获得' : '-'}；
          实习机会：${infoData.practice ? '希望获得' : '-'}`
}

function init(info = null) {
  if (info) {
    queryInfo.member_id = info.member_id;
    getPromotionList()
  }
  dialogVisible.value = true;
}

function getPromotionList() {
  memberApi.getPromotionList(queryInfo).then((res) => {
    list.value = res.data.list;
    info.value = res.data.list[0];
  });
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

function dialogClosed() {
  list.value = [];
  emit("reacquire");
}
defineExpose({
  init,
});
</script>

<style>
</style>
