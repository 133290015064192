<template>
  <!-- 对话框 -->
  <el-dialog
    v-model="dialogVisible"
    :title="title"
    width="500px"
    @close="dialogClosed"
  >
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="100px">
      <el-form-item label="专业大类：" prop="professional_title">
        <el-input
          v-model="form.professional_title"
          placeholder="请输入专业大类"
        ></el-input>
      </el-form-item>
      <el-form-item label="专业小类：" prop="professional_class">
        <el-button @click="addProfessionalClass" type="success">
          新增
        </el-button>
        <div class="overflow-y w-100 pr-2 mt-2" style="height: 300px">
          <div class="mt-1">
            <div
              class="mb-1 flex"
              v-for="(item, index) in professional_class_list"
              :key="index"
            >
              <el-input
                v-model="professional_class_list[index]"
                placeholder="请输入专业小类"
              ></el-input>
              <el-button @click="remove(index)" class="ml-1" type="danger"> 删除 </el-button>
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import systemnApi from "@/api/systemnApi.js";
import { ref, reactive, defineEmits, defineExpose, computed } from "vue";
import { ElMessage } from "element-plus";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let title = computed(() => {
  if (form.value.professional_id == 0) {
    return "专业添加";
  } else {
    return "专业修改";
  }
});
let formRef = ref(null);
let form = ref({
  professional_id: 0,
  professional_title: "",
  professional_class: "",
});

let professional_class_list = ref([]);
const formRules = reactive({
  professional_title: [
    { required: true, message: "请输入专业大类", trigger: "blur" },
  ],
  professional_class: [
    // { required: true, message: "请输入专业小类", trigger: "blur" },
  ],
});

function init(info = null) {
  if (info) {
    form.value = info;
    if (info.professional_class) {
      professional_class_list.value = info.professional_class.split(";");
    }
  }
  dialogVisible.value = true;
}

function addProfessionalClass() {
  professional_class_list.value.push("");
}

function remove(index) {
  professional_class_list.value.splice(index, 1)
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      form.value.professional_class = professional_class_list.value.filter(item=>item && item.trim()).join(';');
      if (form.value.professional_id && form.value.professional_id != 0) {
        updateProfessional();
      } else {
        addProfessional();
      }
    } else {
      return false;
    }
  });
}
function updateProfessional() {
  systemnApi.updateProfessional(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}
function addProfessional() {
  systemnApi.addProfessional(form.value).then(() => {
    dialogVisible.value = false;
  });
}
function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    professional_id: 0,
    professional_title: "",
    professional_class: "",
  };
  professional_class_list.value = []
  emit("reacquire");
}
defineExpose({
  init,
});
</script>
