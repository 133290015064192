<template>
  <!-- 对话框 -->
  <el-dialog
    v-model="dialogVisible"
    title="导出报告"
    width="1100px"
    @close="dialogClosed"
    top="10vh"
  >
    <!-- 表单数据 -->
    <el-descriptions :column="4" border>
      <el-descriptions-item label="订单编号">
        {{ infoDetails.purchase_order_number }}
      </el-descriptions-item>
      <el-descriptions-item label="联系方式">
        {{ infoDetails.purchase_member_phone }}
      </el-descriptions-item>
      <el-descriptions-item label="电子邮箱">
        {{ infoDetails.purchase_member_mailbox }}
      </el-descriptions-item>
      <el-descriptions-item label="订单发起时间">
        {{ getFormatTime(infoDetails.purchase_creation_time) }}
      </el-descriptions-item>
    </el-descriptions>
    <!-- 内容主体区域 -->
    <div class="w-100 mt-1" style="height: 450px">
      <el-table :data="list" class="w-100" height="100%">
        <el-table-column prop="application_id" label="序号" align="center" />
        <el-table-column
          prop="application_ranking"
          label="qs世界排名"
          align="center"
        />
        <el-table-column
          prop="application_name"
          label="院校名称"
          align="center"
          width="220"
        />
        <el-table-column
          prop="application_en_name"
          label="院校英文名称"
          align="center"
          width="300"
        />
        <el-table-column
          prop="application_attribute"
          label="院校属性"
          align="center"
        />
        <el-table-column
          prop="application_country"
          label="所属国家"
          align="center"
        />
        <el-table-column
          prop="application_province"
          label="省/州"
          align="center"
          width="200"
        />
        <el-table-column prop="application_city" label="城市" align="center" />
        <el-table-column
          prop="application_professional"
          label="佳易专业大类"
          align="center"
        />
        <el-table-column
          prop="application_class"
          label="佳易专业小类"
          align="center"
          width="400"
        />
        <el-table-column
          prop="application_major_name"
          label="专业中文名称"
          align="center"
          width="300"
        />
        <el-table-column
          prop="application_major_en_name"
          label="专业名称"
          align="center"
          width="300"
        />
        <el-table-column
          prop="application_faculty"
          label="所属院系"
          align="center"
        />
        <el-table-column
          prop="application_education"
          label="学历/学位（中文）"
          align="center"
        />
        <el-table-column
          prop="application_en_education"
          label="学历/学位（英文）"
          align="center"
        />
        <el-table-column
          prop="application_introduce"
          label="专业/学校介绍"
          align="center"
          width="400"
        />
        <el-table-column
          prop="application_course"
          label="课程长度"
          align="center"
        />
        <el-table-column
          prop="application_tuition"
          label="学费"
          align="center"
        />
        <el-table-column
          prop="application_cost"
          label="申请费（美元）"
          align="center"
        />
        <el-table-column
          prop="application_start_date"
          label="开学期"
          align="center"
        />
        <el-table-column
          prop="application_end_date"
          label="截止日期"
          align="center"
        />
        <el-table-column prop="application_GRA" label="GRA" align="center" width="200" />
        <el-table-column
          prop="application_TOEFL"
          label="TOEFL"
          align="center"
          width="200"
        />
        <el-table-column
          prop="application_IELTS"
          label="IELTS"
          align="center"
          width="200"
        />
        <el-table-column prop="application_GRE" label="GRE" align="center" />
        <el-table-column prop="application_GMAT" label="GMAT" align="center" />
        <el-table-column prop="application_SAT" label="SAT" align="center" />
        <el-table-column prop="application_ACT" label="ACT" align="center" />
        <el-table-column
          prop="application_academic_background"
          label="学术背景"
          align="center"
          width="400"
        />
        <el-table-column
          prop="application_material_requirements"
          label="材料要求"
          align="center"
          width="400"
        />
        <el-table-column
          prop="application_enrollment_website"
          label="招生网址"
          align="center"
          width="400"
        />
        <el-table-column
          prop="application_professional_website"
          label="专业网址"
          align="center"
          width="400"
        />
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="flex justify-center align-center" style="height: 50px">
      <el-pagination
        :page-sizes="[10, 50, 100, 200, 500]"
        background
        layout="total, sizes, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">关 闭</el-button>
        <el-button @click="exportSpecialExcel" type="success">
          <el-icon :size="15"> <component :is="Download"></component> </el-icon
          >导出
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import memberApi from "@/api/memberApi.js";
import XLSX from "@/utils/xlsx.core.min.js";
import date from "@/utils/date.js";
import { ref, defineEmits, defineExpose } from "vue";
import { Download } from "@element-plus/icons-vue";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let queryInfo = ref({
  screen_group: "",
  screen_education: "",
  screen_attribute: "",
  screen_country: "",
  screen_major: "",
  screen_class: "",
  screen_achievement: "",
  screen_IELTS: "",
  screen_TOEFL: "",
  screen_GRE: "",
  screen_GMAT: "",
  screen_target: "",
  screen_score: "",
  screen_member_id: "",
  screen_member_name: "",
  screen_member_phone: "",
  screen_custom_major: "",
  screen_college: "",
  screen_hope: "",
  screen_result: "",
  screen_result: "",
  pageNum: 1,
  pageSize: 10,
});

let infoDetails = ref({});

let list = ref([]);
let total = ref(0);

function getFormatTime(value) {
  return date.formatDate(value * 1000);
}

function init(info = null) {
  if (info) {
    infoDetails.value = info;
    getExportReport();
  }
  dialogVisible.value = true;
}

function getExportReport() {
  memberApi.getExportReport(infoDetails.value).then((res) => {
    queryInfo.value = Object.assign(queryInfo.value, res.data);
    console.log(queryInfo.value);
    queryApplicationList();
  });
}

function queryApplicationList() {
  memberApi.queryApplicationList(queryInfo.value).then((res) => {
    list.value = res.data.list;
    total.value = res.data.total;
  });
}

function handleSizeChange(number) {
  queryInfo.value.pageSize = number;
  queryApplicationList();
}

function handleCurrentChange(newPage) {
  queryInfo.value.pageNum = newPage;
  queryApplicationList();
}

function exportSpecialExcel() {
  let xlsxTable = [];
  // 创建 标题
  xlsxTable.push([
    "序号",
    "qs世界排名",
    "院校名称",
    "院校英文名称",
    "院校属性",
    "所属国家",
    "省/州",
    "城市",
    "佳易专业大类",
    "佳易专业小类",
    "专业中文名称",
    "专业名称",
    "所属院系",
    "学历/学位（中文）",
    "学历/学位（英文）",
    "专业/学校介绍",
    "课程长度",
    "学费",
    "申请费（美元）",
    "开学期",
    "截止日期",
    "GRA",
    "托福",
    "雅思",
    "GRE",
    "GMAT",
    "SAT",
    "ACT",
    "学术背景",
    "材料要求",
    "招生网址",
    "专业网址",
  ]);
  // 创建数据内容
  for (const item of list.value) {
    let info = [
      item.application_id,
      item.application_ranking,
      item.application_name,
      item.application_en_name,
      item.application_attribute,
      item.application_country,
      item.application_province,
      item.application_city,
      item.application_professional,
      item.application_class,
      item.application_major_name,
      item.application_major_en_name,
      item.application_faculty,
      item.application_education,
      item.application_en_education,
      item.application_introduce,
      item.application_course,
      item.application_tuition,
      item.application_cost,
      item.application_start_date,
      item.application_end_date,
      item.application_GRA,
      item.application_TOEFL,
      item.application_IELTS,
      item.application_GRE,
      item.application_GMAT,
      item.application_SAT,
      item.application_ACT,
      item.application_academic_background,
      item.application_material_requirements,
      item.application_enrollment_website,
      item.application_professional_website,
    ];
    xlsxTable.push(info);
  }
  var sheet = XLSX.utils.aoa_to_sheet(xlsxTable);
  // sheet['!merges'] = [
  //   // 设置A1-C1的单元格合并
  //   { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }
  // ];
  openDownloadDialog(
    sheet2blob(sheet),
    `${infoDetails.value.purchase_member_name}采购数据表.xlsx`
  );
}

function openDownloadDialog(url, saveName) {
  if (typeof url == "object" && url instanceof Blob) {
    url = URL.createObjectURL(url); // 创建blob地址
  }
  var aLink = document.createElement("a");
  aLink.href = url;
  aLink.download = saveName || ""; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
  var event;
  if (window.MouseEvent) event = new MouseEvent("click");
  else {
    event = document.createEvent("MouseEvents");
    event.initMouseEvent(
      "click",
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
  }
  aLink.dispatchEvent(event);
}

// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName) {
  sheetName = sheetName || "sheet1";
  var workbook = {
    SheetNames: [sheetName],
    Sheets: {},
  };
  workbook.Sheets[sheetName] = sheet;
  // 生成excel的配置项
  var wopts = {
    bookType: "xlsx", // 要生成的文件类型
    bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    type: "binary",
  };
  var wbout = XLSX.write(workbook, wopts);
  var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
  // 字符串转ArrayBuffer
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  return blob;
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

function dialogClosed() {
  list.value = [];
  queryInfo.value = {
    screen_group: "",
    screen_education: "",
    screen_attribute: "",
    screen_country: "",
    screen_major: "",
    screen_class: "",
    screen_achievement: "",
    screen_IELTS: "",
    screen_TOEFL: "",
    screen_GRE: "",
    screen_GMAT: "",
    screen_target: "",
    screen_score: "",
    screen_member_id: "",
    screen_member_name: "",
    screen_member_phone: "",
    screen_custom_major: "",
    screen_college: "",
    screen_hope: "",
    screen_result: "",
    pageNum: 1,
    pageSize: 10,
  };
  emit("reacquire");
}

defineExpose({
  init,
});
</script>

<style>
.canvasTable {
  border-collapse: collapse;
}

.canvasTable th,
.canvasTable td {
  border: solid 1px #6d6d6d;
  padding: 5px 10px;
}
</style>
