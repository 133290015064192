<template>
  <!-- 对话框 -->
  <el-dialog v-model="dialogVisible" :title="title" width="900px" @close="dialogClosed">
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="100px">
      <div class="flex">
        <div class="flex-1">
          <el-form-item label="用户账号：" prop="user_account">
            <el-input :disabled="form.user_id == 1" v-model="form.user_account" placeholder="3 到 18 个字符"></el-input>
          </el-form-item>
          <el-form-item label="用户密码：" prop="user_password">
            <el-input v-model="form.user_password" placeholder="6-18位英文/数字字符"></el-input>
          </el-form-item>
          <el-form-item label="手机号：" prop="user_phone">
            <el-input v-model="form.user_phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="用户性别：" prop="user_sex">
            <el-select class="w-100" v-model="form.user_sex" placeholder="请选择用户性别">
              <el-option v-for="item in sexOptions" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态：" prop="user_status">
            <el-switch :disabled="form.user_id == 1" v-model="form.user_status" active-color="#13ce66"
              inactive-color="#ff4949" :active-value="1" :inactive-value="0" />
          </el-form-item>
        </div>
        <div class="flex-1">
          <el-form-item label="用户昵称：" prop="user_nickname">
            <el-input v-model="form.user_nickname" placeholder="请输入用户昵称"></el-input>
          </el-form-item>
          <el-form-item label="用户角色：" prop="user_role">
            <el-select class="w-100" v-model="form.user_role" placeholder="请选择用户角色">
              <el-option v-for="item in userRoleOptions" :key="item.role_id" :label="item.role_name"
                :value="item.role_id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="邮箱：" prop="user_mailbox">
            <el-input v-model="form.user_mailbox" placeholder="请输入邮箱"></el-input>
          </el-form-item>
          <el-form-item label="用户岗位：" prop="user_post">
            <el-select class="w-100" v-model="form.user_post" placeholder="请选择用户岗位">
              <el-option v-for="item in userPostOptions" :key="item.post_id" :label="item.post_name"
                :value="item.post_id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="短信通知：" prop="user_notice">
            <el-switch v-model="form.user_notice" active-color="#13ce66" inactive-color="#ff4949" :active-value="1"
              :inactive-value="0" />
          </el-form-item>
        </div>
      </div>
      <el-form-item label="备注：" prop="user_remarks">
        <el-input type="textarea" v-model="form.user_remarks" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import systemnApi from "@/api/systemnApi.js";
import { ref, reactive, defineEmits, defineExpose, computed } from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
const emit = defineEmits(["reacquire"]);
const store = useStore();
let dialogVisible = ref(false);
let title = computed(() => {
  if (form.value.user_id == 0) {
    return "用户添加";
  } else {
    return "用户修改";
  }
});
let isMe = ref(false);
let userRoleOptions = ref([]);
const sexOptions = ["男", "女"];
let userPostOptions = ref([
  {
    post_id: 0,
    post_name: '请选择'
  }
]);
let formRef = ref(null);
let form = ref({
  user_id: 0,
  user_account: "",
  user_password: "",
  user_role: "",
  user_nickname: "",
  user_mailbox: "",
  user_phone: "",
  user_sex: "",
  user_post: "",
  user_status: 1,
  user_notice: 0,
  user_remarks: "",
  user_head: "",
});
const formRules = reactive({
  user_nickname: [
    { required: true, message: "请输入用户姓称", trigger: "blur" },
  ],
  user_phone: [{ required: true, message: "请输入用户姓称", trigger: "blur" }],
  user_role: [{ required: true, message: "请选择用户角色", trigger: "change" }],
  user_sex: [{ required: true, message: "请选择用户性别", trigger: "change" }],
  // user_post: [{ required: true, message: "请选择用户性别", trigger: "change" }],
  user_account: [
    { required: true, message: "请输入账号", trigger: "blur" },
    {
      min: 3,
      max: 18,
      message: "长度在 3 到 18 个字符",
      trigger: "blur",
    },
  ],
  user_password: [


    { required: true, message: "请输入密码", trigger: "blur" },
    {
      min: 6,
      max: 18,
      message: "长度在 6 到 18 个字符",
      trigger: "blur",
    },
    {
      //插入正则验证：大小写、数字、至少8位、不常用字符
      // pattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@#!%^*?&+-])[A-Za-z\d$@#!%^*?&+-]{6,}/,
      pattern:/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,}$/,
      message: "密码应当至少6位且含有数字、字母",
    },
  ],
});

const validatePass = (rule, value, callback) => {
  console.log('value', value);
  var reg = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,}$/;
  callback(new Error('123'))
  // if (!reg.test(value)) {
  //   callback(new Error('123'))
  // } else {
  //   callback()
  // }
}

function init(userInfo = null, isMy = false) {
  if (userInfo) {
    form.value = userInfo;
  }
  getAllUserRole();
  getAllUserPost();
  isMe = isMy;
  dialogVisible.value = true;
}

function getAllUserRole() {
  systemnApi.getAllUserRole().then((res) => {
    userRoleOptions.value = res.data;
  });
}

function getAllUserPost() {
  systemnApi.getAllUserPost().then((res) => {
    userPostOptions.value.push(...res.data)
  });
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      if (form.value.user_id && form.value.user_id != 0) {
        updateUser();
      } else {
        addUser();
      }
    } else {
      return false;
    }
  });
}
function updateUser() {
  const newValue = JSON.parse(JSON.stringify(form.value))
  systemnApi.updateUser(form.value).then(() => {
    if (isMe) {
      ElMessage.success(
        "修改成功，为了保证本次运行正常，更新信息将在下次登录后显示"
      );
      window.localStorage.setItem("userInfo", JSON.stringify(newValue));
      store.commit("SET_VALUE", { key: "userInfo", value: newValue });
    } else {
      ElMessage.success("修改成功");
    }
    dialogVisible.value = false;
  });
}
function addUser() {
  systemnApi.addUser(form.value).then(() => {
    dialogVisible.value = false;
  });
}
function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    user_id: 0,
    user_account: "",
    user_password: "",
    user_role: "",
    user_nickname: "",
    user_mailbox: "",
    user_phone: "",
    user_sex: "",
    user_post: "",
    user_status: 1,
    user_notice: 0,
    user_remarks: "",
    user_head: "",
  };
  if (!isMe) {
    emit("reacquire");
  }
}
defineExpose({
  init,
});
</script>
