// 系统api
import { get, post, put, del } from '../http/http.js';

const memberApi = {
    // 获取会员列表
	getMemberList: (params) => get('/admin/getMemberList', params),
    // 添加会员
    addMember: (params) => post('/admin/addMember', params),
    // 修改会员
    updateMember: (params) => put('/admin/updateMember', params),
    // 删除会员
    deleteMember: (params) => del('/admin/deleteMember', params),
    // 获取采购路由
    getPurchaseList: (params) => get('/admin/getPurchaseList', params),
    // 删除采购
    deletePurchase: (params) => del('/admin/deletePurchase', params),
    // 修改采购价格
    updateExcelConfig: (params) => put('/admin/updateExcelConfig', params),
    // 获取申请路由
    getApplyList: (params) => get('/admin/getApplyList', params),
    // 修改申请
    updateApply: (params) => put('/admin/updateApply', params),
    // 删除申请
    deleteApply: (params) => del('/admin/deleteApply', params),
    // 批量删除申请
    deleteApplyList: (params) => del('/admin/deleteApplyList', params),
    // 获取咨询路由
    getConsultList: (params) => get('/admin/getConsultList', params),
    // 修改咨询
    updateConsult: (params) => put('/admin/updateConsult', params),
    // 删除咨询
    deleteConsult: (params) => del('/admin/deleteConsult', params),
    // 批量删除咨询
    deleteConsultList: (params) => del('/admin/deleteConsultList', params),
    // 获取背景提升路由
    getPromotionList: (params) => get('/admin/getPromotionList', params),
    // 修改背景
    updatePromotion: (params) => put('/admin/updatePromotion', params),
    // 删除背景
    deletePromotion: (params) => del('/admin/deletePromotion', params),
    // 批量删除背景
    deletePromotionList: (params) => del('/admin/deletePromotionList', params),
    // 获取关注列表
    getFocusList: (params) => get('/admin/getFocusList', params),
    // 获取匹配路由
    getScreenList: (params) => get('/admin/getScreenList', params),
    // 删除匹配
    deleteScreen: (params) => del('/admin/deleteScreen', params),
    // 获取评分标准
    getScoreList: (params) => get('/admin/getScoreList', params),
    // 修改评分标准
    updateScore: (params) => put('/admin/updateScore', params),
    // 获取匹配规则
    getMatchingList: (params) => get('/admin/getMatchingList', params),
    // 修改评分标准
    updateMatching: (params) => put('/admin/updateMatching', params),
    // 批量删除用户关注
    deleteFocusList: (params) => del('/admin/deleteFocusList', params),
    // 获取倒出报告数据
    getExportReport: (params) => get('/admin/getExportReport', params),
    // 查询学院数据
    queryApplicationList: (params) => get('/admin/queryApplicationList', params),

}

export default memberApi