import Login from '../pages/Login/Login.vue'
import Home from '../pages/Home/Home.vue'

// 用户管理
import UserManagement from '../pages/UserManagement/UserManagement.vue'
// 角色管理
import RoleManagement from '../pages/RoleManagement/RoleManagement.vue'
// 岗位管理
import PostManagement from '../pages/PostManagement/PostManagement.vue'
// 协议管理
import AgreementManagement from '../pages/AgreementManagement/AgreementManagement.vue'
// 问题管理
import ProblemManagement from '../pages/ProblemManagement/ProblemManagement.vue'

// 专业信息
import ProfessionalInformation from '../pages/ProfessionalInformation/ProfessionalInformation.vue'
// 院校信息
import CollegeInformation from '../pages/CollegeInformation/CollegeInformation.vue'
// 院校申请信息
import ApplicationInformation from '../pages/ApplicationInformation/ApplicationInformation.vue'


// banner管理
import BannerManagement from '../pages/BannerManagement/BannerManagement.vue'
// 公告管理
import NoticeManagement from '../pages/NoticeManagement/NoticeManagement.vue'

// 会员信息
import MemberInformation from '../pages/MemberInformation/MemberInformation.vue'
// 关注信息
import FocusInformation from '../pages/FocusInformation/FocusInformation.vue'

// Excel表格订购
import ExcelSheetOrdering from '../pages/ExcelSheetOrdering/ExcelSheetOrdering.vue'
// 会员咨询
import MemberConsultation from '../pages/MemberConsultation/MemberConsultation.vue'
// 背景提升信息
import PromotionInformation from '../pages/PromotionInformation/PromotionInformation.vue'
// 院校申请进度
import ApplicationProgress from '../pages/ApplicationProgress/ApplicationProgress.vue'

// 研究生匹配名单
import PostgraduateMatchList from '../pages/PostgraduateMatchList/PostgraduateMatchList.vue'
// 大学生匹配名单
import CollegeStudentMatchList from '../pages/CollegeStudentMatchList/CollegeStudentMatchList.vue'
// 高中生匹配名单
import HighSchoolStudentMatchList from '../pages/HighSchoolStudentMatchList/HighSchoolStudentMatchList.vue'

// 留言管理
import MessageManagement from '../pages/MessageManagement/MessageManagement.vue'

// 文档模版
import DocumentTemplate from '../pages/DocumentTemplate/DocumentTemplate.vue'
// 会员文档
import MemberDocumentation from '../pages/MemberDocumentation/MemberDocumentation.vue'

export default {
    Login,
    Home,
    UserManagement,
    RoleManagement,
    PostManagement,
    AgreementManagement,
    ProblemManagement,

    ProfessionalInformation,
    CollegeInformation,
    ApplicationInformation,

    BannerManagement,
    NoticeManagement,

    MemberInformation,
    FocusInformation,

    ExcelSheetOrdering,
    MemberConsultation,
    PromotionInformation,
    ApplicationProgress,

    PostgraduateMatchList,
    CollegeStudentMatchList,
    HighSchoolStudentMatchList,

    MessageManagement,

    DocumentTemplate,
    MemberDocumentation
}