<template>
  <div class="w-100 h-100">
    <div
      class="
        w-100
        border-box
        flex
        justify-start
        align-center
        card-shadow
        bg-white
        rounded
        px-1
        overflow-x
      "
      style="height: 50px"
    >
      <div class="flex justify-start align-center">
        <!-- 用户昵称 -->
        <div class="flex justify-start align-center mr-1">
          <div class="mr-1">专业大类:</div>
          <el-input
            style="width: 110px"
            v-model="queryInfo.professional_title"
            placeholder="请输入"
          ></el-input>
        </div>
        <!-- 按钮 -->
        <el-button type="primary" @click="query">
          <el-icon :size="15">
            <component :is="Search"></component> </el-icon
          >搜索
        </el-button>
        <el-button type="primary" @click="reset">重置</el-button>
        <el-button v-if="showAdd" @click="add" type="success">
          <el-icon :size="15">
            <component :is="Plus"></component> </el-icon
          >新增
        </el-button>
        <el-link
          target="_blank"
          href="https://yooike.vip.aeert.com/project/jylx-php/public/professional.xlsx"
          :underline="false"
        >
          <el-button v-if="showAdd" type="primary" class="mx-1"
            >下载导入模版</el-button
          >
        </el-link>
        <input
          @change="change"
          type="file"
          id="file"
          style="display: none"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
        <el-button v-if="showAdd" @click="selectFile" type="success">
          <el-icon :size="15">
            <component :is="Plus"></component> </el-icon
          >导入
        </el-button>
      </div>
    </div>
    <div
      class="w-100 mt-1 border-box card-shadow bg-white rounded p-1"
      style="height: calc(100% - 70px)"
    >
      <!-- 表格 -->
      <div class="w-100" style="height: calc(100% - 60px)">
        <el-table :data="list" class="w-100"  height="100%">
          <el-table-column label="序号" align="center" min-width="100">
            <template #default="scope">
              {{scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column
            v-for="(field, index) in fieldList"
            :key="index"
            :prop="field.prop"
            :label="field.label"
            :width="field.width || ''"
            align="center"
          >
            <template #default="scope">
              <!-- 操作 -->
              <div v-if="field.prop == 'operation'">
                <!-- 修改按钮 -->
                <el-button
                  :disabled="!showEdit"
                  type="primary"
                  size="small"
                  @click="edit(scope.row)"
                  >编辑</el-button
                >
                <!-- 删除按钮 -->
                <el-button
                  :disabled="!showDelete"
                  type="danger"
                  size="small"
                  @click="del(scope.row)"
                  >删除
                </el-button>
              </div>
              <span v-else>{{ scope.row[field.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="flex justify-center align-center" style="height: 50px">
        <el-pagination
          :page-sizes="[10, 50, 100, 200]"
          background
          layout="total, sizes, prev, pager, next"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <addProfessional
      ref="addProfessionals"
      @reacquire="reacquire"
    ></addProfessional>
    <importTable ref="importTables" @reacquire="reacquire"></importTable>
  </div>
</template>

<script setup>
import systemnApi from "@/api/systemnApi.js";
import addProfessional from "./addProfessional/addProfessional.vue";
import XLSX from "@/utils/xlsx.core.min.js";
import importTable from "./importTable/importTable.vue";
import { ref, reactive, onMounted, computed } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { Search, Plus } from "@element-plus/icons-vue";
import { useStore } from "vuex";
const store = useStore();
let addProfessionals = ref(null);
let importTables = ref(null);
let queryInfo = reactive({
  professional_title: "",
  pageNum: 1,
  pageSize: 10,
});

const fieldList = [
  // {
  //   id: 1,
  //   prop: "professional_id",
  //   label: "序号",
  // },
  {
    id: 2,
    prop: "professional_title",
    label: "专业大类",
  },
  {
    id: 3,
    prop: "professional_class",
    label: "专业小类",
  },
  {
    id: 10,
    prop: "operation",
    label: "操作",
    width: 180,
  },
];
let total = ref(0);
let list = ref([]);

let showAdd = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("50102")) {
    return true;
  } else {
    return false;
  }
});

let showEdit = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("50103")) {
    return true;
  } else {
    return false;
  }
});

let showDelete = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("50104")) {
    return true;
  } else {
    return false;
  }
});

function selectFile() {
  document.getElementById("file").click();
}

function change(e) {
  var files = e.target.files;
  if (files.length == 0) return;
  var f = files[0];
  if (!/\.xlsx$/g.test(f.name)) {
    ElMessage.error("仅支持读取xlsx格式！");
    return;
  }
  readWorkbookFromLocalFile(f, function (workbook) {
    readWorkbook(workbook);
  });
  e.target.value = null;
}

// 读取本地excel文件
function readWorkbookFromLocalFile(file, callback) {
  var reader = new FileReader();
  reader.onload = function (e) {
    var data = e.target.result;
    var workbook = XLSX.read(data, { type: "binary" });
    if (callback) callback(workbook);
  };
  reader.readAsBinaryString(file);
}

function readWorkbook(workbook) {
  // 工作表名称集合
  var sheetNames = workbook.SheetNames;
  // 这里我们只读取第一张sheet
  var worksheet = workbook.Sheets[sheetNames[0]];
  // console.log(worksheet);
  var csv = XLSX.utils.sheet_to_csv(worksheet);
  console.log(csv);
  seeImportTable(csv);
}

function seeImportTable(csv) {
  importTables.value.init(csv);
}

function query() {
  queryInfo.pageNum = 1;
  getProfessionalList();
}

function reset() {
  queryInfo.professional_title = "";
  queryInfo.pageNum = 1;
  queryInfo.pageSize = 10;
  getProfessionalList();
}

function getProfessionalList() {
  systemnApi.getProfessionalList(queryInfo).then((res) => {
    list.value = res.data.list;
    total.value = res.data.total;
  });
}

function add() {
  addProfessionals.value.init();
}

function edit(info) {
  addProfessionals.value.init(info);
}

function del(info) {
  ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let data = {
        professional_id: info.professional_id,
      };
      systemnApi.deleteProfessional(data).then((res) => {
        console.log(res);
        ElMessage.success("删除成功");
        reacquire();
      });
    })
    .catch(() => {
      // catch error
    });
}

function handleSizeChange(number) {
  queryInfo.pageSize = number;
  getProfessionalList();
}

function handleCurrentChange(newPage) {
  queryInfo.pageNum = newPage;
  getProfessionalList();
}

function reacquire() {
  queryInfo.pageNum = 1;
  getProfessionalList();
}

onMounted(() => {
  // 获取列表
  getProfessionalList();
});
</script>

