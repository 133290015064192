<template>
  <div class="w-100 h-100">
    <div class="
        w-100
        border-box
        flex
        justify-start
        align-center
        card-shadow
        bg-white
        rounded
        px-1
        overflow-x
      " style="height: 50px">
      <div class="flex justify-start align-center">
        <!-- 用户昵称 -->
        <div class="flex justify-start align-center mr-1">
          <div class="mr-1">订单编号:</div>
          <el-input
            style="width: 110px"
            v-model="queryInfo.purchase_order_number"
            placeholder="请输入"
          ></el-input>
        </div>
        <!-- 用户昵称 -->
        <div class="flex justify-start align-center">
          <div class="mr-1">会员名称:</div>
          <el-input
            style="width: 110px"
            v-model="queryInfo.purchase_member_name"
            placeholder="请输入"
          ></el-input>
        </div>
        <!-- 手机 -->
        <div class="flex justify-start align-center mx-1">
          <div class="mr-1">联系方式:</div>
          <el-input
            style="width: 110px"
            v-model="queryInfo.purchase_member_phone"
            placeholder="请输入"
          ></el-input>
        </div>
        <!-- 按钮 -->
        <el-button type="primary" @click="query">
          <el-icon :size="15"> <component :is="Search"></component> </el-icon
          >搜索
        </el-button>
        <el-button type="primary" @click="reset">重置</el-button>
        <el-button v-if="showEdit" type="primary" @click="setPrice">价格维护</el-button>
      </div>
    </div>
    <div class="w-100 mt-1 border-box card-shadow bg-white rounded p-1" style="height: calc(100% - 70px)">
      <!-- 表格 -->
      <div class="w-100" style="height: calc(100% - 60px)">
        <el-table :data="list" class="w-100"  height="100%">
          <el-table-column label="序号" align="center" min-width="100">
            <template #default="scope">
              {{scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column v-for="(field, index) in fieldList" :key="index" :prop="field.prop" :label="field.label"
            :width="field.width || ''" align="center">
            <template #default="scope">
              <!-- 操作 -->
              <div v-if="field.prop == 'operation'">
              <el-button  type="primary" size="small"
                  @click="seeDetails2(scope.row)">导出报告
                </el-button>
                <el-button  type="primary" size="small"
                  @click="seeDetails(scope.row)">查询详情
                </el-button>
                <!-- 删除按钮 -->
                <el-button :disabled="!showDelete" type="danger" size="small"
                  @click="del(scope.row)">删除
                </el-button>
              </div>
              <!-- 报告 -->
              <div v-else-if="field.prop == 'purchase_report'">
              <!-- 国家 学校 大专业 小专业 -->
                {{ scope.row.purchase_country }}，{{ scope.row.purchase_college }}，{{ scope.row.purchase_professional }}，{{ scope.row.purchase_class }}，{{ scope.row.purchase_major_name }}
              </div>
              <div v-else-if="field.prop == 'purchase_status'">
                {{ scope.row.purchase_status ? '已支付' : "未支付" }}
              </div>
              
              <span v-else>{{ scope.row[field.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="flex justify-center align-center" style="height: 50px">
        <el-pagination :page-sizes="[10, 50, 100, 200]" background layout="total, sizes, prev, pager, next"
          :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
    <priceMaintenance ref="priceMaintenances" @reacquire="reacquire"></priceMaintenance>
    <priceConfig ref="priceConfigs" @reacquire="reacquire"></priceConfig>
    <exportReport ref="exportReports" @reacquire="reacquire"></exportReport>
  </div>
</template>

<script setup>
import memberApi from "@/api/memberApi.js";
import priceMaintenance from "./priceMaintenance/priceMaintenance.vue";
import priceConfig from "./priceConfig/priceConfig.vue";
import exportReport from "./exportReport/exportReport.vue";
import { ref, reactive, onMounted, computed } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { Search } from "@element-plus/icons-vue";
import { useStore } from "vuex";
const store = useStore();
let priceMaintenances = ref(null);
let priceConfigs = ref(null);
let exportReports = ref(null);
let queryInfo = reactive({
  purchase_order_number: "",
  purchase_member_name: "",
  purchase_member_phone: "",
  pageNum: 1,
  pageSize: 10,
});
const fieldList = [
  // {
  //   id: 1,
  //   prop: "purchase_id",
  //   label: "序号",
  // },
  {
    id: 2,
    prop: "purchase_order_number",
    label: "订单编号",
  },
  {
    id: 3,
    prop: "purchase_member_name",
    label: "客户姓名",
  },
  {
    id: 4,
    prop: "purchase_member_phone",
    label: "联系方式",
  },
  {
    id: 5,
    prop: "purchase_member_mailbox",
    label: "邮箱",
  },
  {
    id: 6,
    prop: "purchase_report",
    label: "查询报告",
  },
   {
    id: 7,
    prop: "purchase_status",
    label: "支付状态",
  },
  {
    id: 10,
    prop: "operation",
    label: "操作",
    width: 240,
  },
];
let total = ref(0);
let list = ref([]);

// let showAdd = computed(() => {
//   if (store.state.userInfo.role_ascription) {
//     return true;
//   } else if (store.getters.role_menu_ids.indexOf("30102")) {
//     return true;
//   } else {
//     return false;
//   }
// });

let showEdit = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("30103")) {
    return true;
  } else {
    return false;
  }
});

let showDelete = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("30104")) {
    return true;
  } else {
    return false;
  }
});

function seeDetails(info) {
  priceMaintenances.value.init(info)
}

function seeDetails2(info) {
  exportReports.value.init(info)
}

function setPrice() {
  priceConfigs.value.init('excel')
}


function query() {
  queryInfo.pageNum = 1;
  getPurchaseList();
}

function reset() {
  queryInfo. purchase_order_number = "";
  queryInfo.purchase_member_name = "";
  queryInfo.purchase_member_phone = "";
  queryInfo.pageNum = 1;
  queryInfo.pageSize = 10;
  getPurchaseList();
}

function getPurchaseList() {
  memberApi.getPurchaseList(queryInfo).then((res) => {
    list.value = res.data.list;
    total.value = res.data.total;
  });
}

function del(info) {
  ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let data = {
        purchase_id: info.purchase_id
      }
      memberApi.deletePurchase(data).then((res) => {
        console.log(res);
        ElMessage.success("删除成功");
        reacquire();
      });
    })
    .catch(() => {
      // catch error
    });
}

function handleSizeChange(number) {
  queryInfo.pageSize = number;
  getPurchaseList();
}

function handleCurrentChange(newPage) {
  queryInfo.pageNum = newPage;
  getPurchaseList();
}

function reacquire() {
  queryInfo.pageNum = 1;
  getPurchaseList();
}

onMounted(() => {
  // 获取列表
  getPurchaseList();
});
</script>

