<template>
  <div>
    <!-- 对话框 -->
    <el-dialog
      v-model="dialogVisible"
      title="匹配规则"
      width="1100px"
      @close="dialogClosed"
      top="10vh"
    >
      <!-- 表单数据 -->
      <!-- 内容主体区域 -->
      <el-table :data="list" class="w-100" height="450px">
        <el-table-column
          v-for="(field, index) in fieldList"
          :key="index"
          :prop="field.prop"
          :label="field.label"
          :width="field.width || ''"
          align="center"
        >
          <template #default="scope">
            <!-- 操作 -->
            <div v-if="field.prop == 'operation'">
              <!-- 修改按钮 -->
              <el-button type="primary" size="small" @click="edit(scope.row)"
                >编辑</el-button
              >
            </div>
            <div v-else-if="field.prop == 'matching_fraction'">
              {{ getMatchingFraction(scope.row) }}
            </div>
            <div v-else-if="field.prop == 'matching_ranking'">
              {{ getMatchingRanking(scope.row) }}
            </div>
            <span v-else>{{ scope.row[field.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 底部区域 -->
      <template #footer>
        <span>
          <el-button @click="cancel()">关 闭</el-button>
        </span>
      </template>
    </el-dialog>
    <setMatching ref="setMatchings" @reacquire="reacquire"></setMatching>
  </div>
</template>
<script setup>
import memberApi from "@/api/memberApi.js";
import setMatching from "./setMatching/setMatching.vue";
import { ref, reactive, defineEmits, defineExpose } from "vue";
const emit = defineEmits(["reacquire"]);
let setMatchings = ref(null);
let dialogVisible = ref(false);
let queryInfo = reactive({
  matching_group: "",
});

let fieldList = ref([
  {
    id: 1,
    prop: "matching_fraction",
    label: "用户得分",
  },
  {
    id: 2,
    prop: "matching_ranking",
    label: "显示“QS世界排名”如下的学校对应的专业",
  },
  {
    id: 10,
    prop: "operation",
    label: "操作",
    width: 180,
  },
]);

let list = ref([]);

function getMatchingFraction(info) {
  if (info.matching_type == "max") {
    return `>=${info.matching_min_fraction}`;
  } else if (info.matching_type == "min") {
    return `<=${info.matching_max_fraction}`;
  } else {
    return `${info.matching_max_fraction}~${info.matching_min_fraction}`;
  }
}

function getMatchingRanking(info) {
  if(info.matching_group == 'high') {
    return info.matching_ranking_grade;
  } else {
    if (info.matching_type == "max") {
    return `<=${info.matching_min_ranking}`;
  } else if (info.matching_type == "min") {
    return `>=${info.matching_max_ranking}`;
  } else {
    return `${info.matching_max_ranking}~${info.matching_min_ranking}`;
  }
  }
  
}

function init(group) {
  queryInfo.matching_group = group;
  getMatchingList();
}

function edit(info) {
  setMatchings.value.init(JSON.parse(JSON.stringify(info)));
}

function getMatchingList() {
  memberApi.getMatchingList(queryInfo).then((res) => {
    console.log(res);
    list.value = res.data;
    dialogVisible.value = true;
  });
}

function reacquire() {
  getMatchingList();
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

function dialogClosed() {
  emit("reacquire");
}

defineExpose({
  init,
});
</script>
