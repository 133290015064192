<template>
  <div class="w-100 h-100">
    <div class="
        w-100
        border-box
        flex
        justify-start
        align-center
        card-shadow
        bg-white
        rounded
        px-1
        overflow-x
      " style="height: 50px">
      <div class="flex justify-start align-center">
        <!-- 1 -->
        <div class="flex justify-start align-center">
          <div class="mr-1">世界排名:</div>
          <el-input type="number" style="width: 100px" v-model="queryInfo.start_ranking" placeholder="请输入"></el-input>
          <div class="mx-1">-</div>
          <el-input type="number" style="width: 100px" v-model="queryInfo.end_ranking" placeholder="请输入"></el-input>
        </div>
        <!-- 2 -->
        <div class="flex justify-start align-center ml-1">
          <div class="mr-1">院校名称:</div>
          <el-input style="width: 110px" v-model="queryInfo.application_name" placeholder="请输入"></el-input>
        </div>
        <!-- 3 -->
        <div class="flex justify-start align-center">
          <div class="mx-1">所属国家:</div>
          <el-select class="mr-1" v-model="queryInfo.application_country" style="width: 110px" placeholder="Select">
            <el-option v-for="item in application_country_options" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
        <!-- 4 -->
        <div class="flex justify-start align-center">
          <div class="mr-1">院校属性:</div>
          <el-select class="mr-1" v-model="queryInfo.application_attribute" style="width: 110px" placeholder="Select">
            <el-option v-for="item in application_attribute_options" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
        <!-- 5 -->
        <div class="flex justify-start align-center mr-1">
          <div class="mr-1">专业大类:</div>
          <el-input style="width: 110px" v-model="queryInfo.application_professional" placeholder="请输入"></el-input>
        </div>
        <div class="flex justify-start align-center mr-1">
          <div class="mr-1">专业小类:</div>
          <el-input style="width: 110px" v-model="queryInfo.application_class" placeholder="请输入"></el-input>
        </div>
        <!-- 按钮 -->
        <el-button type="primary" @click="query">
          <el-icon :size="15">
            <component :is="Search"></component>
          </el-icon>搜索
        </el-button>
        <el-button type="primary" @click="reset">重置</el-button>
        <el-button v-if="showAdd" @click="add" type="success">
          <el-icon :size="15">
            <component :is="Plus"></component>
          </el-icon>新增
        </el-button>
        <el-link target="_blank" href="https://api.yooike.com/jylx-php/public/application.xlsx"
          :underline="false">
          <el-button v-if="showAdd" type="primary" class="mx-1">下载导入模版</el-button>
        </el-link>
        <input @change="change" type="file" id="file" style="display: none"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
        <el-button v-if="showAdd" @click="selectFile" type="success">
          <el-icon :size="15">
            <component :is="Plus"></component>
          </el-icon>导入
        </el-button>
        <el-button v-if="showDelete" @click="daleteAll" type="danger">
          批量删除
        </el-button>
      </div>
    </div>
    <div class="w-100 mt-1 border-box card-shadow bg-white rounded p-1" style="height: calc(100% - 70px)">
      <!-- 表格 -->
      <div class="w-100" style="height: calc(100% - 60px)">
       
        <el-table :data="list" class="w-100 h-100" height="100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="序号" align="center" min-width="100">
            <template #default="scope">
              {{scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column v-for="(field, index) in fieldList" :key="index" :prop="field.prop" :label="field.label"
            :width="field.width || ''" align="center">
            <template #default="scope">
              {{ scope.row[field.prop] }}
            </template>

          </el-table-column>
          <el-table-column width="180" fixed="right" label="操作" align="center">
            <template #default="scope">
              <!-- 修改按钮 -->
              <el-button :disabled="!showEdit" type="primary" size="small" @click="edit(scope.row)">编辑</el-button>
              <!-- 删除按钮 -->
              <el-button :disabled="!showDelete" type="danger" size="small" @click="del(scope.row)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="flex justify-center align-center" style="height: 50px">
        <el-pagination :page-sizes="[10, 50, 100, 200]" background layout="total, sizes, prev, pager, next"
          :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
    <addApplication ref="addApplications" @reacquire="reacquire"></addApplication>
    <importTable ref="importTables" :fieldList="fieldList" @setLoading="setLoading" @reacquire="reacquire">
    </importTable>
  </div>
</template>

<script setup>
import systemnApi from "@/api/systemnApi.js";
import XLSX from "@/utils/xlsx.core.min.js";
import addApplication from "./addApplication/addApplication.vue";
import importTable from "./importTable/importTable.vue";
import { ref, reactive, onMounted, computed } from "vue";
import { ElMessageBox, ElMessage, ElLoading } from "element-plus";
import { Search, Plus } from "@element-plus/icons-vue";
import { useStore } from "vuex";
const store = useStore();
let addApplications = ref(null);
let importTables = ref(null);

let queryInfo = reactive({
  application_name: "",
  application_country: "",
  application_attribute: "",
  application_professional: "",
  application_class: "",
  start_ranking: "",
  end_ranking: "",
  pageNum: 1,
  pageSize: 10,
});

let loading = ref(null)

let application_country_options = ref([
  {
    value: "",
    label: "全部",
  },
]);
let application_attribute_options = ref([
  {
    value: "",
    label: "全部",
  },
]);

const fieldList = [
  // {
  //   id: 1,
  //   prop: "application_id",
  //   label: "序号",
  // },
  {
    id: 2,
    prop: "application_ranking",
    label: "qs世界排名",
    width: 150
  },
  {
    id: 31,
    prop: "application_high_attribute",
    label: "中学属性",
    width: 150
  },
  {
    id: 3,
    prop: "application_name",
    label: "院校名称",
    width: 150
  },
  {
    id: 4,
    prop: "application_en_name",
    label: "院校英文名称",
    width: 260
  },
  {
    id: 5,
    prop: "application_attribute",
    label: "院校属性",
  },
  {
    id: 6,
    prop: "application_country",
    label: "所属国家",
  },
  {
    id: 7,
    prop: "application_province",
    label: "省/州",
  },
  {
    id: 8,
    prop: "application_city",
    label: "城市",
  },
  {
    id: 9,
    prop: "application_professional",
    label: "佳易专业大类",
    width: 180
  },

  {
    id: 11,
    prop: "application_class",
    label: "佳易专业小类",
    width: 180
  },
  {
    id: 12,
    prop: "application_major_name",
    label: "专业中文名称",
    width: 300,
  },
  {
    id: 13,
    prop: "application_major_en_name",
    label: "专业名称",
    width: 300,
  },
  {
    id: 14,
    prop: "application_faculty",
    label: "所属院系",
    width: 300,
  },
  {
    id: 15,
    prop: "application_education",
    label: "学历/学位（中文）",
    width: 180,
  },
  {
    id: 16,
    prop: "application_en_education",
    label: "学历/学位（英文）",
    width: 180,
  },
  {
    id: 17,
    prop: "application_introduce",
    label: "专业/学校介绍",
    width: 400
  },
  {
    id: 18,
    prop: "application_course",
    label: "课程长度",
  },
  {
    id: 31,
    prop: "application_tuition",
    label: "学费",
  },
  {
    id: 19,
    prop: "application_cost",
    label: "申请费（美元）",
    width: 180,
  },
  {
    id: 20,
    prop: "application_start_date",
    label: "开学期",
    width: 250,
  },
  {
    id: 21,
    prop: "application_end_date",
    label: "截止日期",
    width: 250,
  },
  {
    id: 22,
    prop: "application_GRA",
    label: "GRA",
    width: 250,
  },
  {
    id: 23,
    prop: "application_TOEFL",
    label: "托福",
    width: 250
  },
  {
    id: 24,
    prop: "application_IELTS",
    label: "雅思",
    width: 250
  },
  {
    id: 25,
    prop: "application_GRE",
    label: "GRE",
  },
  {
    id: 26,
    prop: "application_GMAT",
    label: "GMAT",
  },
  {
    id: 32,
    prop: "application_SAT",
    label: "SAT",
  },
  {
    id: 33,
    prop: "application_ACT",
    label: "ACT",
  },
  {
    id: 27,
    prop: "application_academic_background",
    label: "学术背景",
    width: 400
  },
  {
    id: 28,
    prop: "application_material_requirements",
    label: "材料要求",
    width: 400
  },
  {
    id: 29,
    prop: "application_enrollment_website",
    label: "招生网址",
    width: 400
  },
  {
    id: 30,
    prop: "application_professional_website",
    label: "专业网址",
    width: 400
  },
];
let total = ref(0);
let list = ref([]);

let multipleSelection = ref([]);

let showAdd = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("50302")) {
    return true;
  } else {
    return false;
  }
});

let showEdit = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("50303")) {
    return true;
  } else {
    return false;
  }
});

let showDelete = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("50304")) {
    return true;
  } else {
    return false;
  }
});

function handleSelectionChange(val) {
  multipleSelection.value = val;
}

// function daleteAll() {
//   ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
//     confirmButtonText: "确认",
//     cancelButtonText: "取消",
//     type: "warning",
//   })
//     .then(() => {
//       const deleteids = multipleSelection.value
//         .map((item) => {
//           return item.application_id;
//         })
//         .join(",");
//       delList(deleteids);
//     })
//     .catch(() => {
//       // catch error
//     });
// }


function daleteAll() {
  ElMessageBox.confirm("确定删除包含条件的所有数据吗（未选择条件则删除全部数据）？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      systemnApi.deleteApplicationByInfo(queryInfo).then((res) => {
        console.log(res);
        reacquire();
      });
    })
    .catch(() => {
      // catch error
    });
}

function delList(ids) {
  systemnApi.deleteApplicationList({ ids }).then((res) => {
    console.log(res);
    reacquire();
  });
}

const getSelectByKeyList = async (table, key) => {
  let data = { table, key };
  const res = await systemnApi.getSelectByKeyList(data);
  if (key == "application_country") {
    application_country_options.value = [
      {
        value: "",
        label: "全部",
      },
    ];
    for (const key in res.data) {
      let item = {
        value: res.data[key].application_country,
        label: res.data[key].application_country,
      };
      application_country_options.value.push(item);
    }
  } else {
    application_attribute_options.value = [
      {
        value: "",
        label: "全部",
      },
    ];
    for (const key in res.data) {
      let item = {
        value: res.data[key].application_attribute,
        label: res.data[key].applicatione_attribute,
      };
      application_attribute_options.value.push(item);
    }
  }
};

function selectFile() {
  document.getElementById("file").click();
}

function setLoading() {
  loading.value.close()
}

function change(e) {
  loading.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })

  var files = e.target.files;
  if (files.length == 0) return;
  var f = files[0];
  if (!/\.xlsx$/g.test(f.name)) {
    ElMessage.error("仅支持读取xlsx格式！");
    return;
  }
  readWorkbookFromLocalFile(f, function (workbook) {
    readWorkbook(workbook);
  });
  e.target.value = null;
}

// 读取本地excel文件
function readWorkbookFromLocalFile(file, callback) {
  var reader = new FileReader();
  reader.onload = function (e) {
    var data = e.target.result;
    var workbook = XLSX.read(data, { type: "binary" });
    if (callback) callback(workbook);
  };
  reader.readAsBinaryString(file);
}

function readWorkbook(workbook) {
  // 工作表名称集合
  var sheetNames = workbook.SheetNames;
  // 这里我们只读取第一张sheet
  var worksheet = workbook.Sheets[sheetNames[0]];
  console.log(XLSX.utils);
  // var csv = XLSX.utils.sheet_to_csv(worksheet);
  var list = XLSX.utils.sheet_to_row_object_array(worksheet);

  // var json = XLSX.utils.sheet_to_json(worksheet);
  seeImportTable(list);
}

function seeImportTable(list) {
  importTables.value.init(list);
}

function query() {
  queryInfo.pageNum = 1;
  getApplicationList();
}

function reset() {
  queryInfo.application_name = "";
  queryInfo.application_country = "";
  queryInfo.application_attribute = "";
  queryInfo.application_professional = "";
  queryInfo.application_class = "";
  queryInfo.start_ranking = "";
  queryInfo.end_ranking = "";
  queryInfo.pageNum = 1;
  queryInfo.pageSize = 10;
  getApplicationList();
}

function getApplicationList() {
  systemnApi.getApplicationList(queryInfo).then((res) => {
    list.value = res.data.list;
    total.value = res.data.total;
    // 根据关键词获取筛选数据(table, key)
    getSelectByKeyList("application", "application_country");
    getSelectByKeyList("application", "application_attribute");
  });
}

function add() {
  addApplications.value.init(null, total.value + 1);
}

function edit(info) {
  addApplications.value.init(info);
}

function del(info) {
  ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let data = {
        application_id: info.application_id,
      };
      systemnApi.deleteApplication(data).then((res) => {
        console.log(res);
        ElMessage.success("删除成功");
        reacquire();
      });
    })
    .catch(() => {
      // catch error
    });
}

function handleSizeChange(number) {
  queryInfo.pageSize = number;
  getApplicationList();
}

function handleCurrentChange(newPage) {
  queryInfo.pageNum = newPage;
  getApplicationList();
}

function reacquire() {
  queryInfo.pageNum = 1;
  getApplicationList();
}

onMounted(() => {
  // 获取列表
  getApplicationList();
});
</script>

