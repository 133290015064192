<template>
  <!-- 对话框 -->
  <el-dialog v-model="dialogVisible" :title="title" width="600px" @close="dialogClosed">
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="120px">
      <el-form-item label="院校排名：" prop="college_ranking">
        <el-input type="number" v-model="form.college_ranking" placeholder="请输入院校排名"></el-input>
      </el-form-item>
      <el-form-item label="院校名称：" prop="college_name">
        <el-input v-model="form.college_name" placeholder="请输入院校名称"></el-input>
      </el-form-item>
      <el-form-item label="英文名称：" prop="college_en_name">
        <el-input v-model="form.college_en_name" placeholder="请输入院校英文名称"></el-input>
      </el-form-item>
     <el-form-item label="院校属性：" prop="college_attribute">
        <el-input v-model="form.college_attribute" placeholder="请输入院校属性"></el-input>
      </el-form-item>
      <el-form-item label="所载国家：" prop="college_country">
        <el-input v-model="form.college_country" placeholder="请输入院校所载国家"></el-input>
      </el-form-item>
      <el-form-item label="所在省/州：" prop="college_province">
        <el-input v-model="form.college_province" placeholder="请输入院校省/州"></el-input>
      </el-form-item>
      <el-form-item label="所在城市：" prop="college_city">
        <el-input v-model="form.college_city" placeholder="请输入院校所在城市"></el-input>
      </el-form-item>
      <!-- <el-form-item label="备注：" prop="college_remarks">
        <el-input type="textarea" :rows="4" v-model="form.college_remarks" placeholder="请输入"></el-input>
      </el-form-item> -->
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import systemnApi from "@/api/systemnApi.js";
import { ref, reactive, defineEmits, defineExpose, computed } from "vue";
import { ElMessage } from "element-plus";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let title = computed(() => {
  if (form.value.college_id == 0) {
    return "院校添加";
  } else {
    return "院校修改";
  }
});
let formRef = ref(null);
let form = ref({
  college_id: 0,
  college_ranking: "",
  college_name: "",
  college_en_name: "",
  college_attribute: "",
  college_country: "",
  college_province: "",
  college_city: "",
  college_remarks: "",
});
const formRules = reactive({
  college_ranking: [{ required: true, message: "请输入院校排名", trigger: "blur" }],
  college_name: [{ required: true, message: "请输入院校名称", trigger: "blur" }],
  college_attribute: [{ required: true, message: "请输入院校属性", trigger: "blur" }],
  college_country: [{ required: true, message: "请输入院校所属国家", trigger: "blur" }],
  college_province: [{ required: true, message: "请输入院校所在省/州", trigger: "blur" }],
  college_city: [{ required: true, message: "请输入院校所在城市", trigger: "blur" }],
});

function init(info = null) {
  if (info) {
    form.value = info;
  }
  dialogVisible.value = true;
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      if (form.value.college_id && form.value.college_id != 0) {
        updateCollege();
      } else {
        addCollege();
      }
    } else {
      return false;
    }
  });
}
function updateCollege() {
  systemnApi.updateCollege(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}
function addCollege() {
  systemnApi.addCollege(form.value).then(() => {
    dialogVisible.value = false;
  });
}
function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    college_id: 0,
    college_ranking: "",
    college_name: "",
    college_en_name: "",
    college_attribute: "",
    college_country: "",
    college_province: "",
    college_city: "",
    college_remarks: "",
  };
  emit("reacquire");
}
defineExpose({
  init,
});
</script>
