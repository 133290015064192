import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './router'



const router = createRouter({
  history: createWebHashHistory(),
  routes:routes
})

router.beforeEach((to, from, next) => {
  let token = window.localStorage.getItem('token');
  if (token) {
    next()
  } else {
    // 如果用户token不存在则跳转到login页面
    if (to.path === '/login') {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
