<template>
  <div class="w-100 h-100">
    <div class="
        w-100
        border-box
        flex
        justify-start
        align-center
        card-shadow
        bg-white
        rounded
        px-1
        overflow-x
      " style="height: 50px">
      <div class="flex justify-start align-center">
        <!-- 用户昵称 -->
        <div class="flex justify-start align-center">
          <div class="mr-1">会员名称:</div>
          <el-input
            style="width: 110px"
            v-model="queryInfo.member_name"
            placeholder="请输入"
          ></el-input>
        </div>
        <!-- 手机 -->
        <div class="flex justify-start align-center mx-1">
          <div class="mr-1">联系方式:</div>
          <el-input
            style="width: 110px"
            v-model="queryInfo.member_phone"
            placeholder="请输入"
          ></el-input>
        </div>
        <!-- 按钮 -->
        <el-button type="primary" @click="query">
          <el-icon :size="15"> <component :is="Search"></component> </el-icon
          >搜索
        </el-button>
        <el-button type="primary" @click="reset">重置</el-button>
        <el-button
          v-if="showDelete && multipleSelection.length > 0"
          @click="daleteAll"
          type="danger"
        >
          批量删除
        </el-button>
      </div>
    </div>
    <div class="w-100 mt-1 border-box card-shadow bg-white rounded p-1" style="height: calc(100% - 70px)">
      <!-- 表格 -->
      <div class="w-100" style="height: calc(100% - 60px)">
        <el-table :data="list" class="w-100"  height="100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="序号" align="center" min-width="100">
            <template #default="scope">
              {{scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column v-for="(field, index) in fieldList" :key="index" :prop="field.prop" :label="field.label"
            :width="field.width || ''" align="center">
            <template #default="scope">
              <!-- 操作 -->
              <div v-if="field.prop == 'operation'">
                <!-- 按钮 -->
                <el-button  type="primary" size="small" @click="seeDetails(scope.row)">查看详情</el-button>
              </div>
              <span v-else>{{ scope.row[field.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="flex justify-center align-center" style="height: 50px">
        <el-pagination :page-sizes="[10, 50, 100, 200, 500]" background layout="total, sizes, prev, pager, next"
          :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
    <detailsTable ref="detailsTables" @reacquire="reacquire"></detailsTable>
  </div>
</template>

<script setup>
import memberApi from "@/api/memberApi.js";
import { ref, reactive, onMounted, computed } from "vue";
import detailsTable from "./detailsTable/detailsTable.vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { Search} from "@element-plus/icons-vue";
import { useStore } from "vuex";
const store = useStore();
let detailsTables = ref(null);
let queryInfo = reactive({
  member_name: "",
  member_phone: "",
  pageNum: 1,
  pageSize: 10,
});
const fieldList = [
  // {
  //   id: 1,
  //   prop: "focus_id",
  //   label: "序号",
  // },
  {
    id: 2,
    prop: "member_open_id",
    label: "会员编号",
  },
  {
    id: 3,
    prop: "member_name",
    label: "姓名",
  },
  {
    id: 4,
    prop: "member_phone",
    label: "联系方式",
  },
  {
    id: 5,
    prop: "application_name",
    label: "院校名称",
  },
  {
    id: 6,
    prop: "application_professional",
    label: "目标专业方向",
  },
  {
    id: 7,
    prop: "application_major_name",
    label: "具体专业名称",
  },
  {
    id: 10,
    prop: "operation",
    label: "操作",
    width: 180,
  },
];
let total = ref(0);
let list = ref([]);

let multipleSelection = ref([]);

// let showAdd = computed(() => {
//   if (store.state.userInfo.role_ascription) {
//     return true;
//   } else if (store.getters.role_menu_ids.indexOf("20102")) {
//     return true;
//   } else {
//     return false;
//   }
// });

// let showEdit = computed(() => {
//   if (store.state.userInfo.role_ascription) {
//     return true;
//   } else if (store.getters.role_menu_ids.indexOf("20103")) {
//     return true;
//   } else {
//     return false;
//   }
// });

let showDelete = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("20104")) {
    return true;
  } else {
    return false;
  }
});

function handleSelectionChange(val) {
  console.log(val);
  multipleSelection.value = val;
}

function daleteAll() {
  ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      const deleteids = multipleSelection.value
        .map((item) => {
          return item.focus_id;
        })
        .join(",");
      console.log(deleteids);
      delList(deleteids);
    })
    .catch(() => {
      // catch error
    });
}

function delList(ids) {
  memberApi.deleteFocusList({ ids }).then((res) => {
    ElMessage.success("删除成功");
    console.log(res);
    reacquire();
  });
}

function query() {
  queryInfo.pageNum = 1;
  getFocusList();
}

function reset() {
  queryInfo.member_name = "";
  queryInfo.member_phone = "";
  queryInfo.pageNum = 1;
  queryInfo.pageSize = 10;
  getFocusList();
}

function seeDetails(info) {
  detailsTables.value.init(info)
}

function getFocusList() {
  memberApi.getFocusList(queryInfo).then((res) => {
    list.value = res.data.list;
    total.value = res.data.total;
  });
}

function handleSizeChange(number) {
  queryInfo.pageSize = number;
  getFocusList();
}

function handleCurrentChange(newPage) {
  queryInfo.pageNum = newPage;
  getFocusList();
}

function reacquire() {
  queryInfo.pageNum = 1;
  getFocusList();
}

onMounted(() => {
  // 获取列表
  getFocusList();
});
</script>

