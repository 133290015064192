<template>
  <!-- 对话框 -->
  <el-dialog
    v-model="dialogVisible"
    title="导入专业信息"
    width="1100px"
    @close="dialogClosed"
  >
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-table :data="list">
      <el-table-column
        v-for="(field, index) in fieldList"
        :key="index"
        :prop="field.prop"
        :label="field.label"
        :width="field.width || ''"
        align="center"
      >
        <template #default="scope">
          {{ scope.row[field.prop] }}
        </template>
      </el-table-column>
    </el-table>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import systemnApi from "@/api/systemnApi.js";
import { ref, defineEmits, defineExpose } from "vue";
import { ElMessage } from "element-plus";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
const keyList = [
  "professional_title",
  "professional_class",
];
let fieldList = ref([]);
let list = ref([]);

function init(info = null) {
  if (info) {
    csv2table(info);
  }
  dialogVisible.value = true;
}
// 将csv转换成表格
function csv2table(csv) {
  // console.log(csv);
  var rows = csv.split("\n");
  // 去除最后一行空字符串
  rows.pop();
  // console.log(rows);
  // 封装成element表格格式
  let fieldInfo = rows[0].split(",").filter((str) => {
    return str && str.trim();
  });
  // console.log(fieldInfo);
  for (const index in fieldInfo) {
    fieldList.value.push({
      id: Number(index) + 1,
      prop: keyList[index] ? keyList[index] : "",
      label: fieldInfo[index],
    });
  }
  // console.log(fieldList.value);
  // 渲染列表
  rows.forEach((row, index) => {
    if (index == 0) {
      return;
    }
    // console.log(row, index);
    const dataList = row.split(",");
    let obj = {};
    for (const index in keyList) {
      obj[keyList[index]] = dataList[index];
    }
    list.value.push(obj);
    console.log('list.value', list.value)
  });
}
// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  systemnApi.addProfessionalList({list: list.value}).then((res) => {
    console.log(res);
    dialogVisible.value = false;
  }).catch(err => {
    ElMessage.error('到入失败，请检查数据格式')
    console.log(err)
  })
}

function dialogClosed() {
  fieldList.value = [];
  list.value = [];
  emit("reacquire");
}
defineExpose({
  init,
});
</script>

<style>
.canvasTable {
  border-collapse: collapse;
}
.canvasTable th,
.canvasTable td {
  border: solid 1px #6d6d6d;
  padding: 5px 10px;
}
</style>
