<template>
  <!-- 对话框 -->
  <el-dialog v-model="dialogVisible" :title="title" width="1100px" @close="dialogClosed">
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="120px">
      <div class="flex">
        <div class="flex-1">
          <el-form-item label="世界排名：" prop="application_ranking">
            <el-input type="number" v-model="form.application_ranking" placeholder="请输入世界排名"></el-input>
          </el-form-item>
          <el-form-item label="院校属性：" prop="application_attribute">
            <el-input v-model="form.application_attribute" placeholder="请输入院校属性"></el-input>
          </el-form-item>
          <el-form-item label="所在国家：" prop="application_country">
            <el-input v-model="form.application_country" placeholder="请输入所在国家"></el-input>
          </el-form-item>
          <el-form-item label="所属院系：" prop="application_faculty">
            <el-input v-model="form.application_faculty" placeholder="请输入所属院系"></el-input>
          </el-form-item>
          <el-form-item label="课程长度：" prop="application_course">
            <el-input v-model="form.application_course" placeholder="请输入课程长度"></el-input>
          </el-form-item>
          <el-form-item label="开学期：" prop="application_start_date">
            <el-input v-model="form.application_start_date" placeholder="请输入开学期"></el-input>
          </el-form-item>
          <el-form-item label="结束期：" prop="application_end_date">
            <el-input v-model="form.application_end_date" placeholder="请输入结束期"></el-input>
          </el-form-item>
          <el-form-item label="雅思：" prop="application_IELTS">
            <el-input v-model="form.application_IELTS" placeholder="请输入雅思"></el-input>
          </el-form-item>
          <el-form-item label="SAT：" prop="application_SAT">
            <el-input v-model="form.application_SAT" placeholder="请输入SAT"></el-input>
          </el-form-item>
          <el-form-item label="招生网址：" prop="application_enrollment_website">
            <el-input v-model="form.application_enrollment_website" placeholder="请输入招生网址"></el-input>
          </el-form-item>
          <el-form-item label="院校介绍：" prop="application_introduce">
            <el-input type="textarea" :rows="4" v-model="form.application_introduce" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
        <div class="flex-1">
          <el-form-item label="院校名称：" prop="application_name">
            <el-input v-model="form.application_name" placeholder="请输入院校名称"></el-input>
          </el-form-item>
          <el-form-item label="专业名称：" prop="application_major_name">
            <el-input v-model="form.application_major_name" placeholder="请输入专业名称"></el-input>
          </el-form-item>
          <el-form-item label="所在省份：" prop="application_province">
            <el-input v-model="form.application_province" placeholder="请输入所在省份"></el-input>
          </el-form-item>
          <el-form-item label="学历/学位：" prop="application_education">
            <el-input v-model="form.application_education" placeholder="请输入学历/学位"></el-input>
          </el-form-item>
          <el-form-item label="专业大类：" prop="application_professional">
            <el-input v-model="form.application_professional" placeholder="请输入">
            </el-input>
          </el-form-item>
          <el-form-item label="学费：" prop="application_tuition">
            <el-input v-model="form.application_tuition" placeholder="请输入学费"></el-input>
          </el-form-item>
          <el-form-item label="GRA：" prop="application_GRA">
            <el-input v-model="form.application_GRA" placeholder="请输入GRA"></el-input>
          </el-form-item>
          <el-form-item label="托福：" prop="application_TOEFL">
            <el-input v-model="form.application_TOEFL" placeholder="请输入托福"></el-input>
          </el-form-item>
          <el-form-item label="ACT：" prop="application_ACT">
            <el-input v-model="form.application_ACT" placeholder="请输入ACT"></el-input>
          </el-form-item>
          <el-form-item label="专业网址：" prop="application_professional_website">
            <el-input v-model="form.application_professional_website" placeholder="请输入专业网址"></el-input>
          </el-form-item>
          <el-form-item label="材料要求：" prop="application_material_requirements">
            <el-input type="textarea" :rows="4" v-model="form.application_material_requirements" placeholder="请输入">
            </el-input>
          </el-form-item>
        </div>
        <div class="flex-1">
          <el-form-item label="英文名称：" prop="application_en_name">
            <el-input v-model="form.application_en_name" placeholder="请输入英文名称"></el-input>
          </el-form-item>
          <el-form-item label="专业英文名称：" prop="application_major_en_name">
            <el-input v-model="form.application_major_en_name" placeholder="请输入专业英文名称"></el-input>
          </el-form-item>
          <el-form-item label="所在城市：" prop="application_city">
            <el-input v-model="form.application_city" placeholder="请输入所在城市"></el-input>
          </el-form-item>
          <el-form-item label="学历/学位(EN)：" prop="application_en_education">
            <el-input v-model="form.application_en_education" placeholder="请输入学历/学位(英文)"></el-input>
          </el-form-item>
          <el-form-item label="专业小类：" prop="application_class">
            <el-input v-model="form.application_class" placeholder="请输入">
            </el-input>
          </el-form-item>
          <el-form-item label="申请费用：" prop="application_cost">
            <el-input v-model="form.application_cost" placeholder="请输入申请费用"></el-input>
          </el-form-item>
          <el-form-item label="GRE：" prop="application_GRE">
            <el-input v-model="form.application_GRE" placeholder="请输入GRE"></el-input>
          </el-form-item>
          <el-form-item label="GMAT：" prop="application_GMAT">
            <el-input v-model="form.application_GMAT" placeholder="请输入GMAT"></el-input>
          </el-form-item>
          <el-form-item label="高中属性：" prop="application_high_attribute">
            <el-select
            class="w-100"
            v-model="form.application_high_attribute"
            placeholder="请选择高中属性"
          >
            <el-option
              v-for="item in high_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="学术背景：" prop="application_academic_background">
            <el-input type="textarea" :rows="4" v-model="form.application_academic_background" placeholder="请输入学术背景">
            </el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import systemnApi from "@/api/systemnApi.js";
import { ref, reactive, defineEmits, defineExpose, computed } from "vue";
import { ElMessage } from "element-plus";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let title = computed(() => {
  if (form.value.application_id == 0) {
    return "院校申请信息添加";
  } else {
    return "院校申请信息修改";
  }
});

let high_options = ref([
  {
    value: "A",
    label: "A",
  },
  {
    value: "B",
    label: "B",
  },
]);
let formRef = ref(null);
let form = ref({
  application_id: 0,
  application_ranking: "",
  application_name: "",
  application_en_name: "",
  application_attribute: "",
  application_country: "",
  application_province: "",
  application_city: "",
  application_professional: "",
  application_class: "",
  application_major_name: "",
  application_major_en_name: "",
  application_faculty: "",
  application_education: "",
  application_en_education: "",
  application_introduce: "",
  application_course: "",
  application_tuition: "",
  application_cost: "",
  application_start_date: "",
  application_end_date: "",
  application_GRA: "",
  application_TOEFL: "",
  application_IELTS: "",
  application_GRE: "",
  application_GMAT: "",
  application_SAT: "",
  application_ACT: "",
  application_academic_background: "",
  application_material_requirements: "",
  application_enrollment_website: "",
  application_professional_website: "",
  application_high_attribute: "",
});
const formRules = reactive({
  application_ranking: [
    { required: true, message: "请输入院校世界排名", trigger: "blur" },
  ],
  application_attribute: [
    { required: true, message: "请输入院校属性", trigger: "blur" },
  ],
  application_name: [
    { required: true, message: "请输入院校名称", trigger: "blur" },
  ],
  application_country: [
    { required: true, message: "请输入所属国家", trigger: "blur" },
  ],
  application_professional: [
    { required: true, message: "请输入专业大类", trigger: "blur" },
  ],
});

function init(info = null) {
  if (info) {
    form.value = info;
  }
  dialogVisible.value = true;
}


// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      if (form.value.application_id && form.value.application_id != 0) {
        updateApplication();
      } else {
        addApplication();
      }
    } else {
      return false;
    }
  });
}
function updateApplication() {
  systemnApi.updateApplication(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}
function addApplication() {
  systemnApi.addApplication(form.value).then(() => {
    dialogVisible.value = false;
  });
}
function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    application_id: 0,
    application_ranking: "",
    application_name: "",
    application_en_name: "",
    application_attribute: "",
    application_country: "",
    application_province: "",
    application_city: "",
    application_professional: "",
    application_class: "",
    application_major_name: "",
    application_major_en_name: "",
    application_faculty: "",
    application_education: "",
    application_en_education: "",
    application_introduce: "",
    application_course: "",
    application_tuition: "",
    application_cost: "",
    application_start_date: "",
    application_end_date: "",
    application_GRA: "",
    application_TOEFL: "",
    application_IELTS: "",
    application_GRE: "",
    application_GMAT: "",
    application_SAT: "",
    application_ACT: "",
    application_academic_background: "",
    application_material_requirements: "",
    application_enrollment_website: "",
    application_professional_website: "",
  };
  emit("reacquire");
}
defineExpose({
  init,
});
</script>
