<template>
  <div class="w-100 h-100">
    <div
      class="
        w-100
        border-box
        flex
        justify-start
        align-center
        card-shadow
        bg-white
        rounded
        px-1
        overflow-x
      "
      style="height: 50px"
    >
      <div class="flex justify-start align-center">
        <!-- 用户昵称 -->
        <div class="flex justify-start align-center">
          <div class="mr-1">昵称:</div>
          <el-input
            style="width: 110px"
            v-model="queryInfo.user_nickname"
            placeholder="请输入"
          ></el-input>
        </div>
        <!-- 状态 -->
        <div class="flex justify-start align-center">
          <div class="mx-1">状态:</div>
          <el-select
            class="mr-1"
            v-model="queryInfo.user_status"
            style="width: 110px"
            placeholder="Select"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <!-- 创建时间 -->
        <div class="mx-1 flex justify-start align-center">
          <div class="mr-1">创建时间:</div>
          <el-date-picker
            style="width: 220px"
            v-model="creationTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            placeholder="不可选"
            @change="setTime"
          ></el-date-picker>
        </div>
        <!-- 按钮 -->
        <el-button type="primary" @click="query">
          <el-icon :size="15"> <component :is="Search"></component> </el-icon
          >搜索
        </el-button>
        <el-button type="primary" @click="reset">重置</el-button>
        <el-button v-if="showAdd" @click="add" type="success">
          <el-icon :size="15"> <component :is="Plus"></component> </el-icon>新增
        </el-button>
        <el-button @click="exportSpecialExcel" type="success">
          <el-icon :size="15"> <component :is="Download"></component> </el-icon
          >导出
        </el-button>
      </div>
    </div>
    <div
      class="w-100 mt-1 border-box card-shadow bg-white rounded p-1"
      style="height: calc(100% - 70px)"
    >
      <!-- 表格 -->
      <div class="w-100" style="height: calc(100% - 60px)">
        <el-table :data="list" class="w-100"  height="100%">
          <el-table-column label="序号" align="center" min-width="100">
            <template #default="scope">
              {{scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column
            v-for="(field, index) in fieldList"
            :key="index"
            :prop="field.prop"
            :label="field.label"
            :width="field.width || ''"
            align="center"
          >
            <template #default="scope">
              <!-- 操作 -->
              <div v-if="field.prop == 'operation'">
                <!-- 修改按钮 -->
                <el-button :disabled="!showEdit" type="primary" size="small" @click="edit(scope.row)"
                  >编辑</el-button
                >
                <!-- 删除按钮 -->
                <el-button
                  :disabled="scope.row.user_id == 1 || !showDelete"
                  type="danger"
                  size="small"
                  @click="del(scope.row)"
                  >删除</el-button
                >
              </div>
              <!-- 账号状态 -->
              <div v-else-if="field.prop == 'user_status'">
                <el-switch
                  @change="updateUser(scope.row)"
                  :disabled="scope.row.user_id == 1 || !showEdit"
                  v-model="scope.row.user_status"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                />
              </div>
              <!-- 通知状态 -->
              <div v-else-if="field.prop == 'user_notice'">
                {{ scope.row.user_notice ? "是" : "否" }}
              </div>
              <span v-else>{{ scope.row[field.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="flex justify-center align-center" style="height: 50px">
        <!--分页-->
        <!-- <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-size="queryInfo.pageSize"
          layout="prev, pager, next"
          :total="total"
        ></el-pagination> -->
        <el-pagination
         :page-sizes="[10, 50, 100, 200, 500]"
          background
          layout="total, sizes, prev, pager, next"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <addUser ref="addUsers" @reacquire="reacquire"></addUser>
  </div>
</template>

<script setup>
import systemnApi from "@/api/systemnApi.js";
import XLSX from "@/utils/xlsx.core.min.js";
import addUser from "@/components/addUser/addUser.vue";
import { ref, reactive, onMounted, computed } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { Search, Download, Plus } from "@element-plus/icons-vue";
import { useStore } from "vuex";
const store = useStore();
let addUsers = ref(null);
let options = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 0,
    label: "停用",
  },
  {
    value: 1,
    label: "启用",
  },
];
let queryInfo = reactive({
  user_nickname: "",
  user_status: "",
  startTime: "",
  endTime: "",
  pageNum: 1,
  pageSize: 10,
});
let creationTime = ref([]);
const fieldList = [
  // {
  //   id: 1,
  //   prop: "user_id",
  //   label: "序号",
  // },
  {
    id: 2,
    prop: "user_account",
    label: "账号",
  },
  {
    id: 3,
    prop: "user_nickname",
    label: "用户昵称",
  },
  {
    id: 4,
    prop: "user_sex",
    label: "性别",
  },
  {
    id: 5,
    prop: "user_phone",
    label: "手机号",
  },
  {
    id: 6,
    prop: "role_name",
    label: "所属角色",
  },
  {
    id: 7,
    prop: "post_name",
    label: "所属岗位",
  },
  {
    id: 8,
    prop: "user_notice",
    label: "是否接收短信",
  },
  {
    id: 9,
    prop: "user_status",
    label: "状态",
  },
  {
    id: 10,
    prop: "operation",
    label: "操作",
    width: 180,
  },
];
let total = ref(0);
let list = ref([]);
let showAdd = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if(store.getters.role_menu_ids.indexOf("10102")) {
    return true;
  } else {
    return false;
  }
});

let showEdit = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if(store.getters.role_menu_ids.indexOf("10103")) {
    return true;
  } else {
    return false;
  }
});

let showDelete = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if(store.getters.role_menu_ids.indexOf("10104")) {
    return true;
  } else {
    return false;
  }
});

function setTime(val) {
  console.log(val);
  if (val) {
    queryInfo.startTime = Number(val[0] / 1000);
    queryInfo.endTime = Number(val[1] / 1000);
  } else {
    queryInfo.startTime = "";
    queryInfo.endTime = "";
  }
}
function query() {
  queryInfo.pageNum = 1;
  getUserList();
}

function reset() {
  queryInfo.user_nickname = "";
  queryInfo.user_status = "";
  queryInfo.startTime = "";
  queryInfo.endTime = "";
  queryInfo.pageNum = 1;
  queryInfo.pageSize = 10;
  creationTime.value = [];
  getUserList();
}

function getUserList() {
  systemnApi.getUserList(queryInfo).then((res) => {
    list.value = res.data.list;
    total.value = res.data.total;
  });
}

function add() {
  addUsers.value.init();
}

function edit(info) {
  addUsers.value.init(info);
}

function del(info) {
  ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      systemnApi.deleteUser(info).then((res) => {
        console.log(res);
        ElMessage.success("删除成功");
        reacquire();
      });
    })
    .catch(() => {
      // catch error
    });
}

function updateUser(info) {
  if (info && info.user_account) {
    systemnApi.updateUser(info).then(() => {
      ElMessage.success("修改成功");
    });
  }
}

function exportSpecialExcel() {
  let xlsxTable = [];
  // 创建 标题
  xlsxTable.push(['序号', '账号', '用户昵称' , '性别' , '手机号', '所属角色', '所属岗位', '是否接收短信', '状态']);
  // 创建数据内容
  for (const item of list.value) {
    let info = [
      item.user_id,
      item.user_account,
      item.user_nickname,
      item.user_sex,
      item.user_phone,
      item.role_name,
      item.post_name,
      item.user_notice ? "是" : "否",
      item.user_status ? "启用" : "停用",
    ];
    xlsxTable.push(info)
  }
  var sheet = XLSX.utils.aoa_to_sheet(xlsxTable);
  // sheet['!merges'] = [
  //   // 设置A1-C1的单元格合并
  //   { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }
  // ];
  openDownloadDialog(sheet2blob(sheet), '用户表.xlsx');
}

function openDownloadDialog(url, saveName) {
  if (typeof url == 'object' && url instanceof Blob) {
    url = URL.createObjectURL(url); // 创建blob地址
  }
  var aLink = document.createElement('a');
  aLink.href = url;
  aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
  var event;
  if (window.MouseEvent) event = new MouseEvent('click');
  else {
    event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  }
  aLink.dispatchEvent(event);
}

// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName) {
  sheetName = sheetName || 'sheet1';
  var workbook = {
    SheetNames: [sheetName],
    Sheets: {}
  };
  workbook.Sheets[sheetName] = sheet;
  // 生成excel的配置项
  var wopts = {
    bookType: 'xlsx', // 要生成的文件类型
    bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    type: 'binary'
  };
  var wbout = XLSX.write(workbook, wopts);
  var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
  // 字符串转ArrayBuffer
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
  return blob;
}

function handleSizeChange(number) {
  queryInfo.pageSize = number;
  getUserList();
}

function handleCurrentChange(newPage) {
  queryInfo.pageNum = newPage;
  getUserList();
}

function reacquire() {
  queryInfo.pageNum = 1;
  getUserList();
}

onMounted(() => {
  // 获取列表
  getUserList();
});
</script>

