export default {
    // 返回近7天的时间戳
    getSevenTimeStamp(){
        var date = new Date();
        // 开始时间
        var beginTime = new Date(date.toLocaleDateString()).getTime() - 1000 * 60 * 60 * 24 * 7;
        // 结束时间
        let endTime = new Date(date.toLocaleDateString()).getTime() - 1;
        let sevenTime = {
            beginTime: beginTime,
            endTime: endTime,
        };
        return sevenTime;
    },
    // 返回近30天的时间戳
    getThirtyTimeStamp(){
        var date = new Date();
        // 开始时间
        var beginTime = new Date(date.toLocaleDateString()).getTime() - 1000 * 60 * 60 * 24 * 30;
        // 结束时间
        let endTime = new Date(date.toLocaleDateString()).getTime() - 1;
        let sevenTime = {
            beginTime: beginTime,
            endTime: endTime,
        };
        return sevenTime;
    },
    // 获取当天0点到24点
    getTodayTimeStamp(){
        var date = new Date();
        let beginTime = new Date(date.toLocaleDateString()).getTime(); // 当天0点
        let endTime = new Date(date.toLocaleDateString()).getTime() + (24 * 60 * 60 * 1000 - 1); // 当天23:59
        let dataTime = {
            beginTime: beginTime,
            endTime: endTime,
        };
        return dataTime;
    },
    // 获取当前日期的前7天
    getBeforeSevenTimeStamp(){
        var date = new Date();
        // 开始时间
        date.setDate(date.getDate() - 5);
        let dataStr = `${date.getFullYear()}-${(date.getMonth() + 1).length > 1 ? '' : 0}${(date.getMonth() + 1)}-${date.getDate()} 00:00:00`;
        var beginTime = new Date(dataStr).getTime()

        // 结束时间
        let endTime = new Date(date.toLocaleDateString()).getTime() + (24 * 60 * 60 * 1000 - 1);
        let sevenTime = {
            beginTime: beginTime,
            endTime: endTime,
        };
        return sevenTime;
    },
    // 获取当周的第一天和最后一天
    getWeekTimeStamp(){
        var date = new Date();
        // 本周一的日期
        date.setDate(date.getDate() - date.getDay() + 1);
        let beginTimeStr = `${date.getFullYear()}-${(date.getMonth() + 1).length > 1 ? '' : 0}${(date.getMonth() + 1)}-${date.getDate()} 00:00:00`;
        var beginTime = new Date(beginTimeStr).getTime()
        // 本周日的日期
        date.setDate(date.getDate() + 6);
        let endTimeStr = `${date.getFullYear()}-${(date.getMonth() + 1).length > 1 ? '' : 0}${(date.getMonth() + 1)}-${date.getDate()} 23:59:59`;
        var endTime = new Date(endTimeStr).getTime()
        let weekTime = {
            beginTime: beginTime,
            endTime: endTime,
        };
        return weekTime;
    },
    // 获取当前月份前7个月
    getSevenMonth(){
        var data = new Date();
        // 设置当前日期
        data.setDate(1);
        // 小时数
        data.setHours(0);
        // 设置分钟数
        data.setMinutes(0);
        // 设置秒
        data.setSeconds(0);
        // 获取开始时间
        var beginTime = data.getTime() - 2592000000 * 7;
        // 结束时间
        var nextMonth = data.getMonth() + 1;
        var nextMonthFirstDay = new Date(data.getFullYear(), nextMonth, 1);
        var endTime = nextMonthFirstDay - 1;
        let weekTime = {
            beginTime: beginTime,
            endTime: endTime,
        };
        return weekTime;
    },
    // 获取当月的第一天和最后一天
    getMonthDate(){
        var data = new Date();
        // 设置当前日期
        data.setDate(1);
        // 小时数
        data.setHours(0);
        // 设置分钟数
        data.setMinutes(0);
        // 设置秒
        data.setSeconds(0);
        // 获取开始时间
        var beginTime = data.getTime();
        // 结束时间
        var nextMonth = data.getMonth() + 1;
        var nextMonthFirstDay = new Date(data.getFullYear(), nextMonth, 1);
        var endTime = nextMonthFirstDay - 1;
        let MonthTime = {
            beginTime: beginTime, //这里调用时间戳转年月日时分秒方法
            endTime: endTime,
        };
        return MonthTime;
    },
    // 获取当前年份前7年
    getSevenYear(){
        var data = new Date();
        // 获取当前年份
        var Year = data.getFullYear();
        // 获取开始时间
        var beginTimeStr = `${Year - 7}-01-01 00:00:00`;
        var beginTime = new Date(beginTimeStr).getTime();
        // 结束时间
        var endTimeStr = `${Year}-12-31 23:59:59`;
        var endTime = new Date(endTimeStr).getTime();
        // 封装数据
        let YearTime = {
            beginTime: beginTime,
            endTime: endTime,
        };
        return YearTime;
    },
    // 获取当年的第一天和最后一天
    getYearDate(){
        var data = new Date();
        // 获取当前年份
        var Year = data.getFullYear();

        // 获取开始时间
        var beginTimeStr = `${Year}-01-01 00:00:00`;
        var beginTime = new Date(beginTimeStr).getTime();
        // 结束时间
        var endTimeStr = `${Year}-12-31 23:59:59`;
        var endTime = new Date(endTimeStr).getTime();
        // 封装数据
        let YearTime = {
            beginTime: beginTime,
            endTime: endTime,
        };
        return YearTime;
    },
    // 时间戳转换为时间
    formatTime(value){
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return y + '年' + MM + '月' + d + '日 ' + h + ':' + m + ':' + s;
    },
    // 时间戳转换为日期
    formatDate(value){
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        return y + '年' + MM + '月' + d + '日';
    },
    // 时间戳转换为时间
    formatTimeNum(value){
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return y + MM + d + h + m + s;
    },

    // 时间转换为时间戳
    dataToformat(value){
        return Date.parse(value);
    },
}