<template>
  <!-- 对话框 -->
  <el-dialog v-model="dialogVisible" title="会员采购详情" width="1100px" @close="dialogClosed">
    <!-- 表单数据 -->
    <el-descriptions :column="3" border>
      <el-descriptions-item label="会员姓名">
        {{ queryInfo.member_name || '未填写' }}
      </el-descriptions-item>
      <el-descriptions-item label="联系方式">
        {{ queryInfo.member_phone || '未填写' }}
      </el-descriptions-item>
      <el-descriptions-item label="电子邮箱">
        {{ queryInfo.member_mailbox || '未填写' }}
      </el-descriptions-item>
    </el-descriptions>
    <!-- 内容主体区域 -->
    <div class="w-100 mt-1" style="height: 450px">
      <el-table :data="list" class="w-100"  height="100%">
      <el-table-column prop="purchase_id" label="序号" align="center"/>
      <el-table-column prop="purchase_order_number" label="订单编号"  align="center"/>
       <el-table-column prop="purchase_creation_time" label="发起日期" align="center">
          <template #default="scope">
           {{ getFormatTime(scope.row.purchase_creation_time)}}
          </template>
        </el-table-column>
        <el-table-column prop="purchase_country" label="目标国家" align="center"/>
        <el-table-column prop="purchase_college" label="目标学校" align="center"/>
        <el-table-column prop="purchase_professional" label="专业大类" align="center"/>
        <el-table-column prop="purchase_class" label="专业小类" align="center"/>
        <el-table-column prop="purchase_money" label="付款记录" align="center"/>
        <!-- <el-table-column v-for="(field, index) in fieldList" :key="index" :prop="field.prop" :label="field.label"
          :width="field.width || ''" align="center">
          <template #default="scope">
            <div v-if="field.prop == 'purchase_creation_time'">
              {{ getFormatTime(scope.row.purchase_creation_time) }}
            </div>
            <span v-else>{{ scope.row[field.prop] }}</span>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="flex justify-center align-center" style="height: 50px">
      <el-pagination :page-sizes="[10, 50, 100, 200]" background layout="total, sizes, prev, pager, next" :total="total"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">关 闭</el-button>
        <el-button @click="exportSpecialExcel" type="success">
          <el-icon :size="15">
            <component :is="Download"></component>
          </el-icon>导出
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import memberApi from "@/api/memberApi.js";
import XLSX from "@/utils/xlsx.core.min.js";
import date from "@/utils/date.js";
import { ref, reactive, defineEmits, defineExpose } from "vue";
import { Download } from "@element-plus/icons-vue";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let queryInfo = reactive({
  member_id: "",
  member_name: "",
  member_phone: "",
  member_mailbox: "",
  pageNum: 1,
  pageSize: 10,
});

let fieldList = ref([
  {
    id: 1,
    prop: "purchase_id",
    label: "序号",
  },
  {
    id: 2,
    prop: "purchase_order_number",
    label: "订单编号",
  },
  {
    id: 3,
    prop: "purchase_creation_time",
    label: "发起日期",
  },
  {
    id: 4,
    prop: "purchase_country",
    label: "目标国家",
  },
  {
    id: 5,
    prop: "purchase_college",
    label: "目标学校",
  },
  {
    id: 6,
    prop: "purchase_professional",
    label: "专业大类",
  },
  {
    id: 7,
    prop: "purchase_class",
    label: "专业小类",
  },
  {
    id: 8,
    prop: "purchase_money",
    label: "付款记录",
  },
]);
let list = ref([]);
let total = ref(0);

function getFormatTime(value) {
  return date.formatDate(value * 1000);
}

function init(info = null) {
  if (info) {
    queryInfo.member_name = info.member_name
    queryInfo.member_phone = info.member_phone
    queryInfo.member_id = info.member_id
    queryInfo.member_mailbox = info.member_mailbox;
    getPurchaseList()
  }
  dialogVisible.value = true;
}

function getPurchaseList() {
  memberApi.getPurchaseList(queryInfo).then((res) => {
    list.value = res.data.list;
    total.value = res.data.total;
  });
}

function exportSpecialExcel() {
  let xlsxTable = [];
  // 创建 标题
  xlsxTable.push(['会员姓名', '联系电话', '电子邮箱', '订单编号', '发起日期', '目标国家', '专业大类', '专业小类', '付款记录']);
  // 创建数据内容
  for (const item of list.value) {
    let info = [
      queryInfo.member_name,
      queryInfo.member_phone,
      queryInfo.member_mailbox,
      item.purchase_order_number,
      getFormatTime(item.purchase_creation_time),
      item.purchase_country,
      item.purchase_professional,
      item.purchase_class,
      item.purchase_money,
    ];
    xlsxTable.push(info)
  }
  var sheet = XLSX.utils.aoa_to_sheet(xlsxTable);
  // sheet['!merges'] = [
  //   // 设置A1-C1的单元格合并
  //   { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }
  // ];
  openDownloadDialog(sheet2blob(sheet), `${queryInfo.member_name}采购详情表.xlsx`);
}

function openDownloadDialog(url, saveName) {
  if (typeof url == 'object' && url instanceof Blob) {
    url = URL.createObjectURL(url); // 创建blob地址
  }
  var aLink = document.createElement('a');
  aLink.href = url;
  aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
  var event;
  if (window.MouseEvent) event = new MouseEvent('click');
  else {
    event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  }
  aLink.dispatchEvent(event);
}

// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName) {
  sheetName = sheetName || 'sheet1';
  var workbook = {
    SheetNames: [sheetName],
    Sheets: {}
  };
  workbook.Sheets[sheetName] = sheet;
  // 生成excel的配置项
  var wopts = {
    bookType: 'xlsx', // 要生成的文件类型
    bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    type: 'binary'
  };
  var wbout = XLSX.write(workbook, wopts);
  var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
  // 字符串转ArrayBuffer
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
  return blob;
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

function handleSizeChange(number) {
  queryInfo.pageSize = number;
  getPurchaseList();
}

function handleCurrentChange(newPage) {
  queryInfo.pageNum = newPage;
  getPurchaseList();
}



function dialogClosed() {
  fieldList.value = [];
  list.value = [];
  emit("reacquire");
}
defineExpose({
  init,
});
</script>

<style>
.canvasTable {
  border-collapse: collapse;
}

.canvasTable th,
.canvasTable td {
  border: solid 1px #6d6d6d;
  padding: 5px 10px;
}
</style>
