<template>
  <div class="w-100 h-100">
    <div class="
          w-100
          border-box
          flex
          justify-start
          align-center
          card-shadow
          bg-white
          rounded
          px-1
          overflow-x
        " style="height: 50px">
      <div class="flex justify-start align-center">
        <!-- 用户昵称 -->
        <div class="flex justify-start align-center">
          <div class="mr-1">公告标题:</div>
          <el-input style="width: 110px" v-model="queryInfo.notice_title" placeholder="请输入"></el-input>
        </div>
        <!-- 状态 -->
        <div class="flex justify-start align-center">
          <div class="mx-1">公告类型:</div>
          <el-select class="mr-1" v-model="queryInfo.notice_type" style="width: 110px" placeholder="请选择">
            <el-option v-for="item in type_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <!-- 状态 -->
        <div class="flex justify-start align-center">
          <div class="mx-1">公告途径:</div>
          <el-select class="mr-1" v-model="queryInfo.notice_channel" style="width: 110px" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <!-- 创建时间 -->
        <div class="mx-1 flex justify-start align-center">
          <div class="mr-1">创建时间:</div>
          <el-date-picker style="width: 220px" v-model="creationTime" type="daterange" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" placeholder="不可选" @change="setTime"></el-date-picker>
        </div>
        <!-- 按钮 -->
        <el-button type="primary" @click="query">
          <el-icon :size="15">
            <component :is="Search"></component>
          </el-icon>搜索
        </el-button>
        <el-button type="primary" @click="reset">重置</el-button>
        <el-button v-if="showAdd" @click="add" type="success">
          <el-icon :size="15">
            <component :is="Plus"></component>
          </el-icon>新增
        </el-button>
      </div>
    </div>
    <div class="w-100 mt-1 border-box card-shadow bg-white rounded p-1" style="height: calc(100% - 70px)">
      <!-- 表格 -->
      <div class="w-100" style="height: calc(100% - 60px)">
        <el-table :data="list" class="w-100" height="100%">
          <el-table-column label="序号" align="center" min-width="100">
            <template #default="scope">
              {{scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column v-for="(field, index) in fieldList" :key="index" :prop="field.prop" :label="field.label"
            :width="field.width || ''" align="center">
            <template #default="scope">
              <!-- 操作 -->
              <div v-if="field.prop == 'operation'">
                <!-- 修改按钮 -->
                <el-button :disabled="!showEdit" type="primary" size="small" @click="edit(scope.row)">编辑</el-button>
                <!-- 删除按钮 -->
                <el-button :disabled="!showDelete" type="danger" size="small" @click="del(scope.row)">删除
                </el-button>
              </div>
              <div v-else-if="field.prop == 'notice_channel'">
                {{ showChannel(scope.row.notice_channel) }}
              </div>
              <!-- 状态 -->
              <div v-else-if="field.prop == 'notice_status'">
                <el-switch @change="updateNotice(scope.row)" :disabled="!showEdit" v-model="scope.row.notice_status"
                  active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" />
              </div>
              <!-- 时间 -->
              <div v-else-if="field.prop == 'notice_creation_time'">
                {{ getFormatTime(scope.row.notice_creation_time) }}
              </div>
              <span v-else>{{ scope.row[field.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="flex justify-center align-center" style="height: 50px">
        <el-pagination :page-sizes="[10, 50, 100, 200, 500]" background layout="total, sizes, prev, pager, next"
          :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
    <addNotice ref="addNotices" :list="list" @reacquire="reacquire"></addNotice>
  </div>
</template>

<script setup>
import systemnApi from "@/api/systemnApi.js";
import { type_options, app_options, pc_options } from "@/utils/options.js";
import date from "@/utils/date.js";
import addNotice from "./addNotice/addNotice.vue";
import { ref, reactive, onMounted, computed } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { Search, Plus } from "@element-plus/icons-vue";
import { useStore } from "vuex";
const store = useStore();
let addNotices = ref(null);

let queryInfo = reactive({
  notice_title: "",
  notice_type: "",
  notice_channel: "",
  startTime: "",
  endTime: "",
  pageNum: 1,
  pageSize: 10,
});
let creationTime = ref([]);

const fieldList = [
  // {
  //   id: 1,
  //   prop: "notice_id",
  //   label: "序号",
  // },
  {
    id: 2,
    prop: "notice_title",
    label: "公告标题",
  },
  {
    id: 3,
    prop: "notice_channel",
    label: "公告途径",
  },
  {
    id: 4,
    prop: "notice_creation_time",
    label: "创建时间",
  },
  {
    id: 5,
    prop: "notice_status",
    label: "上架",
  },
  {
    id: 10,
    prop: "operation",
    label: "操作",
    width: 180,
  },
];
let total = ref(0);
let list = ref([]);

let options = computed(() => {
  if (queryInfo.notice_type == 1) {
    return [
      {
        value: "",
        label: "全部",
      },
      ...pc_options
    ];
  } else if (queryInfo.notice_type === 0) {
    return [
      {
        value: "",
        label: "全部",
      },
      ...app_options
    ];
  }
  return []
});

let showAdd = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("60202")) {
    return true;
  } else {
    return false;
  }
});

let showEdit = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("60203")) {
    return true;
  } else {
    return false;
  }
});

let showDelete = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("60204")) {
    return true;
  } else {
    return false;
  }
});

function showChannel(notice_channel) {
  if (notice_channel) {
    const data = [...app_options, ...pc_options].find(item => {
      return item.value == notice_channel
    })
    return data.label
  }
}

function getFormatTime(value) {
  return date.formatDate(value * 1000);
}

function setTime(val) {
  console.log(val);
  if (val) {
    queryInfo.startTime = Number(val[0] / 1000);
    queryInfo.endTime = Number(val[1] / 1000);
  } else {
    queryInfo.startTime = "";
    queryInfo.endTime = "";
  }
}
function query() {
  queryInfo.pageNum = 1;
  getNoticeList();
}

function reset() {
  queryInfo.notice_title = "";
  queryInfo.notice_type = "";
  queryInfo.notice_channel = "";
  queryInfo.startTime = "";
  queryInfo.endTime = "";
  queryInfo.pageNum = 1;
  queryInfo.pageSize = 10;
  creationTime.value = [];
  getNoticeList();
}

function getNoticeList() {
  systemnApi.getNoticeList(queryInfo).then((res) => {
    list.value = res.data.list;
    total.value = res.data.total;
  });
}

function add() {
  addNotices.value.init(null);
}

function edit(info) {
  addNotices.value.init(info);
}

function del(info) {
  ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let data = {
        notice_id: info.notice_id
      }
      systemnApi.deleteNotice(data).then((res) => {
        console.log(res);
        ElMessage.success("删除成功");
        reacquire();
      });
    })
    .catch(() => {
      // catch error
    });
}

function updateNotice(info) {
  console.log('info', info)
  if (info && info.notice_id) {
    systemnApi.updateNotice(info).then(() => {
      ElMessage.success("修改成功");
    });
  }
}

function handleSizeChange(number) {
  queryInfo.pageSize = number;
  getNoticeList();
}

function handleCurrentChange(newPage) {
  queryInfo.pageNum = newPage;
  getNoticeList();
}

function reacquire() {
  // queryInfo.pageNum = 1;
  getNoticeList();
}

onMounted(() => {
  // 获取列表
  getNoticeList();
});
</script>

