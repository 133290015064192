<template>
  <!-- 对话框 -->
  <el-dialog
    v-model="dialogVisible"
    :title="title"
    width="500px"
    @close="dialogClosed"
  >
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="100px">
      <el-form-item label="评分依据：" prop="score_describe">
        <el-input
          disabled
          v-model="form.score_describe"
          placeholder="请输入评分依据"
        ></el-input>
      </el-form-item>
      <el-form-item label="评分标准：" prop="score_remarks">
        <el-input
          disabled
          v-model="form.score_remarks"
          placeholder="请输入评分标准"
        ></el-input>
      </el-form-item>
      <el-form-item label="评选分数：" prop="score_value">
        <el-input
          type="number"
          v-model="form.score_value"
          placeholder="请输入评选分数"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import memberApi from "@/api/memberApi.js";
import { ref, reactive, defineEmits, defineExpose, computed } from "vue";
import { ElMessage } from "element-plus";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let title = computed(() => {
  if (form.value.role_id == 0) {
    return "评分规则添加";
  } else {
    return "评分规则修改";
  }
});
let formRef = ref(null);
let form = ref({
  score_id: 0,
  score_basis: "",
  score_standard: "",
  score_type: 0,
  score_max_number: 1,
  score_min_number: "",
  score_value: 0,
  score_group: "",
  score_remarks: "",
  score_describe: "",
});
const formRules = reactive({
  score_value: [{ required: true, message: "请输入评选分数", trigger: "blur" }],
});

function init(info = null) {
  if (info) {
    form.value = info;
  }
  dialogVisible.value = true;
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      if (form.value.score_id && form.value.score_id != 0) {
        updateScore();
      } else {
        addScore();
      }
    } else {
      return false;
    }
  });
}
function updateScore() {
  memberApi.updateScore(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}
function addScore() {
  memberApi.addScore(form.value).then(() => {
    dialogVisible.value = false;
  });
}



function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    score_id: 0,
    score_basis: "",
    score_standard: "",
    score_type: 0,
    score_max_number: 1,
    score_min_number: "",
    score_value: 0,
    score_group: "",
    score_remarks: "",
    score_describe: "",
  };
  emit("reacquire");
}
defineExpose({
  init,
});
</script>
