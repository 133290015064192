// 系统api
import { get, post, put, del } from '../http/http.js';

const systemnApi = {
    // 获取菜单列表
	getMenuList: (params) => get('/admin/menulist', params),
    // 获取用户列表
    getUserList: (params) => get('/admin/getUserList', params),
    // 获取全部角色路由
    getAllUserRole: () => get('/admin/getAllUserRole'),
    // 获取全部岗位路由
    getAllUserPost: () => get('/admin/getAllUserPost'),
    // 添加账号
    addUser: (params) => post('/admin/addUser', params),
    // 修改用户
    updateUser: (params) => put('/admin/updateUser', params),
    // 修改用户头像
    updateUserHead: (params) => put('/admin/updateUserHead', params),
    // 删除账号
    deleteUser: (params) => del('/admin/deleteUser', params),
    // 获取用户列表
    getRoleList: (params) => get('/admin/getRoleList', params),
    // 添加角色
    addRole: (params) => post('/admin/addRole', params),
    // 修改角色
    updateRole: (params) => put('/admin/updateRole', params),
    // 删除角色
    deleteRole: (params) => del('/admin/deleteRole', params),
    // 获取全部菜单列表
    getPermissionList: () =>  get('/admin/getPermissionList'),
    // 获取岗位列表
	getPostList: (params) => get('/admin/getPostList', params),
    // 添加岗位
    addPost: (params) => post('/admin/addPost', params),
    // 修改岗位
    updatePost: (params) => put('/admin/updatePost', params),
    // 删除岗位
    deletePost: (params) => del('/admin/deletePost', params),
    // 获取协议列表
	getAgreementList: (params) => get('/admin/getAgreementList', params),
    // 添加协议
    addAgreement: (params) => post('/admin/addAgreement', params),
    // 修改协议
    updateAgreement: (params) => put('/admin/updateAgreement', params),
    // 删除协议
    deleteAgreement: (params) => del('/admin/deleteAgreement', params),
    // 获取问题列表
	getProblemList: (params) => get('/admin/getProblemList', params),
    // 添加问题
    addProblem: (params) => post('/admin/addProblem', params),
    // 修改问题
    updateProblem: (params) => put('/admin/updateProblem', params),
    // 删除问题
    deleteProblem: (params) => del('/admin/deleteProblem', params),

    // 获取banner列表
    getBannerList: (params) => get('/admin/getBannerList', params),
    // 根据类型获取banner数量
    getBannerNumberByType: () => get('/admin/getBannerNumberByType'),
    // 添加banner
    addBanner: (params) => post('/admin/addBanner', params),
    // 修改banner
    updateBanner: (params) => put('/admin/updateBanner', params),
    // 删除banner
    deleteBanner: (params) => del('/admin/deleteBanner', params),
    // 获取配置列表
    getConfigureList: (params) => get('/admin/getConfigureList', params),
    // 修改banner配置
    updateBannerConfig: (params) => put('/admin/updateBannerConfig', params),
    // 获取公告列表
    getNoticeList: (params) => get('/admin/getNoticeList', params),
    // 添加公告
    addNotice: (params) => post('/admin/addNotice', params),
    // 修改公告
    updateNotice: (params) => put('/admin/updateNotice', params),
    // 删除公告
    deleteNotice: (params) => del('/admin/deleteNotice', params),
    // 获取专业列表
    getProfessionalList: (params) => get('/admin/getProfessionalList', params),
    // 添加专业
    addProfessional: (params) => post('/admin/addProfessional', params),
    // 批量添加专业
    addProfessionalList: (params) => post('/admin/addProfessionalList', params),
    // 修改专业
    updateProfessional: (params) => put('/admin/updateProfessional', params),
    // 删除专业
    deleteProfessional: (params) => del('/admin/deleteProfessional', params),
    // 获取院校列表
    getCollegeList: (params) => get('/admin/getCollegeList', params),
    // 根据关键词获取筛选数据
    getSelectByKeyList: (params) => get('/admin/getSelectByKeyList', params),
    // 添加院校
    addCollege: (params) => post('/admin/addCollege', params),
    // 批量添加院校
    addCollegeList: (params) => post('/admin/addCollegeList', params),
    // 修改院校
    updateCollege: (params) => put('/admin/updateCollege', params),
    // 删除院校
    deleteCollege: (params) => del('/admin/deleteCollege', params),
    // 批量删除院校列表
    deleteCollegeList: (params) => del('/admin/deleteCollegeList', params),
    // 获取院校申请列表
    getApplicationList: (params) => get('/admin/getApplicationList', params),
    // 添加院校申请
    addApplication: (params) => post('/admin/addApplication', params),
    // 修改院校申请
    updateApplication: (params) => put('/admin/updateApplication', params),
    // 删除院校申请
    deleteApplication: (params) => del('/admin/deleteApplication', params),
    // 批量添加院校申请列表
    addApplicationList: (params) => post('/admin/addApplicationList', params),
    // 批量删除院校申请
    deleteApplicationList: (params) => del('/admin/deleteApplicationList', params),
    deleteApplicationByInfo: (params) => del('/admin/deleteApplicationByInfo', params),

    // 获取留言
    getMessageList: (params) => get('/admin/getMessageList', params),
    // 删除留言
    deleteMessage: (params) => del('/admin/deleteMessage', params),
    
    // 获取模版列表
    getMasterplateList: (params) => get('/admin/getMasterplateList', params),
    // 添加模版
    addMasterplate: (params) => post('/admin/addMasterplate', params),
    // 修改模版
    updateMasterplate: (params) => put('/admin/updateMasterplate', params),
    // 删除模版
    deleteMasterplate: (params) => del('/admin/deleteMasterplate', params),

    // 获取会员文件列表
    getSubscribeList: (params) => get('/admin/getSubscribeList', params),
    // 添加会员文件
    addSubscribe: (params) => post('/admin/addSubscribe', params),
    // 修改会员文件
    updateSubscribe: (params) => put('/admin/updateSubscribe', params),
    // 删除会员文件
    deleteSubscribe: (params) => del('/admin/deleteSubscribe', params),
}

export default systemnApi