// 登录api
import { post } from '../http/http.js';
// get,,del,put
// const projectUrl = '/bless-statistics/statistics';

const loginApi = {
    // 登陆
	login: (params) => post('/login/nameLogin', params),

}

export default loginApi