<template>
  <!-- 对话框 -->
  <el-dialog
    v-model="dialogVisible"
    :title="title"
    width="900px"
    top="2vh"
    @close="dialogClosed"
  >
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="100px">
      <div class="flex">
        <div class="flex-1">
          <el-form-item label="协议名称：" prop="agreement_title">
            <el-input
              v-model="form.agreement_title"
              placeholder="请输入协议名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="协议索引：" prop="agreement_key">
            <el-input
              :disabled="form.agreement_id != 0"
              v-model="form.agreement_key"
              placeholder="请输入协议索引"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态：" prop="agreement_status">
            <el-switch
              v-model="form.agreement_status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            />
          </el-form-item>
          <el-form-item label="备注：" prop="agreement_remarks">
            <el-input
              type="textarea"
              :rows="19"
              v-model="form.agreement_remarks"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </div>
        <div class="flex-2">
          <el-form-item label="协议内容：" prop="agreement_content">
            <div style="border: 1px solid #ccc">
              <Toolbar
                :editor="editorRef"
                :defaultConfig="toolbarConfig"
                :mode="mode"
                style="border-bottom: 1px solid #ccc"
              />
              <Editor
                :defaultConfig="editorConfig"
                :mode="mode"
                v-model="form.agreement_content"
                style="height: 470px; overflow-y: hidden"
                @onCreated="handleCreated"
              />
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import "@wangeditor/editor/dist/css/style.css";
import systemnApi from "@/api/systemnApi.js";
import {
  ref,
  reactive,
  shallowRef,
  defineEmits,
  defineExpose,
  computed,
  onBeforeUnmount,
} from "vue";
import { ElMessage } from "element-plus";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
const emit = defineEmits(["reacquire"]);
// 编辑器实例，必须用 shallowRef，重要！
const editorRef = shallowRef();
const mode = "default";
let dialogVisible = ref(false);
let title = computed(() => {
  if (form.value.agreement_id == 0) {
    return "协议添加";
  } else {
    return "协议修改";
  }
});
let formRef = ref(null);
let form = ref({
  agreement_id: 0,
  agreement_title: "",
  agreement_key: "",
  agreement_content: "",
  agreement_remarks: "",
  agreement_status: 1,
});
const formRules = reactive({
  agreement_title: [
    { required: true, message: "请输入协议名称", trigger: "blur" },
  ],
  agreement_key: [
    { required: true, message: "请输入协议索引", trigger: "blur" },
  ],
});

const toolbarConfig = {
  toolbarKeys: [
    // 'todo', // 待办
    "redo", // 重做
    "undo", // 撤销
    // 'blockquote', // 引用
    "headerSelect", // 正文
    // 文字处理
    {
      // 必填，要以 group 开头
      key: "group-text",
      // 必填
      title: "文字",
      // 可选
      // iconSvg: '<svg>....</svg>',
      // 下级菜单 key ，必填
      menuKeys: [
        "bold", // 加粗字体
        "underline", // 下划线
        "italic", // 斜体
        "through", // 删除线
        "sub", // 角标
        "sup", // 顶标
        "emotion", // 表情
      ],
    },
    // 样式处理
    {
      // 必填，要以 group 开头
      key: "group-style",
      // 必填
      title: "样式",
      // 可选
      // iconSvg: '<svg>....</svg>',
      // 下级菜单 key ，必填
      menuKeys: [
        "clearStyle", // 清除样式
        "color", // 文字颜色
        "bgColor", // 背景颜色
        "divider", // 分割线
      ],
    },
    // 对齐方式
    {
      // 必填，要以 group 开头
      key: "group-align",
      // 必填
      title: "对齐",
      // 可选
      // iconSvg: '<svg>....</svg>',
      // 下级菜单 key ，必填
      menuKeys: [
        "justifyLeft", // 左对齐
        "justifyRight", // 右对齐
        "justifyCenter", // 中对齐
        "justifyJustify", // 两端对齐
      ],
    },
    // 缩进
    {
      // 必填，要以 group 开头
      key: "group-table",
      // 必填
      title: "缩进",
      // 可选
      // iconSvg: '<svg>....</svg>',
      // 下级菜单 key ，必填
      menuKeys: [
        "indent", // 增加缩进
        "delIndent", // 减少缩进
      ],
    },
    "fullScreen", // 全屏
    // 表格
    {
      // 必填，要以 group 开头
      key: "group-table",
      // 必填
      title: "表格",
      // 可选
      // iconSvg: '<svg>....</svg>',
      // 下级菜单 key ，必填
      menuKeys: [
        "insertTable", // 插入表格
        "tableHeader", // 表头
        "insertTableRow", // 插入行
        "deleteTableRow", // 删除行
        "insertTableCol", // 插入列
        "deleteTableCol", // 删除列
        "deleteTable", // 删除表格
      ],
    },
    // 媒体
    {
      // 必填，要以 group 开头
      key: "group-media",
      // 必填
      title: "媒体",
      // 可选
      // iconSvg: '<svg>....</svg>',
      // 下级菜单 key ，必填
      menuKeys: [
        "insertImage", // 网络图片
        "uploadImage", // 上传图片
        "deleteImage", // 删除图片
        "editImage", // 编辑图片
        "viewImageLink", // 查看链接
        "insertVideo",
        "uploadVideo",
      ],
    },
    // 超链接
    {
      // 必填，要以 group 开头
      key: "group-link",
      // 必填
      title: "超链接",
      // 可选
      // iconSvg: '<svg>....</svg>',
      // 下级菜单 key ，必填
      menuKeys: ["insertLink", "editLink", "unLink", "viewLink"],
    },
    // 'code', // 行内代码
    // 'codeBlock', // 代码块
    "fontSize", // 字体大小
    // 'fontFamily', // 字体选择
    "lineHeight", // 行高
    // 'imageWidth30',
    // 'imageWidth50',
    // 'imageWidth100',
    // 'header1',
    // 'header2',
    // 'header3',
    // 'header4',
    // 'header5',
    // 'bulletedList', // 无序列表
    // 'numberedList', // 有序列表
    "tableFullWidth",
    // 'codeSelectLang' // 选择语言
  ],
};
const editorConfig = {
  placeholder: "请将协议内容复制粘贴上传即可...",
  MENU_CONF: {
    uploadImage: {
      fieldName: "media",
      server:
        "http://www.phpstudy.com/project/jylx-php/public/index.php/api/admin/wangEditorMedia",
    },
    uploadVideo: {
      fieldName: "media",
      server:
        "http://www.phpstudy.com/project/jylx-php/public/index.php/api/admin/wangEditorMedia",
    },
  },
};

// 编辑器回调函数
const handleCreated = (editor) => {
  // console.log("created", editor);
  // 记录 editor 实例，重要！
  editorRef.value = editor;
};

function init(info = null) {
  if (info) {
    form.value = info;
  }
  dialogVisible.value = true;
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      if (form.value.agreement_id && form.value.agreement_id != 0) {
        updateAgreement();
      } else {
        addAgreement();
      }
    } else {
      return false;
    }
  });
}
function updateAgreement() {
  systemnApi.updateAgreement(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}
function addAgreement() {
  systemnApi.addAgreement(form.value).then(() => {
    dialogVisible.value = false;
  });
}
function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    agreement_id: 0,
    agreement_title: "",
    agreement_key: "",
    agreement_content: "",
    agreement_remarks: "",
    agreement_status: 1,
  };
  emit("reacquire");
}
defineExpose({
  init,
});

// 组件销毁时，也及时销毁编辑器，重要！
onBeforeUnmount(() => {
  const editor = editorRef.value;
  if (editor == null) return;
  editor.destroy();
});
</script>
