<template>
  <div class="w-100 h-100">
    <div class="
        w-100
        border-box
        flex
        justify-start
        align-center
        card-shadow
        bg-white
        rounded
        px-1
        overflow-x
      " style="height: 50px">
      <div class="flex justify-start align-center">
        <!-- 用户昵称 -->
        <div class="flex justify-start align-center mr-1">
          <div class="mr-1">协议名称:</div>
          <el-input style="width: 110px" v-model="queryInfo.agreement_title" placeholder="请输入"></el-input>
        </div>
        <!-- 按钮 -->
        <el-button type="primary" @click="query">
          <el-icon :size="15">
            <component :is="Search"></component>
          </el-icon>搜索
        </el-button>
        <el-button type="primary" @click="reset">重置</el-button>
        <el-button v-if="showAdd" @click="add" type="success">
          <el-icon :size="15">
            <component :is="Plus"></component>
          </el-icon>新增
        </el-button>
      </div>
    </div>
    <div class="w-100 mt-1 border-box card-shadow bg-white rounded p-1" style="height: calc(100% - 70px)">
      <!-- 表格 -->
      <div class="w-100" style="height: calc(100% - 60px)">
        <el-table :data="list" class="w-100" height="100%">
          <el-table-column label="序号" align="center" min-width="100">
            <template #default="scope">
              {{scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column v-for="(field, index) in fieldList" :key="index" :prop="field.prop" :label="field.label"
            :width="field.width || ''" align="center">
            <template #default="scope">
              <!-- 操作 -->
              <div v-if="field.prop == 'operation'">
                <!-- 修改按钮 -->
                <el-button :disabled="!showEdit" type="primary" size="small" @click="edit(scope.row)">编辑</el-button>
                <!-- 删除按钮 -->
                <el-button :disabled="!showDelete || scope.row.agreement_id < 3" type="danger" size="small"
                  @click="del(scope.row)">删除
                </el-button>
              </div>
              <!-- 时间 -->
              <div v-else-if="field.prop == 'agreement_creation_time'">
                {{ getFormatTime(scope.row.agreement_creation_time) }}
              </div>
              <span v-else>{{ scope.row[field.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="flex justify-center align-center" style="height: 50px">
        <el-pagination :page-sizes="[10, 50, 100, 200]" background layout="total, sizes, prev, pager, next"
          :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
    <addAgreement ref="addAgreements" @reacquire="reacquire"></addAgreement>
  </div>
</template>

<script setup>
import systemnApi from "@/api/systemnApi.js";
import date from "@/utils/date.js";
import addAgreement from "./addAgreement/addAgreement.vue";
import { ref, reactive, onMounted, computed } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { Search, Plus } from "@element-plus/icons-vue";
import { useStore } from "vuex";
const store = useStore();
let addAgreements = ref(null);

let queryInfo = reactive({
  agreement_title: "",
  pageNum: 1,
  pageSize: 10,
});
const fieldList = [
  // {
  //   id: 1,
  //   prop: "agreement_id",
  //   label: "序号",
  // },
  {
    id: 2,
    prop: "agreement_title",
    label: "协议名称",
  },
  {
    id: 3,
    prop: "agreement_key",
    label: "协议索引",
  },
  {
    id: 4,
    prop: "agreement_creation_time",
    label: "创建时间",
  },
  {
    id: 10,
    prop: "operation",
    label: "操作",
    width: 180,
  },
];
let total = ref(0);
let list = ref([]);

let showAdd = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("10402")) {
    return true;
  } else {
    return false;
  }
});

let showEdit = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("10403")) {
    return true;
  } else {
    return false;
  }
});

let showDelete = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("10404")) {
    return true;
  } else {
    return false;
  }
});

function getFormatTime(value) {
  return date.formatDate(value * 1000);
}


function query() {
  queryInfo.pageNum = 1;
  getAgreementList();
}

function reset() {
  queryInfo.agreement_title = "";
  queryInfo.pageNum = 1;
  queryInfo.pageSize = 10;
  getAgreementList();
}

function getAgreementList() {
  systemnApi.getAgreementList(queryInfo).then((res) => {
    list.value = res.data.list;
    total.value = res.data.total;
  });
}

function add() {
  addAgreements.value.init();
}

function edit(info) {
  addAgreements.value.init(info);
}

function del(info) {
  ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let data = {
        agreement_id: info.agreement_id
      }
      systemnApi.deleteAgreement(data).then(() => {
        ElMessage.success("删除成功");
        reacquire();
      });
    })
    .catch(() => {
      // catch error
    });
}

function handleSizeChange(number) {
  queryInfo.pageSize = number;
  getAgreementList();
}

function handleCurrentChange(newPage) {
  queryInfo.pageNum = newPage;
  getAgreementList();
}

function reacquire() {
  queryInfo.pageNum = 1;
  getAgreementList();
}

onMounted(() => {
  // 获取列表
  getAgreementList();
});
</script>

