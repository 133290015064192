<template>
  <el-container class="h-100 w-100">
    <el-aside :width="menuWidth + 'px'" class="h-100">
      <!-- 头部 -->
      <el-header class="flex justify-center align-center text-white" style="background-color: rgb(54, 127, 169)">
        <div class="flex-1 text-ellipsis">佳易留学管理系统</div>
      </el-header>
      <el-main class="p-1 " style="background-color: rgb(15, 41, 80); height: calc(100% - 60px)">
        <!-- 用户信息 -->
        <div class="w-100 border-box flex justify-start align-center flex-wrap">
          <div class="flex-1 flex justify-center align-center">
            <upload ref="myUpload" uploadKey="user_head" @success="getImg">
              <el-avatar style="width: 40px; height: 40px" size="large" icon="el-icon-s-custom"
                :src="userInfo.user_head"></el-avatar>
            </upload>
          </div>
          <div class="flex-2 text-white">
            <div class="flex align-center">
              <span class="text-ellipsis" style="width: 60px; display: inline-block">{{ userInfo.user_nickname }}</span>
              <div v-if="menuWidth == 200" @click="edit(userInfo)">
                <el-icon :size="15">
                  <component :is="Edit"></component>
                </el-icon>
                <span>修改</span>
              </div>
            </div>
            <div v-if="menuWidth == 200" class="flex justify-between align-center font-sm flex-wrap">
              <div class="flex-1 flex align-center">
                <el-icon :size="15">
                  <component :is="SuccessFilled"></component>
                </el-icon>
                <span style="margin-left: 2px">在线</span>
              </div>
              <div @click="logout" class="flex-1 flex align-center pointer">
                <el-icon :size="15">
                  <component :is="CircleClose" color="#ed5464"></component>
                </el-icon>
                <span style="margin-left: 2px">注销</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 菜单列表 -->
        <div class="w-100 my-1 overflow-hidden">
          <!-- 侧边栏菜单区域 -->
          <el-menu :default-active="editableTabsValue" class="border-0" :collapse="menuWidth == 60" :unique-opened="true"
            background-color="#0f2950" text-color="#fff">
            <label v-for="menu in menulist" :key="menu.permission_id">
              <!-- 123 -->
              <Nav :menuItem="menu" :addTab="addTab"></Nav>
            </label>
          </el-menu>
        </div>
      </el-main>
    </el-aside>
    <el-container class="bg-light">
      <el-header style="background-color: rgb(60, 141, 188)">
        <div class="w-100 h-100 flex justify-between align-center">
          <div @click="setMenuWidth">
            <el-icon :size="30">
              <component :is="menuWidth == 200 ? Fold : Expand" color="#fff"></component>
            </el-icon>
          </div>
          <div class="flex align-center">
            <el-avatar style="width: 40px; height: 40px" size="large" icon="el-icon-s-custom" :src="userInfo.user_head">
            </el-avatar>
            <span class="text-white ml-1 font-sm" style="display: inline-block">{{ userInfo.user_nickname }}</span>
          </div>
        </div>
      </el-header>
      <el-tabs v-model="editableTabsValue" type="card" class="mt-1 mx-1" closable @tab-remove="removeTab"
        @tab-click="setTab">
        <el-tab-pane class="h-10" v-for="item in editableTabs" :key="item.name" :label="item.title" :name="item.name">
        </el-tab-pane>
      </el-tabs>
      <div class="w-100 h-100 pb-1 px-1 border-box">
        <!-- 路由占位符 -->
        <router-view></router-view>
      </div>
    </el-container>
    <addUser ref="addUsers"></addUser>
  </el-container>
</template>
<script setup>
import utils from "@/utils/unit.js";
import systemnApi from "@/api/systemnApi.js";
import upload from "@/components/upload/upload.vue";
import addUser from "@/components/addUser/addUser.vue";
import Nav from "@/components/Menu/Menu.vue";
import { ref, watch, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import {
  Edit,
  SuccessFilled,
  CircleClose,
  Fold,
  Expand,
} from "@element-plus/icons-vue";
let router = useRouter();
const store = useStore();
// 菜单宽度： 200 ： 60
let menuWidth = ref(200);
let addUsers = ref(null);
let userInfo = computed(() => {
  return store.state.userInfo
});

let myUpload = ref(null)
let menulist = ref([]);
const editableTabsValue = ref("");
const editableTabs = ref([]);

function getMenuList() {
  systemnApi.getMenuList().then((res) => {
    console.log('res', res);
    menulist.value = res.data;
    if (menulist.value.length == 0) {
      ElMessage.error(
        "该用户没有任何权限，已退出登录"
      );
      logout()
      return false;
    }
    const activeIndex = window.sessionStorage.getItem("activeIndex");
    if (activeIndex && activeIndex != "undefined") {
      let item = {};
      for (let i = 0; i < menulist.value.length; i++) {
        for (let k = 0; k < menulist.value[i].children.length; k++) {
          if (menulist.value[i].children[k].permission_url == activeIndex) {
            item = menulist.value[i].children[k]
          }
        }
      }
      if (item && item.permission_url) {
        addTab(item)
      }
    } else {
      utils.findNoChild(menulist.value, (item) => {
        addTab(item);
      })
    }
  });
}

const addTab = (menuItem) => {
  // console.log(menuItem);
  // 判断是否存在
  const index = editableTabs.value.findIndex((item) => {
    return item.name == menuItem.permission_url;
  });
  if (index == -1) {
    editableTabs.value.push({
      title: menuItem.permission_name,
      name: menuItem.permission_url,
    });
    editableTabsValue.value = menuItem.permission_url;
    window.sessionStorage.setItem("activeIndex", menuItem.permission_url);
    router.push({
      path: menuItem.permission_url,
    });
  } else {
    console.log(editableTabs.value[index])
    editableTabsValue.value = editableTabs.value[index].name;
    window.sessionStorage.setItem("activeIndex", editableTabs.value[index].name);
    router.replace({
      path: editableTabs.value[index].name,
    });
  }
};
function setTab(tabItem) {
  console.log(tabItem.paneName);
  router.push({
    path: tabItem.paneName,
  });
}
const removeTab = (targetName) => {
  if (editableTabs.value.length == 1) {
    return false;
  }
  const tabs = editableTabs.value;
  let activeName = editableTabsValue.value;
  if (activeName === targetName) {
    tabs.forEach((tab, index) => {
      if (tab.name === targetName) {
        const nextTab = tabs[index + 1] || tabs[index - 1];
        if (nextTab) {
          activeName = nextTab.name;
        }
      }
    });
  }
  editableTabsValue.value = activeName;
  editableTabs.value = tabs.filter((tab) => tab.name !== targetName);
};

function getImg(key, value) {
  userInfo[key] = value;
  updateUserHead();
  myUpload.value.clearFiles()
}
function updateUserHead() {
  systemnApi.updateUserHead(userInfo).then(() => {
    ElMessage.success(
      "修改成功，为了保证本次运行正常，更新信息将在下次登录后显示"
    );
    // 设置缓存
    window.localStorage.setItem("userInfo", JSON.stringify(userInfo));
    store.commit("SET_VALUE", { key: "userInfo", value: userInfo });
  });
}

function edit(userInfo) {
  let newUserInfo = JSON.parse(JSON.stringify(userInfo));
  addUsers.value.init(newUserInfo, true);
}

function logout() {
  window.localStorage.clear();
  window.sessionStorage.clear();
  router.replace({
    path: "/login",
  });
}

function setMenuWidth() {
  if (menuWidth.value == 200) {
    menuWidth.value = 60;
  } else {
    menuWidth.value = 200;
  }
}
// watch(() => router, (newValue, oldValue) => {
//   console.log(newValue, '新的路由')
//   console.log(oldValue, '旧的路由')
// })
watch(store.state.userInfo, (newValue) => {
  console.log("变了", newValue);
}, { deep: true });
getMenuList();
onMounted(() => {
  // 获取历史激活
})
</script>
<style>
.el-tabs__header {
  background-color: #fff;
}
</style>