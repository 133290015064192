<template>
<div>
  <!-- 对话框 -->
  <el-dialog
    v-model="dialogVisible"
    title="会员申请详情"
    width="1100px"
    @close="dialogClosed"
  >
    <!-- 表单数据 -->
    <el-descriptions :column="3" border>
      <el-descriptions-item label="会员姓名">
        {{ queryInfo.member_name || "未填写" }}
      </el-descriptions-item>
      <el-descriptions-item label="联系方式">
        {{ queryInfo.member_phone || "未填写" }}
      </el-descriptions-item>
    </el-descriptions>
    <!-- 内容主体区域 -->
    <div class="w-100 mt-1" style="height: 450px">
      <el-table :data="list" class="w-100" height="100%">
        <el-table-column prop="apply_id" label="序号" align="center"/>
        <el-table-column prop="apply_application_name" label="申请院校名称" align="center"/>
        <el-table-column prop="apply_application_professional" label="目标专业方向" align="center"/>
        <el-table-column prop="apply_application_major_name" label="具体专业名称" align="center"/>
        <el-table-column prop="apply_application_major_name" label="具体专业名称" align="center"/>
        <el-table-column prop="apply_status" label="当前进度" align="center">
          <template #default="scope">
           {{ getApplyStatusLabel(scope.row.apply_status || 1)}}
          </template>
        </el-table-column>
        <el-table-column prop="apply_creation_time" label="申请时间" align="center">
          <template #default="scope">
           {{ getFormatTime(scope.row.apply_creation_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" align="center">
          <template #default="scope">
            <el-button
              :disabled="!showEdit"
              type="primary"
              size="small"
              @click="updateApplication(scope.row)"
              >编辑</el-button
            >
            <el-button
              :disabled="!showDelete"
              type="danger"
              size="small"
              @click="del(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <!-- <el-table-column v-for="(field, index) in fieldList" :key="index" :prop="field.prop" :label="field.label"
          :width="field.width || ''" align="center">
          <template #default="scope">
            <div v-if="field.prop == 'apply_creation_time'">
              {{ getFormatTime(scope.row.apply_creation_time) }}
            </div>
            <div v-else-if="field.prop == 'apply_status'">
              {{ getApplyStatusLabel(scope.row.apply_status || 1)}}
            </div>
              <div v-else-if="field.prop == 'operation'">
                <el-button :disabled="!showEdit" type="primary" size="small" @click="edit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  :disabled="!showDelete"
                  type="danger"
                  size="small"
                  @click="del(scope.row)"
                  >删除</el-button
                >
              </div>
            <span v-else>{{ scope.row[field.prop] }}</span>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="flex justify-center align-center" style="height: 50px">
      <el-pagination
        :page-sizes="[10, 50, 100, 200]"
        background
        layout="total, sizes, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">关 闭</el-button>
        <el-button @click="exportSpecialExcel" type="success">
          <el-icon :size="15">
            <component :is="Download"></component> </el-icon
          >导出
        </el-button>
      </span>
    </template>
  </el-dialog>
  <addApplication ref="addApplications" @reacquire="reacquire"></addApplication>
  </div>
</template>
<script setup>
import memberApi from "@/api/memberApi.js";
import XLSX from "@/utils/xlsx.core.min.js";
import addApplication from "@/pages/ApplicationProgress/addApplication/addApplication.vue";
import date from "@/utils/date.js";
import { ref, reactive, computed, defineEmits, defineExpose } from "vue";
import { Download } from "@element-plus/icons-vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { useStore } from "vuex";
const store = useStore();
const emit = defineEmits(["reacquire"]);
let addApplications = ref(null);
let dialogVisible = ref(false);
let queryInfo = reactive({
  member_id: "",
  member_name: "",
  member_phone: "",
  pageNum: 1,
  pageSize: 10,
});
// 申请状态1:信息咨询，2选择学校专业，3资料准备，4递交资料，5待校方审核，6已出结果
let option = [
  {
    value: 1,
    label: "信息咨询",
  },
  {
    value: 2,
    label: "选择学校专业",
  },
  {
    value: 3,
    label: "资料准备",
  },
  {
    value: 4,
    label: "递交资料",
  },
  {
    value: 5,
    label: "待校方审核",
  },
  {
    value: 6,
    label: "已出结果",
  },
];

let fieldList = ref([
  {
    id: 1,
    prop: "apply_id",
    label: "序号",
  },
  {
    id: 2,
    prop: "apply_application_name",
    label: "申请院校名称",
  },
  {
    id: 3,
    prop: "apply_application_professional",
    label: "目标专业方向",
  },
  {
    id: 4,
    prop: "apply_application_major_name",
    label: "具体专业名称",
  },
  {
    id: 5,
    prop: "apply_status",
    label: "当前进度",
  },
  {
    id: 8,
    prop: "apply_creation_time",
    label: "申请时间",
  },
  {
    id: 10,
    prop: "operation",
    label: "操作",
    width: 180,
  },
]);

let showEdit = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("30303")) {
    return true;
  } else {
    return false;
  }
});

let showDelete = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("30304")) {
    return true;
  } else {
    return false;
  }
});

let list = ref([]);
let total = ref(0);

function getFormatTime(value) {
  return date.formatDate(value * 1000);
}

function getApplyStatusLabel(status) {
  return option.find((item) => item.value == status).label;
}

function init(info = null) {
  if (info) {
    queryInfo.member_name = info.member_name;
    queryInfo.member_phone = info.member_phone;
    queryInfo.member_id = info.member_id;
    getApplyList();
  }
  dialogVisible.value = true;
}

function getApplyList() {
  memberApi.getApplyList(queryInfo).then((res) => {
    list.value = res.data.list;
    total.value = res.data.total;
  });
}

function handleSizeChange(number) {
  queryInfo.pageSize = number;
  getApplyList();
}

function handleCurrentChange(newPage) {
  queryInfo.pageNum = newPage;
  getApplyList();
}

function exportSpecialExcel() {
  let xlsxTable = [];
  // 创建 标题
  xlsxTable.push([
    "会员姓名",
    "联系电话",
    "序号",
    "申请院校名称",
    "目标专业方向",
    "具体专业名称",
    "当前进度",
    "申请时间",
  ]);
  // 创建数据内容
  for (const item of list.value) {
    let info = [
      queryInfo.member_name,
      queryInfo.member_phone,
      item.apply_id,
      item.apply_application_name,
      item.apply_application_professional,
      item.apply_application_major_name,
      getApplyStatusLabel(item.apply_status),
      getFormatTime(item.apply_creation_time),
    ];
    xlsxTable.push(info);
  }
  var sheet = XLSX.utils.aoa_to_sheet(xlsxTable);
  // sheet['!merges'] = [
  //   // 设置A1-C1的单元格合并
  //   { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }
  // ];
  openDownloadDialog(
    sheet2blob(sheet),
    `${queryInfo.member_name}申请详情表.xlsx`
  );
}

function openDownloadDialog(url, saveName) {
  if (typeof url == "object" && url instanceof Blob) {
    url = URL.createObjectURL(url); // 创建blob地址
  }
  var aLink = document.createElement("a");
  aLink.href = url;
  aLink.download = saveName || ""; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
  var event;
  if (window.MouseEvent) event = new MouseEvent("click");
  else {
    event = document.createEvent("MouseEvents");
    event.initMouseEvent(
      "click",
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
  }
  aLink.dispatchEvent(event);
}



// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName) {
  sheetName = sheetName || "sheet1";
  var workbook = {
    SheetNames: [sheetName],
    Sheets: {},
  };
  workbook.Sheets[sheetName] = sheet;
  // 生成excel的配置项
  var wopts = {
    bookType: "xlsx", // 要生成的文件类型
    bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    type: "binary",
  };
  var wbout = XLSX.write(workbook, wopts);
  var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
  // 字符串转ArrayBuffer
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  return blob;
}

function del(info) {
  ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let data = {
        apply_id: info.apply_id
      }
      memberApi.deleteApply(data).then((res) => {
        console.log(res);
        ElMessage.success("删除成功");
        reacquire();
      });
    })
    .catch(() => {
      // catch error
    });
}

function updateApplication(info) {
  addApplications.value.init(JSON.parse(JSON.stringify(info)));
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

function reacquire() {
  queryInfo.pageNum = 1;
  getApplyList();
}

function dialogClosed() {
  fieldList.value = [];
  list.value = [];
  emit("reacquire");
}
defineExpose({
  init,
});
</script>

<style>
.canvasTable {
  border-collapse: collapse;
}

.canvasTable th,
.canvasTable td {
  border: solid 1px #6d6d6d;
  padding: 5px 10px;
}
</style>
