<template>
  <div class="w-100 h-100">
    <div class="
        w-100
        border-box
        flex
        justify-start
        align-center
        card-shadow
        bg-white
        rounded
        px-1
        overflow-x
      " style="height: 50px">
      <div class="flex justify-start align-center">
        <!-- 用户昵称 -->
        <div class="flex justify-start align-center mr-1">
          <div class="mr-1">会员名称:</div>
          <el-input style="width: 110px" v-model="queryInfo.screen_member_name" placeholder="请输入"></el-input>
        </div>
        <!-- 按钮 -->
        <el-button type="primary" @click="query">
          <el-icon :size="15">
            <component :is="Search"></component>
          </el-icon>搜索
        </el-button>
        <el-button type="primary" @click="reset">重置</el-button>
        <el-button v-if="showEdit" type="primary" @click="updateScore">评分规则</el-button>
        <el-button v-if="showEdit" type="primary" @click="updateMatching">评分标准</el-button>
      </div>
    </div>
    <div class="w-100 mt-1 border-box card-shadow bg-white rounded p-1" style="height: calc(100% - 70px)">
      <!-- 表格 -->
      <div class="w-100" style="height: calc(100% - 60px)">
        <el-table :data="list" class="w-100" height="100%">
          <el-table-column label="序号" align="center" min-width="100">
            <template #default="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column v-for="(field, index) in fieldList" :key="index" :prop="field.prop" :label="field.label"
            :width="field.width || ''" align="center">
            <template v-if="field.children && field.children.length > 0" #default>
              <el-table-column v-for="(field2, index) in field.children" :key="index" :prop="field2.prop"
                :label="field2.label" :width="field2.width || ''" align="center">
                <template #default="scope">
                  <div v-if="field2.prop == 'screen_IELTS'">
                    {{ getLabel('memberIELTSLList', scope.row.screen_IELTS) }}
                  </div>
                  <div v-else-if="field2.prop == 'screen_TOEFL'">
                    {{ getLabel('memberTOEFLList', scope.row.screen_TOEFL) }}
                  </div>
                  <div v-else-if="field2.prop == 'screen_GRE'">
                    {{ getLabel('memberGREList', scope.row.screen_GRE) }}
                  </div>
                  <div v-else-if="field2.prop == 'screen_GMAT'">
                    {{ getLabel('memberGMATList', scope.row.screen_GMAT) }}
                  </div>
                  <span v-else>{{ scope.row[field.prop] }}</span>
                </template>
              </el-table-column>
            </template>

            <template v-else #default="scope">
              <!-- 操作 -->
              <div v-if="field.prop == 'operation'">
                <el-button type="primary" size="small" @click="seeScoreDetails(scope.row)">查看详情</el-button>
                <!-- 删除按钮 -->
                <el-button :disabled="!showDelete" type="danger" size="small" @click="del(scope.row)">删除
                </el-button>
              </div>
              <div v-else-if="field.prop == 'screen_achievement'">
                {{getLabel('memberGPAList', scope.row.screen_achievement)}}
              </div>
              <div v-else-if="field.prop == 'screen_education'">
                {{getLabel('memberEducationList', scope.row.screen_education)}}
              </div>
              <div v-else-if="field.prop == 'screen_attribute'">
                {{getLabel('memberAttributeList', scope.row.screen_attribute)}}
              </div>
              <div v-else-if="field.prop == 'screen_score'">
                {{getMemberScore(scope.row)}}
              </div>
              <div v-else-if="field.prop == 'screen_creation_time'">
                {{getFormatTime(scope.row.screen_creation_time)}}
              </div>
              <span v-else>{{ scope.row[field.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="flex justify-center align-center" style="height: 50px">
        <el-pagination :page-sizes="[ 10, 50, 100, 200]" background layout="total, sizes, prev, pager, next"
          :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
    <addScore ref="addScores" @reacquire="reacquire"></addScore>
    <addMatching ref="addMatchings" @reacquire="reacquire"></addMatching>
    <scoreDetails ref="scoreDetailss" @reacquire="reacquire"></scoreDetails>
  </div>
</template>

<script setup>
import memberApi from "@/api/memberApi.js";
import date from "@/utils/date.js";
import addScore from "@/components/addScore/addScore.vue";
import addMatching from "@/components/addMatching/addMatching.vue";
import scoreDetails from "@/components/scoreDetails/scoreDetails.vue";
import { ref, reactive, onMounted, computed } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { Search } from "@element-plus/icons-vue";
import { useStore } from "vuex";
const store = useStore();
let addScores = ref(null);
let addMatchings = ref(null);
let scoreDetailss = ref(null);
let queryInfo = reactive({
  screen_member_name: "",
  screen_group: "postgraduate",
  pageNum: 1,
  pageSize: 10,
});

const fieldList = [
  // {
  //   id: 1,
  //   prop: "screen_id",
  //   label: "序号",
  // },
  {
    id: 2,
    prop: "screen_education",
    label: "最高学历/目前在读",
  },
  {
    id: 3,
    prop: "screen_attribute",
    label: "最高学历学校院校属性",
  },
  {
    id: 4,
    prop: "screen_country",
    label: "留学目的地国家/地区",
  },
  {
    id: 13,
    prop: "screen_major",
    label: "期望留学专业方向",
  },
  {
    id: 14,
    prop: "screen_achievement",
    label: "大学评价成绩区间",
  },
  {
    id: 5,
    prop: "",
    label: "外语沟通能力",
    children: [
      {
        id: 501,
        prop: "screen_IELTS",
        label: "雅思",
      },
      {
        id: 502,
        prop: "screen_TOEFL",
        label: "托福",
      },
    ],
  },
  {
    id: 6,
    prop: "",
    label: "外语学术能力",
    children: [
      {
        id: 601,
        prop: "screen_GRE",
        label: "GRE",
      },
      {
        id: 602,
        prop: "screen_GMAT",
        label: "GMAT总分",
      },
    ],
  },
  {
    id: 7,
    prop: "screen_target",
    label: "留学目标",
  },
  {
    id: 8,
    prop: "screen_score",
    label: "总评分",
  },
  {
    id: 9,
    prop: "screen_member_name",
    label: "会员姓名",
  },
  {
    id: 11,
    prop: "screen_member_phone",
    label: "联系方式",
  },
  {
    id: 12,
    prop: "screen_creation_time",
    label: "匹配时间",
  },
  {
    id: 10,
    prop: "operation",
    label: "操作",
    width: 180,
  },
];

const optionList = {
  memberEducationList: [{
    value: 1,
    label: '大专'
  },
  {
    value: 2,
    label: '本科'
  },
  {
    value: 3,
    label: '硕士'
  },
  {
    value: 4,
    label: '博士'
  },
  ],
  memberAttributeList: [{
    value: 1,
    label: '一本'
  },
  {
    value: 2,
    label: '双一流'
  },
  {
    value: 3,
    label: '985'
  },
  {
    value: 4,
    label: '211'
  },
  {
    value: 5,
    label: '二本'
  },
  {
    value: 6,
    label: '其他'
  },
  ],
  memberGPAList: [{
    value: 1,
    label: '>=90'
  },
  {
    value: 2,
    label: '85~90'
  },
  {
    value: 3,
    label: '80~84'
  },
  {
    value: 4,
    label: '75~79'
  },
  {
    value: 5,
    label: '<=74'
  }
  ],
  memberTOEFLList: [{
    value: 1,
    label: '>=98'
  },
  {
    value: 2,
    label: '78~97'
  },
  {
    value: 3,
    label: '<=77'
  },
  {
    value: 4,
    label: '不清楚'
  }
  ],
  memberIELTSLList: [{
    value: 1,
    label: '>=7'
  },
  {
    value: 2,
    label: '6.5'
  },
  {
    value: 3,
    label: '<=6'
  },
  {
    value: 4,
    label: '不清楚'
  }
  ],
  memberGREList: [{
    value: 1,
    label: '>=330'
  },
  {
    value: 2,
    label: '300~329'
  },
  {
    value: 3,
    label: '<300'
  },
  {
    value: 4,
    label: '不清楚'
  }
  ],
  memberGMATList: [{
    value: 1,
    label: '>=710'
  },
  {
    value: 2,
    label: '650~709'
  },
  {
    value: 3,
    label: '600~649'
  },
  {
    value: 4,
    label: '550~599'
  },
  {
    value: 5,
    label: '<=549'
  },
  {
    value: 6,
    label: '不清楚'
  }
  ]
};

let total = ref(0);
let list = ref([]);

let keys = ref([
  "education",
  "attribute",
  "GPA",
  "IELTS",
  "TOEFL",
  "GRE",
  "GMAT",
]);

let scoreList = ref([]);


let showEdit = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("40103")) {
    return true;
  } else {
    return false;
  }
});

let showDelete = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("40104")) {
    return true;
  } else {
    return false;
  }
});

function getLabel(list, value) {
  const item = optionList[list].find(item => item.value == value);
  if (item && item.label) {
    return item.label;
  } else {
    return '未选择';
  }
}

function getMemberScore(info) {
  console.log('info', info);
  if (info && info.screen_id) {
    let scoreNumber = 0;
    let keyList = JSON.parse(JSON.stringify(keys.value));

    let values = [
      Number(info.screen_education),
      Number(info.screen_attribute),
      Number(info.screen_achievement),
      Number(info.screen_IELTS),
      Number(info.screen_TOEFL),
      Number(info.screen_GRE),
      Number(info.screen_GMAT),
    ];
    // 判断用户的雅思成绩高还是托福成绩高
    if (info.screen_IELTS > 0 && info.screen_TOEFL > info.screen_IELTS) {
      // console.log("雅思成绩高");
      keyList.splice(4, 1);
      values.splice(4, 1);
    } else if (info.screen_TOEFL == 0) {
      // console.log("雅思成绩高");
      keyList.splice(4, 1);
      values.splice(4, 1);
    } else {
      // console.log("托福成绩高");
      keyList.splice(3, 1);
      values.splice(3, 1);
    }
    // 判断用户的GRE成绩高还是GMAT成绩高
    if (info.screen_GRE > 0 && info.screen_GMAT > info.screen_GRE) {
      // console.log("GRE成绩高");
      keyList.splice(5, 1);
      values.splice(5, 1);
    } else if (info.screen_GMAT == 0) {
      // console.log("GRE成绩高");
      keyList.splice(4, 1);
      values.splice(4, 1);
    } else {
      // console.log("GMAT成绩高");
      keyList.splice(4, 1);
      values.splice(4, 1);
    }
    // console.log('scoreList', scoreList);
    for (let index = 0; index < keyList.length; index++) {
      let scoreData = scoreList.value.find((item) => {
        return (
          item.score_standard == values[index] &&
          item.score_basis == keyList[index]
        );
      });
      console.log('scoreData', scoreData);
      let number = 0;
      if (scoreData && scoreData.score_value) {
        number = scoreData.score_value;
      }
      scoreNumber += Number(number);
    }
    console.log('scoreNumber', scoreNumber);
    console.log('-----------------------------------------');
    return scoreNumber;
  } else {
    return 0;
  }
}

function updateScore() {
  addScores.value.init('postgraduate')
}

function updateMatching() {
  addMatchings.value.init('postgraduate')
}

function seeScoreDetails(info) {
  scoreDetailss.value.init('postgraduate', info)
}

function getFormatTime(value) {
  return date.formatDate(value * 1000);
}

function query() {
  queryInfo.pageNum = 1;
  getScreenList();
}

function reset() {
  queryInfo.screen_member_name = "";
  queryInfo.pageNum = 1;
  queryInfo.pageSize = 10;
  getScreenList();
}

function getScreenList() {
  memberApi.getScreenList(queryInfo).then((res) => {
    list.value = res.data.list;
    total.value = res.data.total;

  });
}

// 获取评分
function getScoreList() {
  memberApi.getScoreList({ score_group: 'postgraduate' }).then((res) => {
    scoreList.value = res.data;

  });
}


function del(info) {
  ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let data = {
        screen_id: info.screen_id
      }
      memberApi.deleteScreen(data).then((res) => {
        console.log(res);
        ElMessage.success("删除成功");
        reacquire();
      });
    })
    .catch(() => {
      // catch error
    });
}

function handleSizeChange(number) {
  queryInfo.pageSize = number;
  getScreenList();
}

function handleCurrentChange(newPage) {
  queryInfo.pageNum = newPage;
  getScreenList();
}

function reacquire() {
  queryInfo.pageNum = 1;
  getScreenList();
}

onMounted(() => {
  // 获取列表
  getScreenList();
  getScoreList();
});
</script>

