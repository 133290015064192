import template from './template'
const routes = [
    // 路由重定向
    { path: '/', redirect: '/login' },
    {
        path: '/login',
        component: template.Login
    },
    // 首页
    {
        path: '/home',
        component: template.Home,
        name: "首页",
        children: [
            {
                path: '/user_management',
                component: template.UserManagement,
                name: "用户管理",
            },
            {
                path: '/role_management',
                component: template.RoleManagement,
                name: "角色管理",
            },
            {
                path: '/post_management',
                component: template.PostManagement,
                name: "岗位管理",
            },
            {
                path: '/agreement_management',
                component: template.AgreementManagement,
                name: "协议管理",
            },
            {
                path: '/problem_management',
                component: template.ProblemManagement,
                name: "问题管理",
            },

            {
                path: '/professional_information',
                component: template.ProfessionalInformation,
                name: "专业信息",
            },
            {
                path: '/college_information',
                component: template.CollegeInformation,
                name: "院校信息",
            },
            {
                path: '/application_information',
                component: template.ApplicationInformation,
                name: "院校申请信息",
            },
            {
                path: '/banner_management',
                component: template.BannerManagement,
                name: "banner管理",
            },
            {
                path: '/notice_management',
                component: template.NoticeManagement,
                name: "公告管理",
            },

            {
                path: '/member_information',
                component: template.MemberInformation,
                name: "会员信息",
            },
            {
                path: '/focus_information',
                component: template.FocusInformation,
                name: "关注信息",
            },

            {
                path: '/excel_sheet_ordering',
                component: template.ExcelSheetOrdering,
                name: "Excel表订购",
            },
            {
                path: '/member_consultation',
                component: template.MemberConsultation,
                name: "会员咨询",
            },
            {
                path: '/promotion_information',
                component: template.PromotionInformation,
                name: "背景提升信息",
            },
            {
                path: '/application_progress',
                component: template.ApplicationProgress,
                name: "院校申请进度",
            },
            {
                path: '/postgraduate_match_list',
                component: template.PostgraduateMatchList,
                name: "研究生匹配名单",
            },
            {
                path: '/college_student_match_list',
                component: template.CollegeStudentMatchList,
                name: "大学生匹配名单",
            },
            {
                path: '/high_school_student_match_list',
                component: template.HighSchoolStudentMatchList,
                name: "高中生匹配名单",
            },
            {
                path: '/message_management',
                component: template.MessageManagement,
                name: "留言管理",
            },
            {
                path: '/document_template',
                component: template.DocumentTemplate,
                name: "文档模版",
            },
            {
                path: '/member_documentation',
                component: template.MemberDocumentation,
                name: "会员文档",
            },
        ]
    },
];

export default routes;