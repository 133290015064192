<template>
  <!-- 对话框 -->
  <el-dialog v-model="dialogVisible" :title="title" width="500px" @close="dialogClosed">
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="100px">
      <el-form-item label="会员名称：" prop="consult_member_name">
        <el-input disabled v-model="form.consult_member_name"></el-input>
      </el-form-item>
      <el-form-item label="联系方式：" prop="consult_member_phone">
        <el-input disabled v-model="form.consult_member_phone"></el-input>
      </el-form-item>
      <el-form-item label="咨询状态：" prop="consult_status">
        <el-radio v-model="form.consult_status" :label="0" size="large">未处理</el-radio>
        <el-radio v-model="form.consult_status" :label="1" size="large">已处理</el-radio>
      </el-form-item>
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import memberApi from "@/api/memberApi.js";
import { ref, reactive, defineEmits, defineExpose, computed } from "vue";
import { ElMessage } from "element-plus";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let title = computed(() => {
  if (form.value.consult_id == 0) {
    return "会员咨询添加";
  } else {
    return "会员咨询修改";
  }
});
let formRef = ref(null);
let form = ref({
  consult_id: 0,
  consult_member_id: "",
  consult_member_name: "",
  consult_member_phone: "",
  consult_application_country: "",
  consult_application_professional: "",
  consult_application_class: "",
  consult_target: "",
  consult_content: "",
  consult_status: 0,
});
const formRules = reactive({

});

function init(info = null) {
  if (info) {
    form.value = info;
  }
  dialogVisible.value = true;
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      if (form.value.consult_id && form.value.consult_id != 0) {
        updateConsult();
      } else {
        addConsult();
      }
    } else {
      return false;
    }
  });
}
function updateConsult() {
  memberApi.updateConsult(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}
function addConsult() {
  memberApi.addConsult(form.value).then(() => {
    dialogVisible.value = false;
  });
}
function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    consult_id: 0,
    consult_member_id: "",
    consult_member_name: "",
    consult_member_phone: "",
    consult_application_country: "",
    consult_application_professional: "",
    consult_application_class: "",
    consult_target: "",
    consult_content: "",
    consult_status: 0,
  };
  emit("reacquire");
}
defineExpose({
  init,
});
</script>
