<template>
  <!-- 对话框 -->
  <el-dialog v-model="dialogVisible" :title="title" width="900px" @close="dialogClosed">
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="100px">
      <div class="flex">
        <div class="flex-1">
          <el-form-item label="角色名称：" prop="role_name">
            <el-input v-model="form.role_name" placeholder="请输入角色名称"></el-input>
          </el-form-item>
          <el-form-item label="权限归属：" prop="role_ascription">
            <el-select @change="setSelectKey" :disabled="form.role_id == 1" class="w-100" v-model="form.role_ascription"
              placeholder="请选择权限归属">
              <el-option v-for="item in ascriptionOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="菜单权限：" prop="role_menu_ids">
            <div class="border rounded mr-1 p-1 w-100 position-relative"
              :class="form.role_ascription ? 'overflow-hidden' : 'overflow-y'" style="height: 260px;">
              <el-tree :data="permissionList" :props="treeProps" show-checkbox node-key="permission_id"
                default-expand-all :default-checked-keys="selectKey" ref="treeRef"></el-tree>
              <div v-if="form.role_ascription" class="position-absolute w-100 h-100 top-0 left-0"
                style="background-color: rgba(0,0,0,0.1); z-index: 99999"></div>
            </div>
          </el-form-item>
        </div>
        <div class="flex-1">
          <el-form-item label="权限字符：" prop="role_character">
            <el-input :disabled="form.role_id == 1" v-model="form.role_character" placeholder="请输入权限字符"></el-input>
          </el-form-item>
          <el-form-item label="状态：" prop="role_status">
            <el-switch :disabled="form.role_id == 1" v-model="form.role_status" active-color="#13ce66"
              inactive-color="#ff4949" :active-value="1" :inactive-value="0" />
          </el-form-item>
          <el-form-item label="备注：" prop="role_remarks">
            <el-input type="textarea" :rows="13" v-model="form.role_remarks" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import systemnApi from "@/api/systemnApi.js";
import { ref, reactive, defineEmits, defineExpose, computed } from "vue";
import { ElMessage } from "element-plus";
const emit = defineEmits(["reacquire"]);
const treeRef = ref(null);
let dialogVisible = ref(false);
let title = computed(() => {
  if (form.value.role_id == 0) {
    return "角色添加";
  } else {
    return "角色修改";
  }
});
const ascriptionOptions = [
  {
    value: 0,
    label: '自定义数据权限'
  },
  {
    value: 1,
    label: '全部数据权限'
  }
];

// 树形控件的属性绑定对象
const treeProps = {
  label: "permission_name",
  children: "children",
};
let permissionList = ref([]);
let selectKey = ref([]);
let formRef = ref(null);
let form = ref({
  role_id: 0,
  role_name: "",
  role_ascription: 0,
  role_character: "",
  role_status: 1,
  role_menu_ids: "",
  role_remarks: "",
  children: [],
});
const formRules = reactive({
  role_name: [
    { required: true, message: "请输入角色姓称", trigger: "blur" },
  ],
  role_ascription: [{ required: true, message: "请选择角色权限归属", trigger: "change" }],
  role_character: [
    { required: true, message: "请输入权限字符", trigger: "blur" },
  ],
});

function init(info = null) {
  if (info) {
    form.value = info;
    getPermissionList(info)
  }
  getPermissionList()
  dialogVisible.value = true;
}

function getPermissionList(info = null) {
  systemnApi.getPermissionList().then(res => {
    console.log(res);
    permissionList.value = res.data;
    if (info) {
      getLeafKeys(info, selectKey.value);
    }
    console.log(selectKey.value)
  })
}

// 通过递归的形式，获取角色下所以三级权限id，并保存到defKeys数组中
function getLeafKeys(node, arr) {
  // 如果当前node节点不包含children属性，则是三级节点
  if (node.children.length == 0) {
    return arr.push(node.permission_id);
  }
  node.children.forEach((item) => getLeafKeys(item, arr));
}

function setSelectKey() {
  if (form.value.role_ascription) {
    getLeafKeys({children:permissionList.value}, selectKey.value)
    treeRef.value.setCheckedKeys(selectKey.value, false)
  } else {
    treeRef.value.setCheckedKeys([], false)
  }
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      // 删除不必要字段
      delete form.value.children;
      // 设置选中权限
      const keys = [...treeRef.value.getCheckedKeys(), ...treeRef.value.getHalfCheckedKeys()];
      form.value.role_menu_ids = keys.sort().join(",");
      if (form.value.role_id && form.value.role_id != 0) {
        updateRole();
      } else {
        addRole();
      }
    } else {
      return false;
    }
  });
}
function updateRole() {
  systemnApi.updateRole(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}
function addRole() {
  systemnApi.addRole(form.value).then(() => {
    dialogVisible.value = false;
  });
}
function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    role_id: 0,
    role_name: "",
    role_ascription: 0,
    role_character: "",
    role_status: 1,
    role_menu_ids: "",
    role_remarks: "",
  };
  emit("reacquire");
}
defineExpose({
  init,
});
</script>
