<template>
  <!-- 对话框 -->
  <el-dialog v-model="dialogVisible" :title="title" width="520px" @close="dialogClosed">
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="120px">
      <el-form-item label="banner类型：" prop="banner_canal_type">
        <el-select  class="w-100" v-model="form.banner_canal_type" placeholder="请选择banner类型">
          <el-option v-for="item in type_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="banner途径：" prop="banner_type">
        <el-select @change="setBannerSort" class="w-100" v-model="form.banner_type" placeholder="请选择banner途径">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="等级排序：" prop="banner_sort">
        <el-input type="number" v-model="form.banner_sort" placeholder="请输入等级排序"></el-input>
      </el-form-item>
      <el-form-item label="标题名称：" prop="banner_name">
        <el-input v-model="form.banner_name" placeholder="请输入标题名称"></el-input>
      </el-form-item>
      <el-form-item label="状态：" prop="banner_status">
        <el-switch v-model="form.banner_status" active-color="#13ce66" inactive-color="#ff4949" :active-value="1"
          :inactive-value="0" />
      </el-form-item>
      <el-form-item label="上传图片：" prop="banner_url">
        <upload ref="myUpload" uploadKey="banner_url" @success="getImg">
          <div class="overflow-hidden rounded border flex justify-center align-center"
            style="width: 360px;height: 200px">
            <el-image class="w-100 h-100" v-if="form.banner_url" :src="form.banner_url" fit="cover" />
            <el-icon v-else :size="40">
              <Plus />
            </el-icon>
          </div>
        </upload>
      </el-form-item>
      <!-- <el-form-item label="备注：" prop="banner_remarks">
        <el-input type="textarea" :rows="4" v-model="form.banner_remarks" placeholder="请输入"></el-input>
      </el-form-item> -->
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { type_options, app_banner_options, pc_banner_options } from "@/utils/options.js";
import systemnApi from "@/api/systemnApi.js";
import upload from "@/components/upload/upload.vue";
import { ref, reactive, defineEmits, defineExpose, computed } from "vue";
import { ElMessage } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let myUpload = ref(null)
let title = computed(() => {
  if (form.value.banner_id == 0) {
    return "banner添加";
  } else {
    return "banner修改";
  }
});

let options = computed(() => {
  if(form.value.banner_canal_type == 1) {
    return pc_banner_options;
  } else if(form.value.banner_canal_type === 0) {
    return app_banner_options;
  }
  return []
});

let sortData = ref({});
let formRef = ref(null);
let form = ref({
  banner_id: 0,
  banner_type: "",
  banner_canal_type: "",
  banner_name: "",
  banner_sort: 0,
  banner_status: 1,
  banner_url: "",
  banner_remarks: "",
});
const formRules = reactive({
  banner_canal_type: [{ required: true, message: "请选择banner类型", trigger: "change" }],
  banner_type: [{ required: true, message: "请选择banner途径", trigger: "change" }],
  banner_sort: [{ required: true, message: "请输入等级排序", trigger: "blur" }],
  banner_name: [{ required: true, message: "请输入标题名称", trigger: "blur" }],
  banner_url: [{ required: true, message: "请上传图片", trigger: "change" }],
});

function init(info = null) {
  if (info) {
    form.value = info;
  }
  getBannerNumberByType()
  dialogVisible.value = true;
}

function getBannerNumberByType() {
  systemnApi.getBannerNumberByType().then(res => {
    console.log(res)
    sortData.value = res.data;
  })
}

function setBannerSort() {
  if (form.value.banner_type == 'home') {
    form.value.banner_sort = sortData.value.home_number + 1;
  } else if (form.value.banner_type == 'query') {
    form.value.banner_sort = sortData.value.query_number + 1;
  } else if (form.value.banner_type == 'select') {
    form.value.banner_sort = sortData.value.select_number + 1;
  }
}

function getImg(key, value) {
  form.value[key] = value;
  myUpload.value.clearFiles()
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      if (form.value.banner_id && form.value.banner_id != 0) {
        updateBanner();
      } else {
        if (form.value.banner_type == 'home' && sortData.value.home_number > 5) {
          ElMessage.success("所选类型已经有5张banner,无法继续添加");
          return false;
        } else if (form.value.banner_type == 'query' && sortData.value.uery_number > 5) {
          ElMessage.success("所选类型已经有5张banner,无法继续添加");
          return false;
        } else if (form.value.banner_type == 'select' && sortData.value.select_number > 5) {
          ElMessage.success("所选类型已经有5张banner,无法继续添加");
          return false;
        }
        addBanner();
      }
    } else {
      return false;
    }
  });
}
function updateBanner() {
  systemnApi.updateBanner(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}
function addBanner() {
  systemnApi.addBanner(form.value).then(() => {
    dialogVisible.value = false;
  });
}
function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    banner_id: 0,
    banner_type: "",
    banner_canal_type: "",
    banner_name: "",
    banner_sort: 0,
    banner_status: 1,
    banner_url: "",
    banner_remarks: "",
  };
  emit("reacquire");
}
defineExpose({
  init,
});
</script>
