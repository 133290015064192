<template>
  <!-- 对话框 -->
  <el-dialog v-model="dialogVisible" :title="title" width="500px" @close="dialogClosed">
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="100px">
      <el-form-item label="会员名称：" prop="promotion_member_name">
        <el-input disabled v-model="form.promotion_member_name"></el-input>
      </el-form-item>
      <el-form-item label="联系方式：" prop="promotion_member_phone">
        <el-input disabled v-model="form.promotion_member_phone"></el-input>
      </el-form-item>
      <el-form-item label="备注：" prop="promotion_remarks">
        <el-input disabled type="textarea" :rows="4" v-model="form.promotion_remarks" placeholder="请输入"></el-input>
      </el-form-item> 
      <el-form-item label="咨询状态：" prop="promotion_status">
        <el-radio v-model="form.promotion_status" :label="0" size="large">未处理</el-radio>
        <el-radio v-model="form.promotion_status" :label="1" size="large">已处理</el-radio>
      </el-form-item>
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import memberApi from "@/api/memberApi.js";
import { ref, reactive, defineEmits, defineExpose, computed } from "vue";
import { ElMessage } from "element-plus";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let title = computed(() => {
  if (form.value.promotion_id == 0) {
    return "会员背景提升添加";
  } else {
    return "会员背景提升修改";
  }
});
let formRef = ref(null);
let form = ref({
  promotion_id: 0,
  promotion_remarks: "",
  promotion_member_id: "",
  promotion_member_name: "",
  promotion_member_phone: "",
  promotion_expect: "",
  promotion_status: 0,
});
const formRules = reactive({

});

function init(info = null) {
  if (info) {
    form.value = info;
  }
  dialogVisible.value = true;
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      if (form.value.promotion_id && form.value.promotion_id != 0) {
        updatePromotion();
      } else {
        addPromotion();
      }
    } else {
      return false;
    }
  });
}
function updatePromotion() {
  memberApi.updatePromotion(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}
function addPromotion() {
  memberApi.addPromotion(form.value).then(() => {
    dialogVisible.value = false;
  });
}
function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    promotion_id: 0,
    promotion_remarks: "",
    promotion_member_id: "",
    promotion_member_name: "",
    promotion_member_phone: "",
    promotion_expect: "",
    promotion_status: 0,
  };
  emit("reacquire");
}
defineExpose({
  init,
});
</script>
