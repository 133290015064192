<template>
  <!-- 对话框 -->
  <el-dialog v-model="dialogVisible" :title="title" width="500px" @close="dialogClosed">
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="100px">
      <el-form-item label="会员名称：" prop="apply_member_name">
        <el-input disabled v-model="form.apply_member_name"></el-input>
      </el-form-item>
      <el-form-item label="联系方式：" prop="apply_member_phone">
        <el-input disabled v-model="form.apply_member_phone"></el-input>
      </el-form-item>
      <el-form-item label="目标专业：" prop="apply_application_professional">
        <el-input disabled v-model="form.apply_application_professional"></el-input>
      </el-form-item>
      <el-form-item label="具体专业：" prop="apply_application_major_name">
        <el-input disabled v-model="form.apply_application_major_name"></el-input>
      </el-form-item>
      <el-form-item label="咨询状态：" prop="apply_status">
        <el-select
            class="w-100"
            v-model="form.apply_status"
            placeholder="Select"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
      </el-form-item>
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import memberApi from "@/api/memberApi.js";
import { ref, reactive, defineEmits, defineExpose, computed } from "vue";
import { ElMessage } from "element-plus";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let title = computed(() => {
  if (form.value.apply_id == 0) {
    return "会员申请添加";
  } else {
    return "会员申请修改";
  }
});

let options = [
  {
    value: 1,
    label: '信息咨询'
  },
  {
    value: 2,
    label: '选择学校专业'
  },
  {
    value: 3,
    label: '资料准备'
  },
  {
    value: 4,
    label: '递交资料'
  },
  {
    value: 5,
    label: '待校方审核'
  },
  {
    value: 6,
    label: '已出结果'
  }
];
let formRef = ref(null);
let form = ref({
  apply_id: 0,
  apply_member_id: "",
  apply_member_name: "",
  apply_application_id: "",
  apply_application_name: "",
  apply_application_professional: "",
  apply_application_major_name: "",
  apply_status: 1,
  apply_member_phone: "",
});
const formRules = reactive({

});

function init(info = null) {
  if (info) {
    form.value = info;
  }
  dialogVisible.value = true;
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      if (form.value.apply_id && form.value.apply_id != 0) {
        updateApply();
      } else {
        addApply();
      }
    } else {
      return false;
    }
  });
}
function updateApply() {
  memberApi.updateApply(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}
function addApply() {
  memberApi.addApply(form.value).then(() => {
    dialogVisible.value = false;
  });
}
function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    apply_id: 0,
    apply_member_id: "",
    apply_member_name: "",
    apply_application_id: "",
    apply_application_name: "",
    apply_application_professional: "",
    apply_application_major_name: "",
    apply_status: 1,
    apply_member_phone: "",
  };
  emit("reacquire");
}
defineExpose({
  init,
});
</script>
