const type_options = [
    {
        value: 0,
        label: "小程序/H5",
    },
    {
        value: 1,
        label: "PC官网",
    }
];

const app_options = [
    {
        value: 0,
        label: "首页查询页面",
    },
    {
        value: 1,
        label: "申请页面",
    },
    {
        value: 2,
        label: "excel表格订购页面",
    },
    {
        value: 3,
        label: "研究生留学页面",
    },
    {
        value: 4,
        label: "大学生or预科留学页面",
    },
    {
        value: 5,
        label: "高中生留学页面",
    },
    {
        value: 6,
        label: "个人信息页面",
    },
    {
        value: 7,
        label: "免费咨询页面",
    },
    {
        value: 8,
        label: "背景提升页面",
    },
    {
        value: 9,
        label: "详情页面",
    },
    {
        value: 10,
        label: "关注专业页面",
    }
];

const pc_options = [
    {
        value: 100,
        label: "首页banner主标题",
    },
    {
        value: 101,
        label: "首页banner副标题",
    },
    {
        value: 102,
        label: "首页banner高中申请描述",
    },
    {
        value: 103,
        label: "首页banner本科申请描述",
    },
    {
        value: 104,
        label: "首页banner研究生申请描述",
    },
    {
        value: 105,
        label: "首页关于我们描述",
    },
    {
        value: 106,
        label: "首页院校查询描述",
    },
    {
        value: 107,
        label: "首页智能择校描述",
    },
    {
        value: 108,
        label: "首页会员服务描述",
    },
    {
        value: 109,
        label: "地址文案",
    },
    {
        value: 110,
        label: "电话文案",
    },
    {
        value: 111,
        label: "邮箱文案",
    },
    {
        value: 112,
        label: "logo文案",
    },
];

const app_banner_options = [
    {
        value: 'home',
        label: "首页",
    },
    {
        value: 'query',
        label: "院校查询",
    },
    {
        value: 'select',
        label: "智能择校",
    },
];

const pc_banner_options = [
    {
        value: 'pcHomeBanner',
        label: "首页顶部banner",
    },
    {
        value: 'pcHomeAbout',
        label: "首页关于我们图片",
    },
    {
        value: 'pcHomeQuery',
        label: "首页院校查询logo图片",
    },
    {
        value: 'pcHomeSelect',
        label: "首页智能择校图片",
    },
    {
        value: 'pcHomeMember',
        label: "首页会员服务图片",
    },
    {
        value: 'applet',
        label: "小程序图片",
    },
    {
        value: 'officialAccount',
        label: "公众号图片",
    },
    {
        value: 'applyBanner',
        label: "申请专业banner图片",
    },
    {
        value: 'guidanceBanner',
        label: "人工咨询banner图片",
    },
    {
        value: 'promoteBanner',
        label: "背景提升banner图片",
    },
    {
        value: 'contactBanner',
        label: "联系我们banner图片",
    },
    {
        value: 'logoImg',
        label: "logo图片",
    },
];


export {
    type_options,
    app_options,
    pc_options,
    app_banner_options,
    pc_banner_options
}