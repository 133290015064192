<template>
  <div class="w-100 h-100">
    <div class="
        w-100
        border-box
        flex
        justify-start
        align-center
        card-shadow
        bg-white
        rounded
        px-1
        overflow-x
      " style="height: 50px">
      <div class="flex justify-start align-center">
        <!-- 用户昵称 -->
        <div class="flex justify-start align-center">
          <div class="mr-1">会员名称:</div>
          <el-input style="width: 110px" v-model="queryInfo.member_name" placeholder="请输入"></el-input>
        </div>
        <!-- 手机 -->
        <div class="flex justify-start align-center mx-1">
          <div class="mr-1">联系方式:</div>
          <el-input style="width: 110px" v-model="queryInfo.member_phone" placeholder="请输入"></el-input>
        </div>
        <!-- 按钮 -->
        <el-button type="primary" @click="query">
          <el-icon :size="15">
            <component :is="Search"></component>
          </el-icon>搜索
        </el-button>
        <el-button type="primary" @click="reset">重置</el-button>
      </div>
    </div>
    <div class="w-100 mt-1 border-box card-shadow bg-white rounded p-1" style="height: calc(100% - 70px)">
      <!-- 表格 -->
      <div class="w-100" style="height: calc(100% - 60px)">
        <el-table :data="list" class="w-100" height="100%">
          <el-table-column label="序号" align="center" min-width="100">
            <template #default="scope">
              {{scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column v-for="(field, index) in fieldList" :key="index" :prop="field.prop" :label="field.label"
            :width="field.width || ''" align="center">
            <template v-if="field.children && field.children.length > 0" #default>
              <el-table-column v-for="(field2, index) in field.children" :key="index" :prop="field2.prop"
                :label="field2.label" :width="field2.width || ''" align="center">
                <template #default="scope">
                  <div v-if="field2.prop == 'member_IELTS'">
                    {{ getLabel('memberIELTSLList', scope.row.member_IELTS) }}
                  </div>
                  <div v-else-if="field2.prop == 'member_TOEFL'">
                    {{ getLabel('memberTOEFLList', scope.row.member_TOEFL) }}
                  </div>
                  <div v-else-if="field2.prop == 'member_GRE'">
                    {{ getLabel('memberGREList', scope.row.member_GRE) }}
                  </div>
                  <div v-else-if="field2.prop == 'member_GMAT'">
                    {{ getLabel('memberGMATList', scope.row.member_GMAT) }}
                  </div>
                  <span v-else>{{ scope.row[field.prop] }}</span>
                </template>
              </el-table-column>

            </template>
            <template v-else #default="scope">
              <!-- 操作 -->
              <div v-if="field.prop == 'operation'">
                <!-- 删除按钮 -->
                <el-button :disabled="!showDelete" type="danger" size="small" @click="del(scope.row)">删除
                </el-button>
              </div>
              <div v-else-if="field.prop == 'member_achievement'">
                {{ getLabel('memberGPAList', scope.row.member_achievement) }}
              </div>
              <!-- 采购记录 -->
              <div v-else-if="field.prop == 'purchase_record'">
                <el-button @click="seeDetails(scope.row)" type="primary" size="small">查看详情
                </el-button>
              </div>
              <div v-else-if="field.prop == 'college_application_record'">
                <el-button @click="seeDetails2(scope.row)" type="primary" size="small">查看详情
                </el-button>
              </div>
              <div v-else-if="field.prop == 'consultation_records'">
                <el-button @click="seeDetails3(scope.row)" type="primary" size="small">查看详情
                </el-button>
              </div>
              <div v-else-if="field.prop == 'member_promotion'">
                <el-button @click="seeDetails4(scope.row)" type="primary" size="small">查看详情
                </el-button>
              </div>
              <span v-else>{{ scope.row[field.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="flex justify-center align-center" style="height: 50px">
        <el-pagination :page-sizes="[10, 50, 100, 200]" background layout="total, sizes, prev, pager, next"
          :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
    <purchaseTable ref="purchaseTables" @reacquire="reacquire"></purchaseTable>
    <applyTable ref="applyTables" @reacquire="reacquire"></applyTable>
    <consultDetails ref="consultDetailss" @reacquire="reacquire"></consultDetails>
    <promotionDetails ref="promotionDetailss" @reacquire="reacquire"></promotionDetails>
  </div>
</template>

<script setup>
import memberApi from "@/api/memberApi.js";
import purchaseTable from "./purchaseTable/purchaseTable.vue";
import applyTable from "./applyTable/applyTable.vue";
import consultDetails from "./consultDetails/consultDetails.vue";
import promotionDetails from "./promotionDetails/promotionDetails.vue";
import { ref, reactive, onMounted, computed } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { Search } from "@element-plus/icons-vue";
import { useStore } from "vuex";
const store = useStore();
let purchaseTables = ref(null);
let applyTables = ref(null);
let consultDetailss = ref(null);
let promotionDetailss = ref(null);
let queryInfo = reactive({
  member_name: "",
  member_phone: "",
  pageNum: 1,
  pageSize: 10,
});

const fieldList = [
  // {
  //   id: 1,
  //   prop: "member_id",
  //   label: "序号",
  // },
  {
    id: 2,
    prop: "member_open_id",
    label: "会员编号",
    width: 300
  },
  {
    id: 3,
    prop: "member_nikename",
    label: "微信昵称",
    width: 200
  },
  {
    id: 4,
    prop: "member_name",
    label: "姓名",
  },
  {
    id: 5,
    prop: "member_phone",
    label: "联系方式",
    width: 140
  },
  {
    id: 6,
    prop: "member_education",
    label: "最高学历/目前在读",
    width: 160
  },
  {
    id: 7,
    prop: "member_school",
    label: "最高学历学校",
    width: 140
  },
  {
    id: 8,
    prop: "member_major",
    label: "所学专业",
    width: 140
  },
  {
    id: 9,
    prop: "member_achievement",
    label: "最高学历平均成绩",
    width: 140
  },
  {
    id: 11,
    prop: "",
    label: "外语沟通能力",
    children: [
      {
        id: 1101,
        prop: "member_IELTS",
        label: "雅思",
      },
      {
        id: 1102,
        prop: "member_TOEFL",
        label: "托福",
      },
    ],
  },
  {
    id: 12,
    prop: "",
    label: "外语学术能力",
    children: [
      {
        id: 1201,
        prop: "member_GRE",
        label: "GRE",
      },
      {
        id: 1202,
        prop: "member_GMAT",
        label: "GMAT总分",
        width: 140
      },
    ],
  },
  {
    id: 14,
    prop: "member_study_address",
    label: "拟留学目的地",
    width: 140
  },
  {
    id: 15,
    prop: "member_target_specialty",
    label: "目标专业方向",
    width: 400
  },
  {
    id: 16,
    prop: "purchase_record",
    label: "采购记录",
    width: 140
  },
  {
    id: 17,
    prop: "college_application_record",
    label: "院校申请记录",
    width: 140
  },
  {
    id: 18,
    prop: "consultation_records",
    label: "咨询记录",
    width: 140
  },
  {
    id: 19,
    prop: "member_promotion",
    label: "会员提升",
    width: 140
  },
  {
    id: 10,
    prop: "operation",
    label: "操作",
    width: 180,
  },
];

const optionList = {
  memberGPAList: [{
    value: 1,
    label: '>=90'
  },
  {
    value: 2,
    label: '85~90'
  },
  {
    value: 3,
    label: '80~84'
  },
  {
    value: 4,
    label: '75~79'
  },
  {
    value: 5,
    label: '<=74'
  }
  ],
  memberTOEFLList: [{
    value: 1,
    label: '>=98'
  },
  {
    value: 2,
    label: '78~97'
  },
  {
    value: 3,
    label: '<=77'
  },
  {
    value: 4,
    label: '不清楚'
  }
  ],
  memberIELTSLList: [{
    value: 1,
    label: '>=7'
  },
  {
    value: 2,
    label: '6.5'
  },
  {
    value: 3,
    label: '<=6'
  },
  {
    value: 4,
    label: '不清楚'
  }
  ],
  memberGREList: [{
    value: 1,
    label: '>=330'
  },
  {
    value: 2,
    label: '300~329'
  },
  {
    value: 3,
    label: '<300'
  },
  {
    value: 4,
    label: '不清楚'
  }
  ],
  memberGMATList: [{
    value: 1,
    label: '>=710'
  },
  {
    value: 2,
    label: '650~709'
  },
  {
    value: 3,
    label: '600~649'
  },
  {
    value: 4,
    label: '550~599'
  },
  {
    value: 5,
    label: '<=549'
  },
  {
    value: 6,
    label: '不清楚'
  }
  ]
};



let total = ref(0);
let list = ref([]);

let showDelete = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("20104")) {
    return true;
  } else {
    return false;
  }
});

function getLabel(list, value) {
  const item = optionList[list].find(item => item.value == value);
  if(item && item.label) {
   return item.label;
  }else {
    return "未填写"
  }
}

function seeDetails(info) {
  purchaseTables.value.init(info)
}

function seeDetails2(info) {
  applyTables.value.init(info)
}

function seeDetails3(info) {
  consultDetailss.value.init(info)
}
function seeDetails4(info) {
  promotionDetailss.value.init(info)
}


function query() {
  queryInfo.pageNum = 1;
  getMemberList();
}

function reset() {
  queryInfo.member_name = "";
  queryInfo.member_phone = "";
  queryInfo.pageNum = 1;
  queryInfo.pageSize = 10;
  getMemberList();
}

function getMemberList() {
  memberApi.getMemberList(queryInfo).then((res) => {
    list.value = res.data.list;
    total.value = res.data.total;
  });
}

function del(info) {
  ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let data = {
        member_id: info.member_id,
      };
      memberApi.deleteMember(data).then((res) => {
        console.log(res);
        ElMessage.success("删除成功");
        reacquire();
      });
    })
    .catch(() => {
      // catch error
    });
}

function handleSizeChange(number) {
  queryInfo.pageSize = number;
  getMemberList();
}

function handleCurrentChange(newPage) {
  queryInfo.pageNum = newPage;
  getMemberList();
}

function reacquire() {
  queryInfo.pageNum = 1;
  getMemberList();
}

onMounted(() => {
  // 获取列表
  getMemberList();
});
</script>

