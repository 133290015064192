<template>
  <!-- 对话框 -->
  <el-dialog
    v-model="dialogVisible"
    title="价格维护"
    width="520px"
    @close="dialogClosed"
  >
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="120px">
      <el-form-item label="修改价格：" prop="excel_price">
        <el-input
          type="number"
          v-model="form.excel_price"
          placeholder="请输入金额"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import memberApi from "@/api/memberApi.js";
import systemnApi from "@/api/systemnApi.js";
import { ref, reactive, defineEmits, defineExpose } from "vue";
import { ElMessage } from "element-plus";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);

let formRef = ref(null);
let form = ref({
  excel_price: 59,
});
const formRules = reactive({
  excel_price: [{ required: true, message: "请输入金额", trigger: "blur" }],
});

function init(key = null) {
  if (key) {
    getConfigureList(key);
  }
  dialogVisible.value = true;
}

function getConfigureList(key) {
  systemnApi.getConfigureList({ configure_key: key }).then((res) => {
    console.log(res);
    form.value.excel_price = res.data.list.find((item) => {
      return item.configure_key == "excel_price";
    }).configure_value;
  });
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      updateExcelConfig();
    } else {
      return false;
    }
  });
}
function updateExcelConfig() {
  memberApi.updateExcelConfig(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}

function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    banner_autoplay: 1,
    banner_interval: 3000,
  };
  emit("reacquire");
}
defineExpose({
  init,
});
</script>
