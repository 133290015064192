<template>
  <!-- 对话框 -->
  <el-dialog
    v-model="dialogVisible"
    :title="title"
    width="700px"
    @close="dialogClosed"
  >
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="100px">
      <el-form-item label="公告标题：" prop="notice_title">
        <el-input
          type="textarea"
          :rows="3"
          v-model="form.notice_title"
          placeholder="请输入公告标题"
        ></el-input>
      </el-form-item>
      <el-form-item label="公告类型：" prop="notice_type">
        <el-select
          class="w-100"
          v-model="form.notice_type"
          placeholder="请选择"
        >
          <el-option
            v-for="item in type_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="公告途径：" prop="notice_channel">
        <el-select
          class="w-100"
          v-model="form.notice_channel"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="详情描述：" prop="notice_content">
        <el-input
          type="textarea"
          :rows="6"
          v-model="form.notice_content"
          placeholder="请输入详情描述"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { type_options, app_options, pc_options } from "@/utils/options.js";
import systemnApi from "@/api/systemnApi.js";
import {
  ref,
  reactive,
  defineEmits,
  defineProps,
  defineExpose,
  computed,
} from "vue";
import { ElMessage } from "element-plus";
const props = defineProps({
  list: Array,
});

let options = computed(() => {
  if(form.value.notice_type == 1) {
    return pc_options;
  } else if(form.value.notice_type === 0) {
    return app_options;
  }
  return []
});

const list = ref([]);
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let title = computed(() => {
  if (form.value.notice_id == 0) {
    return "公告添加";
  } else {
    return "公告修改";
  }
});
let formRef = ref(null);
let form = ref({
  notice_id: 0,
  notice_title: "",
  notice_channel: "",
  notice_status: 1,
  notice_type: "",
  notice_content: "",
});
const formRules = reactive({
  notice_title: [
    { required: true, message: "请输入公告标题", trigger: "blur" },
  ],
  notice_type: [
    { required: true, message: "请选择公告类型", trigger: "change" },
  ],
  notice_channel: [
    { required: true, message: "请选择公告途径", trigger: "change" },
  ],
  notice_content: [
    { required: true, message: "请输入公告详情", trigger: "blur" },
  ],
});

function init(info = null) {
  if (info) {
    form.value = info;
  }
  list.value = JSON.parse(JSON.stringify(props.list))
  dialogVisible.value = true;
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      if (form.value.notice_id && form.value.notice_id != 0) {
        // 判断当前选中途径是否有公告
        const index = list.value.findIndex(item => {
          return item.notice_channel == form.value.notice_channel && item.notice_id != form.value.notice_id && item.notice_type == form.value.notice_type
        })
        if(index > -1) {
          ElMessage.error("当前选中途径已有其他公告");
          return false;
        }
        updateNotice();
      } else {
        // 判断当前选中途径是否有公告
        const index = list.value.findIndex(item => {
          return item.notice_channel == form.value.notice_channel
        })
        if(index > -1) {
          ElMessage.error("当前选中途径已有公告");
          return false;
        }
        addNotice();
      }
    } else {
      return false;
    }
  });
}
function updateNotice() {
  systemnApi.updateNotice(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}
function addNotice() {
  systemnApi.addNotice(form.value).then(() => {
    dialogVisible.value = false;
  });
}
function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    notice_id: 0,
    notice_title: "",
    notice_channel: "",
    notice_status: 1,
    notice_type: "",
    notice_content: "",
  };
  emit("reacquire");
}
defineExpose({
  init,
});
</script>
