import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo: {},
  },
  mutations: {
    // 赋值state
    SET_VALUE(state, data) {
      let {
        key,
        value
      } = data;
      state[key] = value;
    },
  },
  actions: {
  },
  getters: {
    role_menu_ids({ userInfo }) {
      if (userInfo.role_menu_ids) {
        return userInfo.role_menu_ids.split(',')
      } else {
        return []
      }
    }
  },
  modules: {
  }
})
