<template>
  <!-- 对话框 -->
  <el-dialog
    v-model="dialogVisible"
    title="导入院校申请信息"
    width="1200px"
    @close="dialogClosed"
    top="5vh"
  >
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-table v-loading="loading" :data="list" class="w-100" style="width: 100%" height="600">
      <el-table-column
        v-for="(field, index) in fieldList"
        :key="index"
        :prop="field.prop"
        :label="field.label"
        :width="field.width || ''"
        align="center"
      >
        <template #default="scope">
          {{ scope.row[field.prop] }}
        </template>
      </el-table-column>
    </el-table>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import systemnApi from "@/api/systemnApi.js";
import { ref, defineEmits, defineExpose, defineProps } from "vue";
import { ElMessage, ElLoading } from "element-plus";
const emit = defineEmits(["reacquire", "setLoading"]);
let dialogVisible = ref(false);
const props = defineProps({
  fieldList: Array,
});
let fieldList = props.fieldList;
let list = ref([]);

function init(info = null) {
  if (info) {
    const listBase = info.slice(1);
    jsonToTable(listBase);
  }
  dialogVisible.value = true;
}

// 将json转换成表格
function jsonToTable(listBase) {
  console.log("listBase", listBase);
  listBase.forEach((row) => {
    let item = {
      application_ranking: row.application_ranking || "",
      application_high_attribute: row.application_high_attribute || "",
      application_name: row.application_name || "",
      application_en_name: row.application_en_name || "",
      application_attribute: row.application_attribute || "",
      application_country: row.application_country || "",
      application_province: row.application_province || "",
      application_city: row.application_city || "",
      application_professional: row.application_professional || "",
      application_class: row.application_class || "",
      application_major_name: row.application_major_name || "",
      application_major_en_name: row.application_major_en_name || "",
      application_faculty: row.application_faculty || "",
      application_education: row.application_education || "",
      application_en_education: row.application_en_education || "",
      application_introduce: row.application_introduce || "",
      application_course: row.application_course || "",
      application_tuition: row.application_tuition || "",
      application_cost: row.application_cost || "",
      application_start_date: row.application_start_date || "",
      application_end_date: row.application_end_date || "",
      application_GRA: row.application_GRA || "",
      application_TOEFL: row.application_TOEFL || "",
      application_IELTS: row.application_IELTS || "",
      application_GRE: row.application_GRE || "",
      application_GMAT: row.application_GMAT || "",
      application_SAT: row.application_SAT || "",
      application_ACT: row.application_ACT || "",
      application_academic_background: row.application_academic_background || "",
      application_material_requirements: row.application_material_requirements || "",
      application_enrollment_website: row.application_enrollment_website || "",
      application_professional_website: row.application_professional_website || "",
    };

    list.value.push(item)
  });
  emit("setLoading");
}
// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  const loading = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  systemnApi
    .addApplicationList({ list: list.value })
    .then((res) => {
      console.log(res);
      loading.close()
      dialogVisible.value = false;
    })
    .catch((err) => {
      ElMessage.error("到入失败，请检查数据格式");
      console.log(err);
    });
}

function dialogClosed() {
  fieldList.value = [];
  list.value = [];
  emit("reacquire");
}
defineExpose({
  init,
});
</script>

<style>
.canvasTable {
  border-collapse: collapse;
}

.canvasTable th,
.canvasTable td {
  border: solid 1px #6d6d6d;
  padding: 5px 10px;
}
</style>
