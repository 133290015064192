<template>
  <!-- 对话框 -->
  <el-dialog v-model="dialogVisible" title="banner配置修改" width="520px" @close="dialogClosed">
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="120px">
    <el-form-item label="自动切换：" prop="banner_autoplay">
        <el-switch v-model="form.banner_autoplay" active-color="#13ce66" inactive-color="#ff4949" :active-value="1"
          :inactive-value="0" />
      </el-form-item>
      <el-form-item label="切换时长：" prop="banner_interval">
        <el-input type="number" v-model="form.banner_interval" placeholder="请输入切换时长"></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import systemnApi from "@/api/systemnApi.js";
import { ref, reactive, defineEmits, defineExpose } from "vue";
import { ElMessage } from "element-plus";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);

let formRef = ref(null);
let form = ref({
  banner_autoplay: 1,
  banner_interval: 3000
});
const formRules = reactive({
  banner_autoplay: [{ required: true, message: "请选择是否自动切换", trigger: "change" }],
  banner_interval: [{ required: true, message: "请输入切换时长", trigger: "blur" }],
});

function init(key = null) {
  if (key) {
    getConfigureList(key)
  }
  dialogVisible.value = true;
}

function getConfigureList(key) {
  systemnApi.getConfigureList({configure_key :key}).then(res => {
    console.log(res)
    form.value.banner_autoplay = res.data.list.find(item => {
      return item.configure_key == 'banner_autoplay'
    }).configure_value;
    form.value.banner_interval = res.data.list.find(item => {
      return item.configure_key == 'banner_interval'
    }).configure_value;
  })
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      updateBannerConfig();
    } else {
      return false;
    }
  });
}
function updateBannerConfig() {
  systemnApi.updateBannerConfig(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}

function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    banner_autoplay: 1,
    banner_interval: 3000
  };
  emit("reacquire");
}
defineExpose({
  init,
});
</script>
