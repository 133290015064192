<template>
  <div class="w-100 h-100">
    <div class="
          w-100
          border-box
          flex
          justify-start
          align-center
          card-shadow
          bg-white
          rounded
          px-1
          overflow-x
        " style="height: 50px">
      <div class="flex justify-start align-center">
        <!-- 用户昵称 -->
        <div class="flex justify-start align-center">
          <div class="mr-1">用户名称:</div>
          <el-input
            style="width: 110px"
            v-model="queryInfo.member_name"
            placeholder="请输入"
          ></el-input>
        </div>
        <!-- 联系方式 -->
        <div class="flex justify-start align-center">
          <div class="mx-1">联系方式:</div>
          <el-input
            style="width: 110px"
            v-model="queryInfo.member_phone"
            placeholder="请输入"
          ></el-input>
        </div>
        <!-- 类目 -->
        <div class="flex justify-start align-center">
          <div class="mx-1">类目:</div>
          <el-input
            style="width: 110px"
            v-model="queryInfo.masterplate_type"
            placeholder="请输入"
          ></el-input>
        </div>
        <!-- 创建时间 -->
        <div class="mx-1 flex justify-start align-center">
          <div class="mr-1">创建时间:</div>
          <el-date-picker style="width: 220px" v-model="creationTime" type="daterange" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" placeholder="不可选" @change="setTime"></el-date-picker>
        </div>
        <!-- 按钮 -->
        <el-button type="primary" @click="query">
          <el-icon :size="15">
            <component :is="Search"></component>
          </el-icon>搜索
        </el-button>
        <el-button type="primary" @click="reset">重置</el-button>
        <!-- <el-button v-if="showAdd" @click="add" type="success">
          <el-icon :size="15">
            <component :is="Plus"></component>
          </el-icon>新增
        </el-button> -->
      </div>
    </div>
    <div class="w-100 mt-1 border-box card-shadow bg-white rounded p-1" style="height: calc(100% - 70px)">
      <!-- 表格 -->
      <div class="w-100" style="height: calc(100% - 60px)">
        <el-table :data="list" class="w-100" height="100%">
          <el-table-column label="序号" align="center" min-width="100">
            <template #default="scope">
              {{scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column v-for="(field, index) in fieldList" :key="index" :prop="field.prop" :label="field.label"
            :width="field.width || ''" align="center">
            <template #default="scope">
              <!-- 操作 -->
              <div v-if="field.prop == 'operation'" class="flex justify-start align-center">
                <div class="mr-1">
                  <el-link target="_blank" :href="scope.row.masterplate_file">
                  <el-button type="primary" size="small">查看</el-button>
                </el-link>
                </div>
                <!-- 删除按钮 -->
                <el-button :disabled="!showDelete" type="danger" size="small" @click="del(scope.row)">删除
                </el-button>
              </div>
              <!-- 昵称 -->
              <div v-else-if="field.prop == 'member_nikename'">
                {{ scope.row.member_nikename || scope.row.member_name || scope.row.member_phone}}
              </div>
              <!-- 时间 -->
              <div v-else-if="field.prop == 'subscribe_creation_time'">
                {{ getFormatTime(scope.row.subscribe_creation_time) }}
              </div>
              <span v-else>{{ scope.row[field.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="flex justify-center align-center" style="height: 50px">
        <el-pagination :page-sizes="[10, 50, 100, 200]" background layout="total, sizes, prev, pager, next" :total="total"
          @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
    <addMasterplate ref="addMasterplates" @reacquire="reacquire"></addMasterplate>
  </div>
</template>

<script setup>
import systemnApi from "@/api/systemnApi.js";
import date from "@/utils/date.js";
import addMasterplate from "./addMasterplate/addMasterplate.vue";
import { ref, reactive, onMounted, computed } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { Search, Plus } from "@element-plus/icons-vue";
import { useStore } from "vuex";
const store = useStore();
let addMasterplates = ref(null);

let options = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 0,
    label: "停用",
  },
  {
    value: 1,
    label: "启用",
  },
];


let queryInfo = reactive({
  startTime: "",
  endTime: "",
  member_name: "",
  member_phone: "",
  masterplate_type: "",
  pageNum: 1,
  pageSize: 10,
});
let creationTime = ref([]);
const fieldList = [
  // {
  //   id: 1,
  //   prop: "subscribe_id",
  //   label: "序号",
  // },
  {
    id: 2,
    prop: "member_nikename",
    label: "用户名称",
  },
  {
    id: 3,
    prop: "member_phone",
    label: "联系方式",
  },
  {
    id: 4,
    prop: "masterplate_type",
    label: "类目",
  },
  {
    id: 5,
    prop: "subscribe_name",
    label: "文档名称",
  },
  {
    id: 10,
    prop: "operation",
    label: "操作",
    width: 180,
  },
];
let total = ref(0);
let list = ref([]);

let showAdd = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("80202")) {
    return true;
  } else {
    return false;
  }
});

let showEdit = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("80203")) {
    return true;
  } else {
    return false;
  }
});

let showDelete = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("80204")) {
    return true;
  } else {
    return false;
  }
});

function getFormatTime(value) {
  return date.formatDate(value * 1000);
}

function setTime(val) {
  console.log(val);
  if (val) {
    queryInfo.startTime = Number(val[0] / 1000);
    queryInfo.endTime = Number(val[1] / 1000);
  } else {
    queryInfo.startTime = "";
    queryInfo.endTime = "";
  }
}


function query() {
  queryInfo.pageNum = 1;
  getSubscribeList();
}

function reset() {
  queryInfo.startTime = "";
  queryInfo.endTime = "";
  queryInfo.member_name= "";
  queryInfo.member_phone= "";
  queryInfo. masterplate_type= "";
  queryInfo.pageNum = 1;
  queryInfo.pageSize = 10;
  creationTime.value = [];
  getSubscribeList();
}

function getSubscribeList() {
  systemnApi.getSubscribeList(queryInfo).then((res) => {
    list.value = res.data.list;
    total.value = res.data.total;
  });
}


function add() {
  addMasterplates.value.init(null);
}

function edit(info) {
  addMasterplates.value.init(info);
}



function del(info) {
  ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let data = {
        subscribe_id: info.subscribe_id
      }
      systemnApi.deleteSubscribe(data).then((res) => {
        console.log(res);
        ElMessage.success("删除成功");
        reacquire();
      });
    })
    .catch(() => {
      // catch error
    });
}



function handleSizeChange(number) {
  queryInfo.pageSize = number;
  getSubscribeList();
}

function handleCurrentChange(newPage) {
  queryInfo.pageNum = newPage;
  getSubscribeList();
}

function reacquire() {
  queryInfo.pageNum = 1;
  getSubscribeList();
}

onMounted(() => {
  // 获取列表
  getSubscribeList();
});
</script>

