<template>
  <!-- 对话框 -->
  <el-dialog v-model="dialogVisible" :title="title" width="520px" @close="dialogClosed">
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="120px">
      <el-form-item label="类目：" prop="masterplate_type">
        <el-input v-model="form.masterplate_type" placeholder="请输入类目"></el-input>
      </el-form-item>
      <el-form-item label="文档名称：" prop="masterplate_name">
        <el-input v-model="form.masterplate_name" placeholder="请输入文档名称"></el-input>
      </el-form-item>
      <el-form-item label="状态：" prop="masterplate_status">
        <el-switch v-model="form.masterplate_status" active-color="#13ce66" inactive-color="#ff4949" :active-value="1"
          :inactive-value="0" />
      </el-form-item>
      <el-form-item label="上传文件：" prop="masterplate_file">
        <upload ref="myUpload" uploadKey="masterplate_file" @success="getImg">
          <div class="overflow-hidden rounded border flex justify-center align-center" style="width: 100px;height: 40px">
            <el-icon :size="20">
              <Plus />
            </el-icon>
          </div>
        </upload>
      </el-form-item>
      <el-form-item v-if="form.masterplate_file" label="预览文件：" prop="masterplate_file">
        <el-link target="_blank"  :href="form.masterplate_file" type='success'>下载文件</el-link>
      </el-form-item>
      <!-- <el-form-item label="备注：" prop="banner_remarks">
        <el-input type="textarea" :rows="4" v-model="form.banner_remarks" placeholder="请输入"></el-input>
      </el-form-item> -->
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import systemnApi from "@/api/systemnApi.js";
import upload from "@/components/upload/upload.vue";
import { ref, reactive, defineEmits, defineExpose, computed } from "vue";
import { ElMessage } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let myUpload = ref(null)
let title = computed(() => {
  if (form.value.masterplate_id == 0) {
    return "模版添加";
  } else {
    return "模版修改";
  }
});



let sortData = ref({});
let formRef = ref(null);
let form = ref({
  masterplate_id: 0,
  masterplate_name: "",
  masterplate_creation_time: "",
  masterplate_file: "",
  masterplate_status: 1,
  masterplate_type: "",
});
const formRules = reactive({
  masterplate_type: [{ required: true, message: "请输入类目", trigger: "blur" }],
  masterplate_file: [{ required: true, message: "请上传模版文件", trigger: "change" }],
});

function init(info = null) {
  if (info) {
    form.value = info;
  }
  dialogVisible.value = true;
}


function getImg(key, value) {
  form.value[key] = value;
  myUpload.value.clearFiles()
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      if (form.value.masterplate_id && form.value.masterplate_id != 0) {
        updateMasterplate();
      } else {
        addMasterplate();
      }
    } else {
      return false;
    }
  });
}
function updateMasterplate() {
  systemnApi.updateMasterplate(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}

function addMasterplate() {
  systemnApi.addMasterplate(form.value).then(() => {
    dialogVisible.value = false;
  });
}

function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    masterplate_id: 0,
    masterplate_name: "",
    masterplate_creation_time: "",
    masterplate_file: "",
    masterplate_status: 1,
    masterplate_type: "",
  };
  emit("reacquire");
}
defineExpose({
  init,
});
</script>
