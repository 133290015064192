<template>
  <!-- 对话框 -->
  <el-dialog
    v-model="dialogVisible"
    :title="title"
    width="500px"
    @close="dialogClosed"
  >
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="100px">
      <el-form-item label="岗位名称：" prop="post_name">
        <el-input
          v-model="form.post_name"
          placeholder="请输入岗位名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="岗位编码：" prop="post_code">
        <el-input
          v-model="form.post_code"
          placeholder="请输入岗位编码"
        ></el-input>
      </el-form-item>
      <el-form-item label="岗位编号：" prop="post_number">
        <el-input
          type="number"
          v-model="form.post_number"
          placeholder="请输入岗位编号"
        ></el-input>
      </el-form-item>
      <el-form-item label="岗位状态：" prop="post_status">
            <el-switch
              v-model="form.post_status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            />
          </el-form-item>
          <el-form-item label="备注：" prop="post_remarks">
            <el-input
              type="textarea"
              :rows="4"
              v-model="form.post_remarks"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import systemnApi from "@/api/systemnApi.js";
import { ref, reactive, defineEmits, defineExpose, computed } from "vue";
import { ElMessage } from "element-plus";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let title = computed(() => {
  if (form.value.role_id == 0) {
    return "岗位添加";
  } else {
    return "岗位修改";
  }
});
let formRef = ref(null);
let form = ref({
  post_id: 0,
  post_name: "",
  post_code: "",
  post_number: 0,
  post_status: 1,
  post_remarks: "",
});
const formRules = reactive({
  post_name: [{ required: true, message: "请输入岗位姓称", trigger: "blur" }],
  post_code: [{ required: true, message: "请输入岗位编码", trigger: "blur" }],
  post_number: [{ required: true, message: "请输入岗位编号", trigger: "blur" }],
});

function init(info = null, length = null) {
  if (info) {
    form.value = info;
  }
  if(length) {
    form.value.post_number = length;
  }
  dialogVisible.value = true;
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      if (form.value.post_id && form.value.post_id != 0) {
        updatePost();
      } else {
        addPost();
      }
    } else {
      return false;
    }
  });
}
function updatePost() {
  systemnApi.updatePost(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}
function addPost() {
  systemnApi.addPost(form.value).then(() => {
    dialogVisible.value = false;
  });
}
function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    post_id: 0,
    post_name: "",
    post_code: "",
    post_number: 0,
    post_status: 1,
    post_remarks: "",
  };
  emit("reacquire");
}
defineExpose({
  init,
});
</script>
