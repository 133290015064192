<template>
  <div class="w-100 h-100">
    <div class="
        w-100
        border-box
        flex
        justify-start
        align-center
        card-shadow
        bg-white
        rounded
        px-1
        overflow-x
      " style="height: 50px">
      <div class="flex justify-start align-center">
        <!-- 用户昵称 -->
        <div class="flex justify-start align-center">
          <div class="mr-1">会员名称:</div>
          <el-input
            style="width: 110px"
            v-model="queryInfo.promotion_member_name"
            placeholder="请输入"
          ></el-input>
        </div>
        <!-- 手机 -->
        <div class="flex justify-start align-center mx-1">
          <div class="mr-1">联系方式:</div>
          <el-input
            style="width: 110px"
            v-model="queryInfo.promotion_member_phone"
            placeholder="请输入"
          ></el-input>
        </div>
        <!-- 状态 -->
        <div class="flex justify-start align-center">
          <div class="mx-1">当前状态:</div>
          <el-select
            class="mr-1"
            v-model="queryInfo.promotion_status"
            style="width: 110px"
            placeholder="Select"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <!-- 按钮 -->
        <el-button type="primary" @click="query">
          <el-icon :size="15"> <component :is="Search"></component> </el-icon
          >搜索
        </el-button>
        <el-button type="primary" @click="reset">重置</el-button>
        <el-button
          v-if="showDelete && multipleSelection.length > 0"
          @click="daleteAll"
          type="danger"
        >
          批量删除
        </el-button>
      </div>
    </div>
    <div class="w-100 mt-1 border-box card-shadow bg-white rounded p-1" style="height: calc(100% - 70px)">
      <!-- 表格 -->
      <div class="w-100" style="height: calc(100% - 60px)">
        
        <el-table :data="list" class="w-100" height="100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="序号" align="center" min-width="100">
            <template #default="scope">
              {{scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column v-for="(field, index) in fieldList" :key="index" :prop="field.prop" :label="field.label"
            :width="field.width || ''" align="center">
            <template #default="scope">
              <!-- 操作 -->
              <div v-if="field.prop == 'operation'">
                <!-- 修改按钮 -->
                <el-button :disabled="!showEdit" type="primary" size="small" @click="updatePromotion(scope.row)">编辑</el-button>
                <!-- 删除按钮 -->
                <el-button :disabled="!showDelete" type="danger" size="small"
                  @click="del(scope.row)">删除
                </el-button>
              </div>
              <div v-else-if="field.prop == 'promotion_expect'">
               {{getPromotionExpectCont(scope.row.promotion_expect)}}
              </div>
              <div v-else-if="field.prop == 'promotion_status'">
               {{scope.row.promotion_status ? '已处理' : '未处理'}}
              </div>
              <span v-else>{{ scope.row[field.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="flex justify-center align-center" style="height: 50px">
        <el-pagination :page-sizes="[10, 50, 100, 200]" background layout="total, sizes, prev, pager, next"
          :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
    <addPromotion ref="addPromotions" @reacquire="reacquire"></addPromotion>
  </div>
</template>

<script setup>
import memberApi from "@/api/memberApi.js";
import addPromotion from "./addPromotion/addPromotion.vue";
import { ref, reactive, onMounted, computed } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { Search } from "@element-plus/icons-vue";
import { useStore } from "vuex";
const store = useStore();
let addPromotions = ref(null);
let options = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 0,
    label: "未处理",
  },
  {
    value: 1,
    label: "已处理",
  },
];
let queryInfo = reactive({
  promotion_member_name: "",
  promotion_member_phone: "",
  promotion_status: "",
  pageNum: 1,
  pageSize: 10,
});
const fieldList = [
  // {
  //   id: 1,
  //   prop: "promotion_id",
  //   label: "序号",
  // },
  {
    id: 2,
    prop: "promotion_member_name",
    label: "姓名",
  },
  {
    id: 3,
    prop: "promotion_member_phone",
    label: "联系方式",
  },
  {
    id: 4,
    prop: "promotion_expect",
    label: "希望获得",
  },
  {
    id: 5,
    prop: "promotion_status",
    label: "当前状态",
  },
  {
    id: 10,
    prop: "operation",
    label: "操作",
    width: 180,
  },
];
let total = ref(0);
let list = ref([]);

let multipleSelection = ref([]);

// let showAdd = computed(() => {
//   if (store.state.userInfo.role_ascription) {
//     return true;
//   } else if (store.getters.role_menu_ids.indexOf("30402")) {
//     return true;
//   } else {
//     return false;
//   }
// });

let showEdit = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("30403")) {
    return true;
  } else {
    return false;
  }
});

let showDelete = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("30404")) {
    return true;
  } else {
    return false;
  }
});

function getPromotionExpectCont(info) {
  const infoData = JSON.parse(info);
  return `语言培训：${infoData.train ? '希望获得' : '-'}；
          实习机会：${infoData.practice ? '希望获得' : '-'}`
}

function handleSelectionChange(val) {
  console.log(val);
  multipleSelection.value = val;
}

function daleteAll() {
  ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      const deleteids = multipleSelection.value
        .map((item) => {
          return item.promotion_id;
        })
        .join(",");
      delList(deleteids);
    })
    .catch(() => {
      // catch error
    });
}

function delList(ids) {
  memberApi.deletePromotionList({ ids }).then((res) => {
    console.log(res);
    reacquire();
  });
}

function query() {
  queryInfo.pageNum = 1;
  getPromotionList();
}

function reset() {
  queryInfo.promotion_member_name = "";
  queryInfo.promotion_member_phone = "";
  queryInfo.promotion_status = "";
  queryInfo.pageNum = 1;
  queryInfo.pageSize = 10;
  getPromotionList();
}

function getPromotionList() {
  memberApi.getPromotionList(queryInfo).then((res) => {
    list.value = res.data.list;
    total.value = res.data.total;
  });
}

function updatePromotion(info) {
  addPromotions.value.init(JSON.parse(JSON.stringify(info)));
}

function del(info) {
  ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let data = {
        promotion_id: info.promotion_id
      }
      memberApi.deletePromotion(data).then((res) => {
        console.log(res);
        ElMessage.success("删除成功");
        reacquire();
      });
    })
    .catch(() => {
      // catch error
    });
}

function handleSizeChange(number) {
  queryInfo.pageSize = number;
  getPromotionList();
}

function handleCurrentChange(newPage) {
  queryInfo.pageNum = newPage;
  getPromotionList();
}

function reacquire() {
  queryInfo.pageNum = 1;
  getPromotionList();
}

onMounted(() => {
  // 获取列表
  getPromotionList();
});
</script>

