<template>
  <div class="w-100 h-100 flex justify-center align-center bg-light">
    <div class="bg-white p-2 rounded position-relative border" style="width: 375px">
      <h2 class="position-absolute position-x-center" style="top: -50px">佳易留学管理系统</h2>
      <el-form ref="formRef" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="账号">
          <el-input v-model="form.user_account" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            type="password"
            placeholder="请输入密码"
            show-password
            v-model="form.user_password"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码">
          <div class="flex justify-start">
            <el-input class="mr-1" v-model="form.code"></el-input>
            <div class="code" @click="refreshCode">
              <SIdentify ref="sidentifyItem" @getCode="getCode"></SIdentify>
            </div>
          </div>
        </el-form-item>
        <div class="flex justify-center">
          <el-button style="width: 375px" type="primary" @click="login">登陆</el-button>
        </div>
      </el-form>
    </div>
    <div class="fixed-bottom flex align-center  justify-center" style="height: 50px;">
      <div class="font-sm flex align-center  justify-center text-hover-dark">
        <span>ICP备案/许可证号：</span>
        <a class="text-hover-dark" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022062497号</a>
        <span>，主办单位名称：佳易赢信息技术（深圳）有限公司</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import config from "@/utils/config.js"
import loginApi from "@/api/loginApi.js";
import SIdentify from "@/components/SIdentify/SIdentify.vue";
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus'
const store = useStore();
let router = useRouter();
const form = reactive({
  user_account: '',
  user_password: '',
  code: ''
})
const rules = reactive({
  // 验证用户名是否合法
  user_account: [
    { required: true, message: "请输入登陆名称", trigger: "blur" },
    {
      min: 3,
      max: 18,
      message: "长度在 3 到 18 个字符",
      trigger: "blur",
    },
  ],
  // 验证密码是否合法
  user_password: [
    { required: true, message: "请输入登陆密码", trigger: "blur" },
    {
      min: 6,
      max: 18,
      message: "长度在 6 到 18 个字符",
      trigger: "blur",
    },
  ],
  code: [
    { required: true, message: "请输入验证码", trigger: "blur" },
    {
      min: 4,
      max: 4,
      message: "4位字符验证码",
      trigger: "blur",
    },
  ]
})
let sidentifyItem = ref(null)
let formRef = ref(null)
// 刷新后的验证码
let code = ref("")

function login() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      submit()
    } else {
      console.log('验证不通过')
      return false
    }
  })
}
function submit() {
  // 判断验证码是否正确
  if (code != form.code.toLowerCase() && config.isProduction) {
    ElMessage.error('验证码错误')
    return false
  }
  loginApi.login(form).then((res) => {
    const myToken = res.data.token.token;
    const userInfo = JSON.stringify(res.data);
    window.localStorage.setItem("token", myToken);
    window.localStorage.setItem("userInfo", userInfo);
    store.commit('SET_VALUE', { key: "userInfo", value: res.data })
    router.push({
      //传递参数使用query的话，指定path或者name都行，但使用params的话，只能使用name指定 
      path: '/home',
    });
  });
}
function refreshCode() {
  sidentifyItem.value.fourCode()
}

function getCode(value) {
  console.log(value);
  code = value.toLowerCase();
}
onMounted(() => {
  if (!config.isProduction) {
    form.user_account = 'admin';
    form.user_password = '123456';
    form.code = 'aaaa';
  }
})
</script>
