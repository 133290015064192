<template>
<div>
  <!-- 对话框 -->
  <el-dialog
    v-model="dialogVisible"
    title="评分规则"
    width="1100px"
    @close="dialogClosed"
    top="10vh"
  >
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-table :data="list" class="w-100" height="450px" :span-method="handleObjectSpanMethod">
        <el-table-column v-for="(field, index) in fieldList" :key="index" :prop="field.prop" :label="field.label"
          :width="field.width || ''" align="center">
          <template #default="scope">
            <!-- 操作 -->
              <div v-if="field.prop == 'operation'">
                <!-- 修改按钮 -->
                <el-button type="primary" size="small" @click="edit(scope.row)"
                  >编辑</el-button
                >
              </div>
            <span v-else>{{ scope.row[field.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">关 闭</el-button>
      </span>
    </template>
  </el-dialog>
  <setScore ref="setScores" @reacquire="reacquire"></setScore>
  </div>
</template>
<script setup>
import memberApi from "@/api/memberApi.js";
import setScore from "./setScore/setScore.vue"; 
import { ref, reactive, defineEmits, defineExpose } from "vue";
const emit = defineEmits(["reacquire"]);
let setScores = ref(null);
let dialogVisible = ref(false);
let queryInfo = reactive({
  score_group: "",
});

let spanData = ref([]);
let pos = ref([]);

let fieldList = ref([
  {
    id: 1,
    prop: "score_describe",
    label: "评分依据",
  },
  {
    id: 2,
    prop: "score_remarks",
    label: "评分标准",
  },
  {
    id: 3,
    prop: "score_value",
    label: "评选分数",
  },
  {
    id: 10,
    prop: "operation",
    label: "操作",
    width: 180,
  },
]);

// 合并单元格
function handleObjectSpanMethod({ rowIndex, columnIndex }) {
  //  row, column,
 // 需要合并的列
 // [0, 1, 2].includes(columnIndex ), 表示合并前三列
 if (columnIndex === 0) {
  const _row = spanData.value[rowIndex]
  const _col = _row > 0 ? 1 : 0
  return {
   rowspan: _row,
   colspan: _col
  }
 }
}

let list = ref([])

function init(group) {
  queryInfo.score_group = group;
  getScoreList();
}

function edit(info) {
  setScores.value.init(JSON.parse(JSON.stringify(info)));
}

function getScoreList() {
  memberApi.getScoreList(queryInfo).then((res) => {
    console.log(res);
    list.value = res.data;
    getSpanData(res.data)
    dialogVisible.value = true;
  });
}

// 计算需要合并的单元格
function getSpanData(data) {
 // 存放计算好的合并单元格的规则
 spanData.value = []
 for (var i = 0; i < data.length; i++) {
  if (i === 0) {
   spanData.value.push(1)
   pos.value = 0
  } else {
   // 判断当前元素与上一个元素是否相同
   if (data[i].score_basis === data[i - 1].score_basis) {
    spanData.value[pos.value] += 1
    spanData.value.push(0)
   } else {
    spanData.value.push(1)
    pos.value = i
   }
  }
 }
}

function reacquire() {
  getScoreList();
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

function dialogClosed() {
  emit("reacquire");
}

defineExpose({
  init,
});
</script>
