<template>
  <div>
    <!-- 没有child,就显示单个目录，没有小箭头 -->
    <el-menu-item :index="navItem.permission_url" v-if="navItem.children.length == 0" @click="setAddItem(menuItem)">
      <template #title>
        <el-icon :size="15">
          <component :is="Menu"></component>
        </el-icon>
        <span>{{ navItem.permission_name }}</span>
      </template>
    </el-menu-item>
    <el-sub-menu v-else :index="navItem.permission_url">
      <template #title>
        <el-icon :size="15">
          <component :is="Grid"></component>
        </el-icon>
        <span>{{ navItem.permission_name }}</span>
      </template>
      <label v-for="menuItem in navItem.children" :key="menuItem.permission_url">
        <Nav :menuItem="menuItem" :addTab="addTab"></Nav>
      </label>
    </el-sub-menu>
  </div>
</template>
<script setup>
import { ref, defineProps } from "vue";
import { Grid, Menu } from "@element-plus/icons-vue";
import Nav from "@/components/Menu/Menu.vue";

let navItem = ref([]);
const props = defineProps({
  menuItem: {
    type: Object,
    defaule: () => {
      return {};
    },
  },
  addTab: Function
});
const addTab = props.addTab
navItem.value = props.menuItem;
const setAddItem = (menuItem) => {
  addTab(menuItem)
}

</script>