<template>
  <!-- 对话框 -->
  <el-dialog
    v-model="dialogVisible"
    title="会员咨询详情"
    width="1100px"
    @close="dialogClosed"
  >
    <!-- 表单数据 -->
    <div v-if="list.length == 0" class="flex justify-center align-center">
      <div>----该会员未提交过咨询内容----</div>
    </div>
    <el-descriptions v-else :column="3" :size="size" border>
      <el-descriptions-item label="会员姓名">
        {{ info.consult_member_name || "未填写" }}
      </el-descriptions-item>
      <el-descriptions-item label="联系方式">
        {{ info.consult_member_phone || "未填写" }}
      </el-descriptions-item>
      <el-descriptions-item label="咨询时间">
        {{
          info.consult_creation_time
            ? getFormatTime(info.consult_creation_time)
            : "未填写"
        }}
      </el-descriptions-item>
      <el-descriptions-item label="留学目标">
        {{ info.consult_target || "未填写" }}
      </el-descriptions-item>
      <el-descriptions-item label="留学大专业">
        {{ info.consult_application_professional || "未填写" }}
      </el-descriptions-item>
      <el-descriptions-item label="留学小专业">
        {{ info.consult_application_class || "未填写" }}
      </el-descriptions-item>
      <el-descriptions-item label="留学国家">
        {{ info.consult_application_country || "未填写" }}
      </el-descriptions-item>
      <el-descriptions-item label="希望了解内容">
        {{ info.consult_content || "未填写" }}
      </el-descriptions-item>
    </el-descriptions>

    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">关 闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import memberApi from "@/api/memberApi.js";
import date from "@/utils/date.js";
import { ref, reactive, defineEmits, defineExpose } from "vue";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let queryInfo = reactive({
  member_id: "",
  pageNum: 1,
  pageSize: 10,
});
let list = ref([]);
let info = ref({});

function getFormatTime(value) {
  return date.formatDate(value * 1000);
}

function init(info = null) {
  if (info) {
    queryInfo.member_id = info.member_id;
    getConsultList();
  }
  dialogVisible.value = true;
}

function getConsultList() {
  memberApi.getConsultList(queryInfo).then((res) => {
    console.log(res);
    list.value = res.data.list;
    info.value = res.data.list[0];
  });
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

function dialogClosed() {
  list.value = [];
  emit("reacquire");
}
defineExpose({
  init,
});
</script>

<style>
</style>
