<template>
  <el-upload
    ref="uploadImg"
    :limit="limit"
    :multiple="multiple"
    :action="config.addUrl"
    :show-file-list="showList"
    :on-success="handleSuccess"
    :before-upload="beforeUpload"
    :on-exceed="handleExceed"
    name="media"
  >
    <slot></slot>
  </el-upload>
</template>

<script setup>
// import commonApi from "@/api/commonApi.js";
import { ref, defineProps, computed, defineEmits, defineExpose } from 'vue'
import { ElMessage } from 'element-plus'
const emit = defineEmits(["success"]);
import config from "@/utils/config.js";
let uploadImg = ref(null)
const props = defineProps({
  showList: {
    type: Boolean,
    default: false,
  },
  uploadKey: {
    type: String,
    default: "",
  },
  limit: {
    type: Number,
    default: 999,
  },
})

const multiple = computed(() => {
  let isMultiple = false;
  if (props.limit > 1) {
    isMultiple = true;
  }
  return isMultiple;
})

function beforeUpload(file) {
  const isLt10M = file.size / 1024 / 1024 < 100;
  if (!isLt10M) {
    ElMessage.error("上传资源大小不能超过 100MB!");
    return false;
  }
}

// 上传文件数量限制
function handleExceed() {
  ElMessage.warning("超过最大上传数量");
  return false;
}

function handleSuccess(res) {
  emit("success", props.uploadKey, res.data.mediaUrl);
}

// 清除
function clearFiles() {
  console.log("清楚");
  uploadImg.value.clearFiles();
}

defineExpose({
  clearFiles
})
</script>

