<template>
  <!-- 对话框 -->
  <el-dialog
    v-model="dialogVisible"
    :title="title"
    width="500px"
    @close="dialogClosed"
  >
    <!-- 表单数据 -->
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="160px">
      <el-form-item label="评分依据：" prop="matching_type">
        <el-select
        @change="see"
          class="w-100"
          v-model="form.matching_type"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户分数最大值：" prop="matching_max_fraction">
        <el-input
          :disabled="form.matching_type == 'max'"
          type="number"
          v-model="form.matching_max_fraction"
          placeholder="请输入用户分数最大值"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户分数最小值：" prop="matching_min_fraction">
        <el-input
          :disabled="form.matching_type == 'min'"
          type="number"
          v-model="form.matching_min_fraction"
          placeholder="请输入用户分数最小值"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="form.matching_group != 'high'"
        label="QS世界排名最先值："
        prop="matching_max_ranking"
      >
        <el-input
          type="number"
          :disabled="form.matching_type == 'max'"
          v-model="form.matching_max_ranking"
          placeholder="请输入QS世界排名最先值"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="form.matching_group != 'high'"
        label="QS世界排名最后值："
        prop="matching_min_ranking"
      >
        <el-input
          type="number"
          :disabled="form.matching_type == 'min'"
          v-model="form.matching_min_ranking"
          placeholder="请输入QS世界排名最后值"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="form.matching_group == 'high'"
        label="高中学校排名值："
        prop="matching_ranking_grade"
      >
        <el-select
          class="w-100"
          v-model="form.matching_ranking_grade"
          placeholder="请选择"
        >
          <el-option
            v-for="item in gradeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注：" prop="matching_remarks">
        <el-input
          type="textarea"
          :rows="4"
          v-model="form.matching_remarks"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部区域 -->
    <template #footer>
      <span>
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import memberApi from "@/api/memberApi.js";
import { ref, reactive, defineEmits, defineExpose, computed } from "vue";
import { ElMessage } from "element-plus";
const emit = defineEmits(["reacquire"]);
let dialogVisible = ref(false);
let title = computed(() => {
  if (form.value.matching_id == 0) {
    return "匹配规则添加";
  } else {
    return "匹配规则修改";
  }
});

let options = [
  {
    value: "max",
    label: "大于等于某值",
  },
  {
    value: "min",
    label: "小于等于某值",
  },
  {
    value: "between",
    label: "某值与某值之间",
  },
];

let gradeOptions = [
  {
    value: "A",
    label: "A",
  },
  {
    value: "B",
    label: "B",
  },
];
let formRef = ref(null);
let form = ref({
  matching_id: 0,
  matching_group: "",
  matching_max_fraction: 0,
  matching_min_fraction: 0,
  matching_max_ranking: 0,
  matching_min_ranking: 0,
  matching_remarks: "",
  matching_ranking_grade: "",
  matching_type: "",
});
const formRules = reactive({});

function init(info = null) {
  if (info) {
    form.value = info;
    console.log('form.value',form.value)
  }
  dialogVisible.value = true;
}

// 取消
function cancel() {
  dialogVisible.value = false;
}

function see() {
  console.log('form.value',form.value);
}

// 提交
function onSubmit() {
  if (!formRef) return;
  formRef.value.validate((valid) => {
    if (valid) {
      if (form.value.matching_id && form.value.matching_id != 0) {
        updateMatching();
      } else {
        addMatching();
      }
    } else {
      return false;
    }
  });
}
function updateMatching() {
  memberApi.updateMatching(form.value).then(() => {
    ElMessage.success("修改成功");
    dialogVisible.value = false;
  });
}
function addMatching() {
  memberApi.addMatching(form.value).then(() => {
    dialogVisible.value = false;
  });
}

function dialogClosed() {
  // 表单重置
  formRef.value.resetFields();
  form.value = {
    matching_id: 0,
    matching_group: "",
    matching_max_fraction: 0,
    matching_min_fraction: 0,
    matching_max_ranking: 0,
    matching_min_ranking: 0,
    matching_remarks: "",
    matching_ranking_grade: "",
    matching_type: "",
  };
  emit("reacquire");
}
defineExpose({
  init,
});
</script>
