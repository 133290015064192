<template>
  <div class="w-100 h-100">
    <div class="
        w-100
        border-box
        flex
        justify-start
        align-center
        card-shadow
        bg-white
        rounded
        px-1
        overflow-x
      " style="height: 50px">
      <div class="flex justify-start align-center">
        <!-- 用户昵称 -->
        <div class="flex justify-start align-center">
          <div class="mr-1">问题描述:</div>
          <el-input style="width: 110px" v-model="queryInfo.question_title" placeholder="请输入"></el-input>
        </div>
        <!-- 状态 -->
        <div class="flex justify-start align-center">
          <div class="mx-1">上架状态:</div>
          <el-select class="mr-1" v-model="queryInfo.question_status" style="width: 110px" placeholder="Select">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <!-- 按钮 -->
        <el-button type="primary" @click="query">
          <el-icon :size="15">
            <component :is="Search"></component>
          </el-icon>搜索
        </el-button>
        <el-button type="primary" @click="reset">重置</el-button>
        <el-button v-if="showAdd" @click="add" type="success">
          <el-icon :size="15">
            <component :is="Plus"></component>
          </el-icon>新增
        </el-button>
      </div>
    </div>
    <div class="w-100 mt-1 border-box card-shadow bg-white rounded p-1" style="height: calc(100% - 70px)">
      <!-- 表格 -->
      <div class="w-100" style="height: calc(100% - 60px)">
        <el-table :data="list" class="w-100"  height="100%">
          <el-table-column label="序号" align="center" min-width="100">
            <template #default="scope">
              {{scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column v-for="(field, index) in fieldList" :key="index" :prop="field.prop" :label="field.label"
            :width="field.width || ''" align="center">
            <template #default="scope">
              <!-- 操作 -->
              <div v-if="field.prop == 'operation'">
                <!-- 修改按钮 -->
                <el-button :disabled="!showEdit" type="primary" size="small" @click="edit(scope.row)">编辑</el-button>
                <!-- 删除按钮 -->
                <el-button :disabled="!showDelete" type="danger" size="small"
                  @click="del(scope.row)">删除
                </el-button>
              </div>
              <!-- 状态 -->
              <div v-else-if="field.prop == 'question_status'">
                <el-switch @change="updateProblem(scope.row)"
                  v-model="scope.row.question_status" active-color="#13ce66" inactive-color="#ff4949" :active-value="1"
                  :inactive-value="0" />
              </div>
              <span v-else>{{ scope.row[field.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="flex justify-center align-center" style="height: 50px">
        <el-pagination :page-sizes="[10, 50, 100, 200, 500]" background layout="total, sizes, prev, pager, next"
          :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
    <addProblem ref="addProblems" @reacquire="reacquire"></addProblem>
  </div>
</template>

<script setup>
import systemnApi from "@/api/systemnApi.js";
import addProblem from "./addProblem/addProblem.vue";
import { ref, reactive, onMounted, computed } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { Search, Plus } from "@element-plus/icons-vue";
import { useStore } from "vuex";
const store = useStore();
let addProblems = ref(null);
let options = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 0,
    label: "下架",
  },
  {
    value: 1,
    label: "上架",
  },
];
let queryInfo = reactive({
  question_title: "",
  question_status: "",
  pageNum: 1,
  pageSize: 10,
});
const fieldList = [
  // {
  //   id: 1,
  //   prop: "question_id",
  //   label: "序号",
  // },
  {
    id: 2,
    prop: "question_title",
    label: "问题描述",
  },
  {
    id: 3,
    prop: "question_sort",
    label: "等级排序",
  },
  {
    id: 4,
    prop: "question_answer",
    label: "解决方案",
  },
  {
    id: 5,
    prop: "question_status",
    label: "上架",
  },
  {
    id: 6,
    prop: "operation",
    label: "操作",
    width: 180,
  },
];
let total = ref(0);
let list = ref([]);

let showAdd = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("10502")) {
    return true;
  } else {
    return false;
  }
});

let showEdit = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("10503")) {
    return true;
  } else {
    return false;
  }
});

let showDelete = computed(() => {
  if (store.state.userInfo.role_ascription) {
    return true;
  } else if (store.getters.role_menu_ids.indexOf("10504")) {
    return true;
  } else {
    return false;
  }
});

function query() {
  queryInfo.pageNum = 1;
  getProblemList();
}

function reset() {
  queryInfo.question_title = "";
  queryInfo.question_status = "";
  queryInfo.pageNum = 1;
  queryInfo.pageSize = 10;
  getProblemList();
}

function getProblemList() {
  systemnApi.getProblemList(queryInfo).then((res) => {
    list.value = res.data.list;
    total.value = res.data.total;
  });
}

function add() {
  addProblems.value.init(null, total.value + 1);
}

function edit(info) {
  addProblems.value.init(info);
}

function del(info) {
  ElMessageBox.confirm("确定删除该数据吗？", "Warning", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let data = {
        question_id: info.question_id
      }
      systemnApi.deleteProblem(data).then((res) => {
        console.log(res);
        ElMessage.success("删除成功");
        reacquire();
      });
    })
    .catch(() => {
      // catch error
    });
}

function updateProblem(info) {
  if (info && info.question_id) {
    systemnApi.updateProblem(info).then(() => {
      ElMessage.success("修改成功");
    });
  }
}

function handleSizeChange(number) {
  queryInfo.pageSize = number;
  getProblemList();
}

function handleCurrentChange(newPage) {
  queryInfo.pageNum = newPage;
  getProblemList();
}

function reacquire() {
  queryInfo.pageNum = 1;
  getProblemList();
}

onMounted(() => {
  // 获取列表
  getProblemList();
});
</script>

